import React from "react";
export const IntroLanding = ({data}) => {
  
  return (
    <section id='intro-landing' className='intro container-fluid d-flex align-items-center'>
          <div className='row flex-grow-1 justify-content-center'>
            <div className='col-auto intro-text'>
              <div className='d-flex flex-wrap w-100 mb-5'>
                {data ? data.LandingList.map((d, i) => (
                  <a className='btn-landing col mx-2 mx-sm-0 my-3 px-0' key={`${i}`} title={d.title} href={`${d ? d.link : '#'}`} target={`${d.target}`} rel={`${d.rel}`}>
                     <img width="35" height="50" src={`${data ? data.linkImage : ''}`} className="d-none d-lg-block hover-icon img-responsive" alt="" />
                    {d.title}
                    <img width="54" height="54" className='icon mt-3' src={d.icon} alt={d.title}/>
                  </a>
                ))
                : "loading"}
              </div>
              <img src={`${data ? data.landingImage : ''}`} className="img-responsive" alt="" />
            </div>
          </div>
    </section>
  )
}


