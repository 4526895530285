import React from "react";

export const CopyRight = ({data}) => {
  return (
    <>
    <div id='footer-copyright'>
      <div className='container text-center'>
        <p dangerouslySetInnerHTML={{__html: data ? data.paragraph : 'Loading' }}></p>
      </div>
    </div>
    </>
  )
}

export default CopyRight;