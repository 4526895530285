import React from "react";

export const Soon = ({data}) => {
  return (
    <>
    <section id='intro' className='intro container-fluid d-flex align-items-center h-100' >

          <div className='row flex-grow-1'>
            <div className='col-12 intro-text'>
              <h1>Coming Soon</h1>
              <p className="mx-auto my-4 px-5" style={{maxWidth: '560px'}}>{data ? data.paragraph : 'Loading'}</p>
              <a href={`${data ? data.link : '#'}`} className='btn-custom'>
                <img src={`${data ? data.linkImage : ''}`} className="img-responsive mr-1" alt="" />
                {data ? data.linkTitle : 'Loading'}
              </a>
            </div>
          </div>
    </section>
    </>
  )
}


