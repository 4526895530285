import React from "react";
import {Helmet} from "react-helmet";
import { IntroLanding } from "../../components/intro-landing";
import { usePageData } from "../../hooks/app-hooks";
import SmoothScroll from "smooth-scroll";
import { withLandingLayout } from '../../layouts'

//import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});


const IndexPage = () => {
  const PageData = usePageData();
  return (
    <>
      <Helmet>
        <title>Own.fund</title>
        <meta name="description" content="A Collective of Cryptocurrency Founders and Investors Supporting the Creator Economy"/>
        <meta property="og:title" content="Own.fund"/>
        <meta name="keywords" content="" />
        <meta property="og:description" content="A Collective of Cryptocurrency Founders and Investors Supporting the Creator Economy" />
      </Helmet>
      <IntroLanding data={PageData.IntroLanding} />
    </>
  );
};

export default withLandingLayout(IndexPage);
