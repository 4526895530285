import React from "react";
export const TorchImage = ({widthImage, heightImage, className}) => {
  return (
    <>
      <svg width={widthImage} height={heightImage} className={className} version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 54 54">
        <g className="st0">
          <path className="st1" d="M32.7,1.8H20.6v4h-4.7v3.4h-3.7v5.1H8.1v12.5h4.1v4.1h3.7v4h4.7v4.4h12.1v-4.4h4.7v-4h4.4v-4.1h4V14.3h-4V9.2
              h-4.4V5.8h-4.7V1.8z" />
          <path className="st2" d="M33.1,6.5H20.9v4.1h-4.4v4.1h-4.4v12.5h4.4v4.1h4.4v4.4H33v-4.4h4v-4.1h4.4V14.6H37v-4.1h-4L33.1,6.5L33.1,6.5
              z" />
          <path className="st3" d="M20.2,11.2H32V15h4.7v12.1H32v3.4H20.2v-3.4h-3.4V15h3.4V11.2z" />
        </g>
        <rect x="16.2" y="32.4" className="st4" width="21.6" height="2.7" />
        <rect x="20.7" y="35.1" className="st4" width="2.7" height="2.7" />
        <rect x="23.4" y="35.1" className="st5" width="2.7" height="2.7" />
        <rect x="26.1" y="35.1" className="st6" width="2.7" height="2.7" />
        <rect x="28.8" y="35.1" className="st7" width="2.7" height="2.7" />
        <rect x="23.4" y="37.8" className="st8" width="2.7" height="2.7" />
        <rect x="28.8" y="37.8" className="st8" width="2.7" height="2.7" />
        <rect x="26.1" y="37.8" className="st9" width="2.7" height="2.7" />
        <rect x="20.7" y="37.8" className="st4" width="2.7" height="2.7" />
        <rect x="31.5" y="35.1" className="st4" width="2.7" height="2.7" />
        <rect x="23.4" y="40.5" className="st4" width="2.7" height="2.7" />
        <rect x="28.8" y="40.5" className="st4" width="2.7" height="2.7" />
        <rect x="23.4" y="43.2" className="st4" width="2.7" height="2.7" />
        <rect x="23.4" y="45.9" className="st4" width="2.7" height="2.7" />
        <rect x="31.5" y="37.8" className="st4" width="2.7" height="2.7" />
        <rect x="28.8" y="43.2" className="st4" width="2.7" height="2.7" />
        <rect x="28.8" y="45.9" className="st4" width="2.7" height="2.7" />
        <rect x="26.1" y="48.6" className="st4" width="2.7" height="2.7" />
        <rect x="26.1" y="45.9" className="st10" width="2.7" height="2.7" />
        <rect x="26.1" y="43.2" className="st11" width="2.7" height="2.7" />
        <rect x="26.1" y="40.5" className="st11" width="2.7" height="2.7" />
        <rect x="19.8" y="17.1" className="flame3 st12" width="2.7" height="2.7" />
        <path className="st13"
          d="M16.2,29.2v3.2h21.6v-3.2h-3.2v-5.9H32V17h-5.8v3.1H23V23h-3.1v6.3L16.2,29.2L16.2,29.2z" />
        <path className="st14" d="M31.5,23.4v9h-5.4V20.7h2.7v2.7C28.8,23.4,31.5,23.4,31.5,23.4z" />
        <polygon className="st12"
            points="22.5,25.2 22.5,28.8 22.5,32.4 26.1,32.4 26.1,28.8 26.1,25.2 	" />
        <rect x="23.4" y="8.1" className="flame1 st12" width="2.7" height="2.7" />
        <rect x="36" y="18" className="flame2 st12" width="2.7" height="2.7" />
      </svg>
    </>
  )
}