import React from "react";

export const About = ({data}) => {
  return (
    <section id="about" className="pt-5">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6 mb-5 mb-lg-0 pr-xl-5">
              <h2 className="mb-5" dangerouslySetInnerHTML={{__html: data ? data.heading : "loading..." }}></h2>
              <div className="text-justify" dangerouslySetInnerHTML={{__html: data ? data.paragraph : "loading..." }}></div>
          </div>
          <div className="col-12 col-lg-6">
            <ul className="w-100 list-style"> 
              {data ? data.Why.map((d, i) => (
                    <li key={`${d}-${i}`}>{d}</li>
                  ))
                : "loading"}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};
