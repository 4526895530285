import React from "react";
export const Navigation = ({data}) => {
  return (
    <header>
      <nav id='menu' className='navbar navbar-expand-lg fixed-top'>
        <div className='container'>
          
          <a className='navbar-brand page-scroll' href='/home#page-top'>
              <img width="173" height="40" src="img/ownfund-logo.svg" className="img-responsive" alt=""/>
          </a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarContent" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
              <span></span>
              <span></span>
              <span></span>
          </button>

          <div className='collapse navbar-collapse' id='navbarContent'>
            <ul className='nav navbar-nav ml-auto'>
              {data ? data.map((d, i) => (
                    <li key={`${d.title}-${i}`} className='nav-item'>
                      <a href={`${d.link}`} target={`${d.target}`} rel={`${d.rel}`} className='nav-link page-scroll'>{d.title}</a>
                    </li>
                  ))
                : 'Loading'}
                <li className='nav-item'>
                  <a rel="noopener noreferrer" target="_blank" href="https://vote.own.fund/" className='btn-custom'>
                     Governance
                  </a>
                </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  )
}

export default Navigation;