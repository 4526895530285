import {useState, useEffect } from "react"
import { useLocation } from "react-router"
import JsonData from "../data/data.json";

export const useBodyClass = () => {

    const location = useLocation();
    useEffect(() => {

        const path = location.pathname.replace('/', '').replace('.', '-')|| 'landing'
        document.body.classList.add(`${path}-page`)
    },[location])

}

export const usePageData = () => {

    const [PageData, setPageData] = useState({});
    
    useEffect(() => {
        if (JsonData) setPageData(JsonData);
    }, []);

    return PageData
}

