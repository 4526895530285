import React from "react";
import { useLocation } from 'react-router-dom' ;

export const NotFound = ({data}) => {
  let location = useLocation();

  return (
    <>
    <section id='not-found' className='intro container-fluid d-flex align-items-center'>
         <code className="d-none">{location.pathname}</code>
          <div className='row flex-grow-1'>
            <div className='col-12 intro-text mb-5'>
              <h1>{data ? data.title : 'Loading'}</h1>
              <p className="mx-auto my-4 px-5" style={{maxWidth: '560px'}}>{data ? data.paragraph : 'Loading'}</p>
              <a href={`${data ? data.link : '#'}`} className='btn-custom'>
                <img src={`${data ? data.linkImage : ''}`} className="img-responsive mr-1" alt="" />
                {data ? data.linkTitle : 'Loading'}
              </a>
            </div>
          </div>
    </section>
     <div className='w-100 main-img-wrapper mt-auto'>
        <img width="1920" height="391" src={`${data ? data.mainImage : ''}`} className="img-responsive" alt="" />
    </div>
    </>
  )
}


