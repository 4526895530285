import React from "react";
import { TorchImage } from "./svg/torch-svg";

export const Intro = ({data}) => {
  return (
  <section id='intro' className='intro container-fluid d-flex align-items-center'>
    <div className='row flex-grow-1 justify-content-center'>
      <div className='col-auto intro-text'>
        <h1 className='text-primary'>
          <TorchImage widthImage={'5rem'} heightImage={'5rem'} className={'torchLeft'}/>
          {data ? data.title : 'Loading'}
          <TorchImage widthImage={'5rem'} heightImage={'5rem'} className={'torchRight'}/>
        </h1>
        <p className="lead mx-auto my-4" style={{maxWidth: '620px'}} dangerouslySetInnerHTML={{__html: data ? data.paragraph : 'Loading' }}></p>
        <img width="814" height="208" src={`${data ? data.mainImage : '' }`} className="d-block mx-auto" alt="" />
        <a href={`${data ? data.link : '#' }`} className='btn-arrow-down d-inline-block page-scroll'>
          {data ? data.linkTitle : 'Loading'}
          <img width="35" height="50" src={`${data ? data.linkImage : '' }`} className="img-responsive" alt="" />
        </a>
      </div>
    </div>
  </section>
  )
}