import React, { useState, useEffect } from "react";
import Header from '../components/navigation';
import Footer from '../components/footer';
import CopyRight from '../components/copyright';
import Modal from '../components/modal';
import JsonData from "../data/data.json";
import { useBodyClass } from "../hooks/app-hooks";

export const withStandardLayout = PageContentComponent => () => {
	const [PageData, setPageData] = useState({});
    useBodyClass();
	useEffect(() => {
	  setPageData(JsonData);
	}, []);

	return (
		<>
			<Header data={PageData.Navigation}/>
			<div className="page-content">
				<PageContentComponent />
			</div>
			<Footer data={PageData.Footer} />
			<CopyRight data={PageData.CopyRight} />
			<Modal />
			<a href="#page-top" className="page-scroll" id="onTop">On Top</a>
		</>
	)
}

export const withLandingLayout = PageContentComponent => () => {
	const [PageData, setPageData] = useState({});
    useBodyClass();
	useEffect(() => {
	  setPageData(JsonData);
	}, []);

	return (
		<>
			<div className="page-content pt-0">
				<PageContentComponent />
			</div>
			<CopyRight data={PageData.CopyRight} />
		</>
	)
}
export const withNotFoundLayout = PageContentComponent => () => {
	useBodyClass();
	return (
		<>
			<div className="not-found-content">
				<PageContentComponent />
			</div>
		</>
	)
}