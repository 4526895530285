import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { showModal } from '../store/store'
import { MemberImage } from "./svg/member-svg";
import { TorchImage } from "./svg/torch-svg";

export const Team = ({data}) => {
const dispatch = useDispatch();
  return (
  <section id='team'>
    <div className='container'>
      <div id='row' className='row'>
        <div className='col-12 text-center'>
          <h2 className='heading mx-auto mb-5'>
            <TorchImage widthImage={'3rem'} heightImage={'3rem'} className={'torchLeft'}/>
            {data ? data.heading : 'Loading'}
            <TorchImage widthImage={'3rem'} heightImage={'3rem'} className={'torchRight'}/>
          </h2>
        </div>
        {data
        ? data.TeamList.map((d, i) => (
        <div key={`${d.name}-${i}`} className='member col-12 col-sm-6 col-lg-3 mb-5'>
            <div className="img-wrapper">
              <Button variant="member" onClick={()=> dispatch(showModal({ title: d.name, job: d.job, content: d.content }))}>
                <img width="260" height="260" className="w-100" src={d.img} alt='...' />
              </Button>
              <div className="member-social text-center">
                <a rel="noopener noreferrer" target="_blank" className="mx-3 mx-sm-2" href={`https://twitter.com/${d ?
                  d.linkTwitter : '#' }`} title={`Follow ${d.name} on Twitter`}>
                  <img className="icon" src="../img/twitter-primary.svg" alt='...' />
                </a>
                <a rel="noopener noreferrer" target="_blank" className="mx-2" href={`${d ? d.linkMember : '#' }`}
                  title={`Visit site`}>
                  <img className="icon" src="../img/link-icon.svg" alt='...' />
                </a>
              </div>
            </div>
          <div className='caption mb-2'>
            <h3 className='h6 text-white mb-0 mt-2'>{d.name}</h3>
            <p className='text-small text-gray'>{d.job}</p>
          </div>
        </div>
        ))
        : 'loading'}
        <div className='col-12 text-center'>
          <a href="#down" id="loadMore" className='btn-custom d-sm-none'>
            Show more
            <img src={`${data ? data.linkImage : '' }`} className="img-responsive ml-2" alt="" />
          </a>
        </div>
      </div>
    </div>
    <MemberImage/>
  </section>
  )
}