import React from "react";

export const Footer = ({data}) => {
  return (
    <footer id='footer' className='container pt-3'>
      <div className='row'>

        <div className='col-12 col-lg-3 my-3 d-flex d-lg-block flex-column align-items-center align-items-sm-start'>
          <a className='page-scroll' href='/home#page-top'>
              <img width="173" height="40" src="img/ownfund-logo.svg" className="img-responsive" alt=""/>
          </a>
        </div>

        <div className='col-12 col-sm my-3 d-flex d-lg-block flex-column align-items-center align-items-sm-start'>
          <h2 className='h6 mb-3'>{data ? data.title1 : 'Loading'}</h2>
          <ul className='links text-center text-sm-left mb-0'>
            {data ? data.FooterMenu1.map((d, i) => (
              <li key={`${i}`}>
                <a title={d.title} href={`${d ? d.link : '#'}`}>{d.title}</a>
              </li>
            ))
            : "loading"}
          </ul>
        </div>

        <div className='col-12 col-sm-auto my-3 col-lg d-flex d-lg-block flex-column align-items-center align-items-sm-start'>
          <h2 className='h6 mb-3'>{data ? data.title2 : 'Loading'}</h2>
          <ul className='links text-center text-sm-left mb-0'>
            {data ? data.FooterMenu2.map((d, i) => (
              <li key={`${i}`}>
                <a title={d.title} href={`${d ? d.link : '#'}`} rel="noopener noreferrer" target="_blank">{d.title}</a>
              </li>
            ))
            : "loading"}
          </ul>
        </div>

        <div className='col-12 col-sm d-flex d-lg-block flex-column align-items-center align-items-sm-end my-3'>
          <h2 className='h6 mb-3'>{data ? data.title3 : 'Loading'}</h2>
          <ul className='links text-center text-sm-left mb-0'>
            {data ? data.FooterMenuSocial.map((d, i) => (
              <li key={`${i}`}>
                <a title={d.title} href={`${d ? d.link : '#'}`} rel="noopener noreferrer" target="_blank">
                  <img src={`${d.icon ? d.icon : 'img/favicon.svg'}`} className="img-responsive" alt={d.title}/>
                  {d.title}
                </a>
              </li>
            ))
            : "loading"}
          </ul>
        </div>
      </div>
  </footer>
  )
}


export default Footer;