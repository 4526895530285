import React from "react";
export const MemberImage = () => {
  return (
    <>
        <div className="svg-wrapper">
            <svg id="teamLand" width="100%" height="100%" viewBox="0 0 380 381" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="coin-and-land">
                    <g id="coin">
                        <g className="coin-front">
                        <path className="st6" d="M296.1,6.8h3.4v3.4h-3.4V6.8z"/>
                        <path className="st6" d="M296.1,43.9h3.4v3.4h-3.4V43.9z"/>
                        <path className="st6" d="M326.5,43.9h3.4v3.4h-3.4V43.9z"/>
                        <path className="st6" d="M329.9,40.5h3.4v3.4h-3.4V40.5z"/>
                        <path className="st6" d="M292.8,10.1h3.4v3.4h-3.4V10.1z"/>
                        <path className="st6" d="M329.9,10.1h3.4v3.4h-3.4V10.1z"/>
                        <path className="st6" d="M326.5,6.8h3.4v3.4h-3.4V6.8z"/>
                        <path className="st6" d="M292.8,40.5h3.4v3.4h-3.4V40.5z"/>
                        <path className="st6" d="M289.4,13.5h3.4v6.8h-3.4V13.5z"/>
                        <path className="st6" d="M333.2,13.5h3.4v6.8h-3.4V13.5z"/>
                        <path className="st6" d="M289.4,33.8h3.4v6.8h-3.4V33.8z"/>
                        <path className="st6" d="M333.2,33.8h3.4v6.8h-3.4V33.8z"/>
                        <path className="st6" d="M286,20.2h3.4v13.5H286V20.2z"/>
                        <path className="st6" d="M336.6,20.2h3.4v13.5h-3.4V20.2z"/>
                        <path className="st6" d="M299.5,3.4h6.8v3.4h-6.8V3.4z"/>
                        <path className="st6" d="M319.8,3.4h6.8v3.4h-6.8V3.4z"/>
                        <path className="st6" d="M299.5,47.2h6.8v3.4h-6.8V47.2z"/>
                        <path className="st6" d="M319.8,47.2h6.8v3.4h-6.8V47.2z"/>
                        <path className="st6" d="M306.2,50.6h13.5V54h-13.5V50.6z"/>
                        <path className="st6" d="M306.2,0h13.5v3.4h-13.5V0z"/>
                        <path className="st6" d="M299.5,6.8v3.4h-3.4v3.4h-3.4v6.8h-3.4v13.5h3.4v6.8h3.4v3.4h3.4v3.4h6.8v3.4h13.5v-3.4h6.8v-3.4h3.4v-3.4h3.4
                            v-6.8h3.4V20.2h-3.4v-6.8h-3.4v-3.4h-3.4V6.8h-6.8V3.4h-13.5v3.4H299.5z"/>
                        <path className="st7" d="M296.1,6.8h3.4v3.4h-3.4V6.8z"/>
                        <path className="st7" d="M296.1,43.9h3.4v3.4h-3.4V43.9z"/>
                        <path className="st7" d="M326.5,43.9h3.4v3.4h-3.4V43.9z"/>
                        <path className="st7" d="M329.9,40.5h3.4v3.4h-3.4V40.5z"/>
                        <path className="st7" d="M292.8,10.1h3.4v3.4h-3.4V10.1z"/>
                        <path className="st7" d="M329.9,10.1h3.4v3.4h-3.4V10.1z"/>
                        <path className="st7" d="M326.5,6.8h3.4v3.4h-3.4V6.8z"/>
                        <path className="st7" d="M292.8,40.5h3.4v3.4h-3.4V40.5z"/>
                        <path className="st7" d="M289.4,13.5h3.4v6.8h-3.4V13.5z"/>
                        <path className="st7" d="M333.2,13.5h3.4v6.8h-3.4V13.5z"/>
                        <path className="st7" d="M289.4,33.8h3.4v6.8h-3.4V33.8z"/>
                        <path className="st7" d="M333.2,33.8h3.4v6.8h-3.4V33.8z"/>
                        <path className="st7" d="M286,20.2h3.4v13.5H286V20.2z"/>
                        <path className="st7" d="M336.6,20.2h3.4v13.5h-3.4V20.2z"/>
                        <path className="st7" d="M299.5,3.4h6.8v3.4h-6.8V3.4z"/>
                        <path className="st7" d="M319.8,3.4h6.8v3.4h-6.8V3.4z"/>
                        <path className="st7" d="M299.5,47.2h6.8v3.4h-6.8V47.2z"/>
                        <path className="st7" d="M319.8,47.2h6.8v3.4h-6.8V47.2z"/>
                        <path className="st7" d="M306.2,50.6h13.5V54h-13.5V50.6z"/>
                        <path className="st7" d="M306.2,0h13.5v3.4h-13.5V0z"/>
                        <rect x="299.5" y="16.9" className="st8" width="3.4" height="3.4"/>
                        <rect x="323.1" y="16.9" className="st8" width="3.4" height="3.4"/>
                        <rect x="299.5" y="33.8" className="st8" width="3.4" height="3.4"/>
                        <rect x="296.1" y="20.2" className="st8" width="3.4" height="13.5"/>
                        <rect x="326.5" y="20.2" className="st8" width="3.4" height="13.5"/>
                        <rect x="323.1" y="33.8" className="st8" width="3.4" height="3.4"/>
                        <rect x="302.9" y="13.5" className="st8" width="3.4" height="3.4"/>
                        <rect x="319.8" y="13.5" className="st8" width="3.4" height="3.4"/>
                        <rect x="302.9" y="37.1" className="st8" width="3.4" height="3.4"/>
                        <rect x="319.8" y="37.1" className="st8" width="3.4" height="3.4"/>
                        <rect x="306.2" y="10.1" className="st8" width="13.5" height="3.4"/>
                        <rect x="306.2" y="40.5" className="st8" width="13.5" height="3.4"/>
                        <rect x="299.5" y="20.2" className="st7" width="3.4" height="3.4"/>
                        <rect x="302.9" y="16.9" className="st7" width="3.4" height="3.4"/>
                        <rect x="302.9" y="33.8" className="st7" width="3.4" height="3.4"/>
                        <rect x="299.5" y="37.1" className="st7" width="3.4" height="3.4"/>
                        <rect x="306.2" y="30.4" className="st7" width="3.4" height="3.4"/>
                        <rect x="309.6" y="27" className="st7" width="3.4" height="3.4"/>
                        <rect x="313" y="23.6" className="st7" width="3.4" height="3.4"/>
                        <rect x="316.4" y="20.2" className="st7" width="3.4" height="3.4"/>
                        <rect x="319.8" y="16.9" className="st7" width="3.4" height="3.4"/>
                        <rect x="323.1" y="13.5" className="st7" width="3.4" height="3.4"/>
                        <rect x="306.2" y="13.5" className="st7" width="3.4" height="3.4"/>
                        </g>
                    </g>
                    <g id="middle-land">
                        <path className="st10" d="M379.2,116.2v-3.8H244v23.9h5.3v10.6h7v6.8h9v5h18.4v-9.5h8.9v11.1h10.1h9.2v-11.1h14.9v7.9h8.9v6.7h9.8
                        v-14.6h13.6v4.5h14.2V143h9.6V121v-4.8H379.2z"/>
                        <path className="st11" d="M341.8,140.3h-7v4.2h-18.6h-6.2v5.1h-7.7v-3h-7v-11.9h-13.2v14.9h-7v5.7h-4.6v-2.8h-8.5v-5h-6.2v-5.7h-6.2
                        v-39.7h92.1V119h17.8v1h23.6v10.1h-6.3v9.8h-11.7v-6.7h-5.5v3.6h-8.9v8.5h-8.9V140.3z"/>
                        <path className="st12" d="M345.6,136.3v1.2h-20.1v-2.4h-11.2v2.4h-8.9v4.4h-17.9v-11.6h-12.3v10h-8.9v-10h-4.7v4.8h-4.3v2.4h-12.3v-6
                        h-5.6V129v-17.3h106.1v1.9h29v12.1h-15.2v-2.2h-6.7v6.8h-7.1V136.3z"/>
                        <path className="st3" d="M283.3,122.7v-4V82.5h93.3v7.7h6.8v7.7h5.4V134h-3.7h-4.3v-8.8h-9.4v5.5h-5.1v-12.1h-18.8v6.6h-14.5v-6.6h-5.2
                        v14.7h-8.5v-4.8h-6.8v7.3h-6v-7.3h-6v-9.8h-5.2v4H283.3z"/>
                        <path className="st3" d="M290.6,127.1v-6.6V84.4h-40.4v7.7h-7.5v7.7H237v36.1h3.8h4.7v-3.7h10.4v8.1h8.2v-19.7h18.2v6.6H290.6z"/>
                        <path className="st4" d="M340.8,110.8h5.3V80h-95.3v6.5h-6.9v6.5h-5v30.8h3.2h4.3v-7.5h9.5h5.2v-5.6h7.8v3.3h16.2v-3.3h7.8v8.1h9.7
                        v-8.1h5.3v13.1h11.1v-4.7h4.4v5.9h6.1v-5.9h11.1V110.8z"/>
                        <path className="st4" d="M328.3,116.4v-5.6V80h41.5v6.5h7.3v6.5h5.5v30.8H379h-4.6v-7.5h-7.5v6.1h-5.5v-11.7h-17.6v5.6H328.3z"/>
                        <path className="st5" d="M266.7,100.9h23.5V80h-36.4v4.4h-8.6v4.4h-6.4v20.9h4.3h5.4v-5.1h11.8v7.6h6.4V100.9z"/>
                        <path className="st5" d="M268.1,106.6v-5.7V80h96.4v4.4h8.4v4.4h6.1v20.9h-4h-5.3v-5.1h-11.6v7.6h-6.3v-11.4h-23.2v3.8h-17.9v-3.8h-9.5
                        v8.9h-7.4v-3.2h-8.4v5.7h-7.4v-5.7H268.1z"/>
                    </g>
                </g>
                <g id="book-and-land">
                    <g id="book">
                    <rect x="260.4" y="222.5" className="st9" width="3.1" height="31.8"/>
                    <rect x="254.2" y="254.3" className="st9" width="15.6" height="3.2"/>
                    <rect x="257.3" y="219.4" className="st9" width="3.1" height="3.2"/>
                    <rect x="254.2" y="216.2" className="st9" width="3.1" height="3.2"/>
                    <rect x="238.6" y="213" className="st9" width="15.6" height="3.2"/>
                    <rect x="235.5" y="216.2" className="st9" width="3.1" height="3.2"/>
                    <rect x="232.4" y="219.4" className="st9" width="3.1" height="3.2"/>
                    <rect x="232.4" y="247.9" className="st9" width="3.1" height="3.2"/>
                    <rect x="288.5" y="247.9" className="st9" width="3.1" height="3.2"/>
                    <rect x="254.2" y="247.9" className="st9" width="3.1" height="3.2"/>
                    <rect x="266.7" y="247.9" className="st9" width="3.1" height="3.2"/>
                    <rect x="257.3" y="251.1" className="st9" width="3.1" height="3.2"/>
                    <rect x="263.6" y="251.1" className="st9" width="3.1" height="3.2"/>
                    <rect x="229.2" y="254.3" className="st9" width="6.2" height="3.2"/>
                    <rect x="226.1" y="263.8" className="st9" width="9.4" height="3.2"/>
                    <rect x="288.5" y="263.8" className="st9" width="9.4" height="3.2"/>
                    <rect x="235.5" y="260.6" className="st9" width="6.2" height="3.2"/>
                    <rect x="282.3" y="260.6" className="st9" width="6.2" height="3.2"/>
                    <rect x="241.7" y="257.5" className="st9" width="9.4" height="3.2"/>
                    <rect x="272.9" y="257.5" className="st9" width="9.4" height="3.2"/>
                    <rect x="251.1" y="260.6" className="st9" width="21.8" height="3.2"/>
                    <rect x="288.5" y="254.3" className="st9" width="6.2" height="3.2"/>
                    <rect x="235.5" y="251.1" className="st9" width="18.7" height="3.2"/>
                    <rect x="269.8" y="251.1" className="st9" width="18.7" height="3.2"/>
                    <rect x="235.5" y="244.8" className="st9" width="18.7" height="3.2"/>
                    <rect x="269.8" y="244.8" className="st9" width="18.7" height="3.2"/>
                    <rect x="226.1" y="225.7" className="st9" width="3.1" height="3.2"/>
                    <rect x="223" y="228.9" className="st9" width="3.1" height="34.9"/>
                    <rect x="229.2" y="222.5" className="st9" width="3.1" height="31.8"/>
                    <rect x="263.6" y="219.4" className="st9" width="3.1" height="3.2"/>
                    <rect x="266.7" y="216.2" className="st9" width="3.1" height="3.2"/>
                    <rect x="285.4" y="216.2" className="st9" width="3.1" height="3.2"/>
                    <rect x="288.5" y="219.4" className="st9" width="3.1" height="3.2"/>
                    <rect x="294.8" y="225.7" className="st9" width="3.1" height="3.2"/>
                    <rect x="297.9" y="228.9" className="st9" width="3.1" height="34.9"/>
                    <rect x="291.6" y="222.5" className="st9" width="3.1" height="31.8"/>
                    <rect x="269.8" y="213" className="st9" width="15.6" height="3.2"/>
                    <path className="st8" d="M260.4,222.5h3.1v31.8h-3.1V222.5z"/>
                    <path className="st8" d="M254.2,254.3h15.6v3.2h-15.6V254.3z"/>
                    <path className="st8" d="M257.3,219.4h3.1v3.2h-3.1V219.4z"/>
                    <path className="st8" d="M254.2,216.2h3.1v3.2h-3.1V216.2z"/>
                    <path className="st8" d="M238.6,213h15.6v3.2h-15.6V213z"/>
                    <path className="st8" d="M235.5,216.2h3.1v3.2h-3.1V216.2z"/>
                    <path className="st8" d="M232.4,219.4h3.1v3.2h-3.1V219.4z"/>
                    <path className="st8" d="M232.4,247.9h3.1v3.2h-3.1V247.9z"/>
                    <path className="st8" d="M288.5,247.9h3.1v3.2h-3.1V247.9z"/>
                    <path className="st8" d="M254.2,247.9h3.1v3.2h-3.1V247.9z"/>
                    <path className="st8" d="M266.7,247.9h3.1v3.2h-3.1V247.9z"/>
                    <path className="st8" d="M257.3,251.1h3.1v3.2h-3.1V251.1z"/>
                    <path className="st8" d="M263.6,251.1h3.1v3.2h-3.1V251.1z"/>
                    <path className="st8" d="M229.2,254.3h6.2v3.2h-6.2V254.3z"/>
                    <path className="st8" d="M226.1,263.8h9.4v3.2h-9.4V263.8z"/>
                    <path className="st8" d="M288.5,263.8h9.4v3.2h-9.4V263.8z"/>
                    <path className="st8" d="M235.5,260.6h6.2v3.2h-6.2V260.6z"/>
                    <path className="st8" d="M282.3,260.6h6.2v3.2h-6.2V260.6z"/>
                    <path className="st8" d="M241.7,257.5h9.4v3.2h-9.4V257.5z"/>
                    <path className="st8" d="M272.9,257.5h9.4v3.2h-9.4V257.5z"/>
                    <path className="st8" d="M251.1,260.6h21.8v3.2h-21.8V260.6z"/>
                    <path className="st8" d="M288.5,254.3h6.2v3.2h-6.2V254.3z"/>
                    <path className="st8" d="M235.5,251.1h18.7v3.2h-18.7V251.1z"/>
                    <path className="st8" d="M269.8,251.1h18.7v3.2h-18.7V251.1z"/>
                    <path className="st8" d="M235.5,244.8h18.7v3.2h-18.7V244.8z"/>
                    <path className="st8" d="M269.8,244.8h18.7v3.2h-18.7V244.8z"/>
                    <path className="st8" d="M226.1,225.7h3.1v3.2h-3.1V225.7z"/>
                    <path className="st8" d="M223,228.9h3.1v34.9H223V228.9z"/>
                    <path className="st8" d="M229.2,222.5h3.1v31.8h-3.1V222.5z"/>
                    <path className="st8" d="M263.6,219.4h3.1v3.2h-3.1V219.4z"/>
                    <path className="st8" d="M266.7,216.2h3.1v3.2h-3.1V216.2z"/>
                    <path className="st8" d="M285.4,216.2h3.1v3.2h-3.1V216.2z"/>
                    <path className="st8" d="M288.5,219.4h3.1v3.2h-3.1V219.4z"/>
                    <path className="st8" d="M294.8,225.7h3.1v3.2h-3.1V225.7z"/>
                    <path className="st8" d="M297.9,228.9h3.1v34.9h-3.1V228.9z"/>
                    <path className="st8" d="M291.6,222.5h3.1v31.8h-3.1V222.5z"/>
                    <path className="st8" d="M269.8,213h15.6v3.2h-15.6V213z"/>
                    <path className="st8" d="M260.4,251.1v-28.6h-3.1v-3.2h-3.1v-3.2h-15.6v3.2h-3.1v3.2h-3.1v25.4h3.1v-3.2h18.7v3.2h3.1v3.2H260.4z"/>
                    <path className="st8" d="M235.5,247.9v3.2h18.7v-3.2H235.5z"/>
                    <path className="st8" d="M235.5,251.1h-3.1v3.2h3.1V251.1z"/>
                    <path className="st8" d="M254.2,254.3h3.1v-3.2h-3.1V254.3z"/>
                    <path className="st8" d="M266.7,254.3h3.1v-3.2h-3.1V254.3z"/>
                    <path className="st8" d="M288.5,251.1v-3.2h-18.7v3.2H288.5z"/>
                    <path className="st8" d="M291.6,251.1h-3.1v3.2h3.1V251.1z"/>
                    <path className="st8" d="M263.6,222.5v28.6h3.1v-3.2h3.1v-3.2h18.7v3.2h3.1v-25.4h-3.1v-3.2h-3.1v-3.2h-15.6v3.2h-3.1v3.2H263.6z"/>
                    <path className="st8" d="M269.8,254.3v3.2h-15.6v-3.2h-18.7v3.2h-6.2v-3.2v-25.4h-3.1v34.9h9.4v-3.2h6.2v-3.2h9.4v3.2h21.8v-3.2h9.4
                        v3.2h6.2v3.2h9.4v-34.9h-3.1v25.4v3.2h-6.2v-3.2H269.8z"/>
                    <path className="st6" d="M260.4,222.5h3.1v31.8h-3.1V222.5z"/>
                    <path className="st6" d="M254.2,254.3h15.6v3.2h-15.6V254.3z"/>
                    <path className="st6" d="M257.3,219.4h3.1v3.2h-3.1V219.4z"/>
                    <path className="st6" d="M254.2,216.2h3.1v3.2h-3.1V216.2z"/>
                    <path className="st6" d="M238.6,213h15.6v3.2h-15.6V213z"/>
                    <path className="st6" d="M235.5,216.2h3.1v3.2h-3.1V216.2z"/>
                    <path className="st6" d="M232.4,219.4h3.1v3.2h-3.1V219.4z"/>
                    <path className="st6" d="M232.4,247.9h3.1v3.2h-3.1V247.9z"/>
                    <path className="st6" d="M288.5,247.9h3.1v3.2h-3.1V247.9z"/>
                    <path className="st6" d="M254.2,247.9h3.1v3.2h-3.1V247.9z"/>
                    <path className="st6" d="M266.7,247.9h3.1v3.2h-3.1V247.9z"/>
                    <path className="st6" d="M257.3,251.1h3.1v3.2h-3.1V251.1z"/>
                    <path className="st6" d="M263.6,251.1h3.1v3.2h-3.1V251.1z"/>
                    <path className="st6" d="M229.2,254.3h6.2v3.2h-6.2V254.3z"/>
                    <path className="st6" d="M226.1,263.8h9.4v3.2h-9.4V263.8z"/>
                    <path className="st6" d="M288.5,263.8h9.4v3.2h-9.4V263.8z"/>
                    <path className="st6" d="M235.5,260.6h6.2v3.2h-6.2V260.6z"/>
                    <path className="st6" d="M282.3,260.6h6.2v3.2h-6.2V260.6z"/>
                    <path className="st6" d="M241.7,257.5h9.4v3.2h-9.4V257.5z"/>
                    <path className="st6" d="M272.9,257.5h9.4v3.2h-9.4V257.5z"/>
                    <path className="st6" d="M251.1,260.6h21.8v3.2h-21.8V260.6z"/>
                    <path className="st6" d="M288.5,254.3h6.2v3.2h-6.2V254.3z"/>
                    <path className="st6" d="M235.5,251.1h18.7v3.2h-18.7V251.1z"/>
                    <path className="st6" d="M269.8,251.1h18.7v3.2h-18.7V251.1z"/>
                    <path className="st6" d="M235.5,244.8h18.7v3.2h-18.7V244.8z"/>
                    <path className="st6" d="M269.8,244.8h18.7v3.2h-18.7V244.8z"/>
                    <path className="st6" d="M226.1,225.7h3.1v3.2h-3.1V225.7z"/>
                    <path className="st6" d="M223,228.9h3.1v34.9H223V228.9z"/>
                    <path className="st6" d="M229.2,222.5h3.1v31.8h-3.1V222.5z"/>
                    <path className="st6" d="M263.6,219.4h3.1v3.2h-3.1V219.4z"/>
                    <path className="st6" d="M266.7,216.2h3.1v3.2h-3.1V216.2z"/>
                    <path className="st6" d="M285.4,216.2h3.1v3.2h-3.1V216.2z"/>
                    <path className="st6" d="M288.5,219.4h3.1v3.2h-3.1V219.4z"/>
                    <path className="st6" d="M294.8,225.7h3.1v3.2h-3.1V225.7z"/>
                    <path className="st6" d="M297.9,228.9h3.1v34.9h-3.1V228.9z"/>
                    <path className="st6" d="M291.6,222.5h3.1v31.8h-3.1V222.5z"/>
                    <path className="st6" d="M269.8,213h15.6v3.2h-15.6V213z"/>
                    <path className="st6" d="M260.4,251.1v-28.6h-3.1v-3.2h-3.1v-3.2h-15.6v3.2h-3.1v3.2h-3.1v25.4h3.1v-3.2h18.7v3.2h3.1v3.2H260.4z"/>
                    <path className="st6" d="M235.5,247.9v3.2h18.7v-3.2H235.5z"/>
                    <path className="st6" d="M235.5,251.1h-3.1v3.2h3.1V251.1z"/>
                    <path className="st6" d="M254.2,254.3h3.1v-3.2h-3.1V254.3z"/>
                    <path className="st6" d="M266.7,254.3h3.1v-3.2h-3.1V254.3z"/>
                    <path className="st6" d="M288.5,251.1v-3.2h-18.7v3.2H288.5z"/>
                    <path className="st6" d="M291.6,251.1h-3.1v3.2h3.1V251.1z"/>
                    <path className="st6" d="M263.6,222.5v28.6h3.1v-3.2h3.1v-3.2h18.7v3.2h3.1v-25.4h-3.1v-3.2h-3.1v-3.2h-15.6v3.2h-3.1v3.2H263.6z"/>
                    <path className="st7" d="M260.4,222.5h3.1v31.8h-3.1V222.5z"/>
                    <path className="st7" d="M254.2,254.3h15.6v3.2h-15.6V254.3z"/>
                    <path className="st7" d="M257.3,219.4h3.1v3.2h-3.1V219.4z"/>
                    <path className="st7" d="M254.2,216.2h3.1v3.2h-3.1V216.2z"/>
                    <path className="st7" d="M238.6,213h15.6v3.2h-15.6V213z"/>
                    <path className="st7" d="M235.5,216.2h3.1v3.2h-3.1V216.2z"/>
                    <path className="st7" d="M232.4,219.4h3.1v3.2h-3.1V219.4z"/>
                    <path className="st7" d="M232.4,247.9h3.1v3.2h-3.1V247.9z"/>
                    <path className="st7" d="M288.5,247.9h3.1v3.2h-3.1V247.9z"/>
                    <path className="st7" d="M254.2,247.9h3.1v3.2h-3.1V247.9z"/>
                    <path className="st7" d="M266.7,247.9h3.1v3.2h-3.1V247.9z"/>
                    <path className="st7" d="M257.3,251.1h3.1v3.2h-3.1V251.1z"/>
                    <path className="st7" d="M263.6,251.1h3.1v3.2h-3.1V251.1z"/>
                    <path className="st7" d="M229.2,254.3h6.2v3.2h-6.2V254.3z"/>
                    <path className="st7" d="M226.1,263.8h9.4v3.2h-9.4V263.8z"/>
                    <path className="st7" d="M288.5,263.8h9.4v3.2h-9.4V263.8z"/>
                    <path className="st7" d="M235.5,260.6h6.2v3.2h-6.2V260.6z"/>
                    <path className="st7" d="M282.3,260.6h6.2v3.2h-6.2V260.6z"/>
                    <path className="st7" d="M241.7,257.5h9.4v3.2h-9.4V257.5z"/>
                    <path className="st7" d="M272.9,257.5h9.4v3.2h-9.4V257.5z"/>
                    <path className="st7" d="M251.1,260.6h21.8v3.2h-21.8V260.6z"/>
                    <path className="st7" d="M288.5,254.3h6.2v3.2h-6.2V254.3z"/>
                    <path className="st7" d="M235.5,251.1h18.7v3.2h-18.7V251.1z"/>
                    <path className="st7" d="M269.8,251.1h18.7v3.2h-18.7V251.1z"/>
                    <path className="st7" d="M235.5,244.8h18.7v3.2h-18.7V244.8z"/>
                    <path className="st7" d="M269.8,244.8h18.7v3.2h-18.7V244.8z"/>
                    <path className="st7" d="M226.1,225.7h3.1v3.2h-3.1V225.7z"/>
                    <path className="st7" d="M223,228.9h3.1v34.9H223V228.9z"/>
                    <path className="st7" d="M229.2,222.5h3.1v31.8h-3.1V222.5z"/>
                    <path className="st7" d="M263.6,219.4h3.1v3.2h-3.1V219.4z"/>
                    <path className="st7" d="M266.7,216.2h3.1v3.2h-3.1V216.2z"/>
                    <path className="st7" d="M285.4,216.2h3.1v3.2h-3.1V216.2z"/>
                    <path className="st7" d="M288.5,219.4h3.1v3.2h-3.1V219.4z"/>
                    <path className="st7" d="M294.8,225.7h3.1v3.2h-3.1V225.7z"/>
                    <path className="st7" d="M297.9,228.9h3.1v34.9h-3.1V228.9z"/>
                    <path className="st7" d="M291.6,222.5h3.1v31.8h-3.1V222.5z"/>
                    <path className="st7" d="M269.8,213h15.6v3.2h-15.6V213z"/>
                    <rect x="248" y="231.4" className="st7" width="3.1" height="3.2"/>
                    <rect x="248" y="221.9" className="st7" width="3.1" height="3.2"/>
                    <rect x="241.7" y="231.4" className="st7" width="3.1" height="3.2"/>
                    <rect x="235.5" y="234.6" className="st7" width="3.1" height="3.2"/>
                    <rect x="235.5" y="225.1" className="st7" width="3.1" height="3.2"/>
                    <rect x="241.7" y="221.9" className="st7" width="3.1" height="3.2"/>
                    <rect x="254.2" y="234.6" className="st7" width="3.1" height="3.2"/>
                    <rect x="254.2" y="225.1" className="st7" width="3.1" height="3.2"/>
                    <rect x="279.2" y="231.4" className="st7" width="3.1" height="3.2"/>
                    <rect x="279.2" y="221.9" className="st7" width="3.1" height="3.2"/>
                    <rect x="272.9" y="231.4" className="st7" width="3.1" height="3.2"/>
                    <rect x="266.7" y="234.6" className="st7" width="3.1" height="3.2"/>
                    <rect x="266.7" y="225.1" className="st7" width="3.1" height="3.2"/>
                    <rect x="272.9" y="221.9" className="st7" width="3.1" height="3.2"/>
                    <rect x="285.4" y="234.6" className="st7" width="3.1" height="3.2"/>
                    <rect x="285.4" y="225.1" className="st7" width="3.1" height="3.2"/>
                    </g>
                    <g id="large-land">
                    <path className="st0" d="M364.9,321.2v-3.4H158v7.6h5.2v16h4.5v12.6h11.2v5.1h3.7v12.6h8.2v-5.1h-2.2v-10.1h8.2v5.1h6v-5.1h8.2v8.4
                        h11.9v6.7h6v-5.1h7.4v5.1h13.4v-2.5h3.7v2.5h17.9V365h6v6.7h6v5.9h8.2v3.4h4.5v-6.7h6.7v-17.7H315l0.7,5.1h6v12.6h7.4v-5.1h6v2.5
                        h3.7V365h-3.7v-5.9H353v-5.1h6.7v-12.6h6.7v-16h6v-4.2H364.9z"/>
                    <path className="st1" d="M171.9,320.3v-2.5H361v5.6h-4.8v11.8h-4.1v9.3H342v3.7h-3.4v9.3h-7.5v-3.7h2v-7.4h-7.5v3.7h-5.4v-3.7h-7.5v6.2
                        h-10.9v5h-5.4v-3.7h-6.8v3.7h-12.2v-1.9h-3.4v1.9h-16.3v-5h-5.4v5h-5.4v4.3h-7.5v2.5h-4.1v-5H229v-13h-11.6l-0.7,3.7h-5.4v9.3h-6.8
                        v-3.7h-5.4v1.9h-3.4v-5h3.4v-4.3h-16.3v-3.7h-6.1v-9.3h-6.1v-11.8h-5.4v-3.1H171.9z"/>
                    <path className="st2" d="M396.8,319.3v-1.4H124v3.2h6.9v6.7h5.9v5.3h14.7v2.1h4.9v5.3h10.8v-2.1h-2.9v-4.2h10.8v2.1h7.8v-2.1h10.8v3.5
                        h15.7v2.8h7.9v-2.1h9.8v2.1h17.7v-1.1h4.9v1.1h23.6v-2.8h7.9v2.8h7.9v2.5h10.8v1.4h5.9v-2.8h8.8v-7.4h16.7l1,2.1h7.8v5.3h9.8v-2.1
                        h7.8v1.1h4.9v-2.8h-4.9v-2.5h23.6V333h8.8v-5.3h8.8V321h7.9v-1.8H396.8z"/>
                    <path className="st3" d="M365.4,297.8V293H125.6v6.7H119v6.7h-5v31.7h3.3h4.1v-7.7h9.1v11.5h5v-17.3h18.2v5.8h14.1v-5.8h7.4v13.4h5.8
                        v-4.8h6.6v8.6h5.8v-8.6h5.8v-8.6h9.9v5.8h6.6v-5.8H229v5.8h6.6v11.5h5.8v-11.5h5v-3.8h12.4v-6.7h6.6v4.8h8.3v13.4h7.4v-11.5h13.2
                        v3.8h9.9v4.8h6.6v-4.8h7.4v-5.8h16.5v-9.6h12.4v-6.7h-4.1v-10.6H365.4z"/>
                    <path className="st4" d="M362.1,297.1V293H129.1v5.7h-6.4v5.7h-4.8v27h3.2h4v-6.5h8.8v9.8h4.8V320h17.7v4.9h13.7V320h7.2v11.4h5.6v-4.1
                        h6.4v7.4h5.6v-7.4h5.6V320h9.6v4.9h6.4V320h12.8v4.9h6.4v9.8h5.6v-9.8h4.8v-3.3h12v-5.7h6.4v4.1h8v11.4h7.2v-9.8H293v3.3h9.6v4.1
                        h6.4v-4.1h7.2V320h16.1v-8.2h12v-5.7h-4v-9H362.1z"/>
                    <path className="st5" d="M391,295.5V293H130.5v3.5h-7.2v3.5h-5.4v16.5h3.6h4.5v-4h9.9v6h5.4v-9H161v3h15.3v-3h8.1v7h6.3v-2.5h7.2v4.5
                        h6.3v-4.5h6.3v-4.5h10.8v3h7.2v-3h14.4v3h7.2v6h6.3v-6h5.4v-2h13.5V307h7.2v2.5h9v7h8.1v-6h14.4v2h10.8v2.5h7.2v-2.5h8.1v-3h18v-5
                        h13.5V301h-4.5v-5.5H391z"/>
                    <path className="st3" d="M181.4,297.8V293h218.1v6.7h6v6.7h4.5v31.7h-3h-3.8v-7.7H395v11.5h-4.5v-17.3h-16.5v5.8h-12.8v-5.8h-6.8v13.4
                        h-5.3v-4.8h-6v8.6h-5.3v-8.6h-5.3v-8.6h-9v5.8h-6v-5.8h-12v5.8h-6v11.5h-5.3v-11.5h-4.5v-3.8h-11.3v-6.7h-6v4.8h-7.5v13.4h-6.8
                        v-11.5h-12v3.8h-9v4.8h-6v-4.8h-6.8v-5.8h-15v-9.6h-11.3v-6.7h3.8v-10.6H181.4z"/>
                    <path className="st4" d="M174.8,297.1V293H396v5.7h6.1v5.7h4.6v27h-3.1h-3.8v-6.5h-8.4v9.8h-4.6V320H370v4.9h-13V320h-6.9v11.4h-5.3
                        v-4.1h-6.1v7.4h-5.3v-7.4h-5.3V320h-9.2v4.9h-6.1V320h-12.2v4.9h-6.1v9.8h-5.3v-9.8h-4.6v-3.3h-11.4v-5.7h-6.1v4.1h-7.6v11.4h-6.9
                        v-9.8h-12.2v3.3h-9.2v4.1h-6.1v-4.1h-6.9V320H203v-8.2h-11.4v-5.7h3.8v-9H174.8z"/>
                    <path className="st5" d="M139.6,295.5V293h254.7v3.5h7v3.5h5.3v16.5h-3.5h-4.4v-4h-9.7v6h-5.3v-9h-19.3v3h-14.9v-3h-7.9v7h-6.1v-2.5h-7
                        v4.5h-6.1v-4.5h-6.1v-4.5h-10.5v3h-7v-3h-14.1v3h-7v6h-6.1v-6h-5.3v-2h-13.2V307h-7v2.5h-8.8v7h-7.9v-6h-14.1v2h-10.5v2.5h-7v-2.5
                        h-7.9v-3h-17.6v-5H159V301h4.4v-5.5H139.6z"/>
                    </g>
                </g>
                <g id="small-land">
                <path className="st10" d="M99.3,178.3v-2.7H4.9v16.7h3.7v7.4h4.9v4.7h6.3v3.5h12.8v-6.6h6.2v7.7h7.1h6.4v-7.7h10.4v5.5h6.2v4.6h6.9
                    v-10.2h9.5v3.2h9.9V197h6.7v-15.4v-3.4H99.3z"/>
                <path className="st11" d="M73.2,195.1h-4.9v3h-13H51v3.6h-5.4v-2.1h-4.9v-8.3h-9.2v10.4h-4.9v4h-3.2v-2h-5.9v-3.5h-4.3v-4H8.9v-27.7
                    h64.3v11.7h12.4v0.7h16.5v7.1h-4.4v6.9h-8.2v-4.6h-3.9v2.5h-6.2v5.9h-6.2V195.1z"/>
                <path className="st12" d="M75.8,192.3v0.8h-14v-1.7H54v1.7h-6.2v3.1H35.3v-8.1h-8.6v7h-6.2v-7h-3.3v3.4h-3v1.7H5.7v-4.2H1.8v-1.7v-12.1
                    h74v1.3h20.3v8.4H85.5v-1.6h-4.6v4.7h-5V192.3z"/>
                <path className="st3" d="M32.3,182.8V180v-25.2h65.1v5.3h4.8v5.3h3.8v25.2h-2.6h-3v-6.1h-6.6v3.9h-3.6V180H77.1v4.6H67V180h-3.6v10.3
                    h-6v-3.4h-4.8v5.1h-4.2v-5.1h-4.2V180h-3.6v2.8H32.3z"/>
                <path className="st3" d="M37.4,185.9v-4.6v-25.2H9.2v5.3H3.9v5.3H0V192h2.6h3.3v-2.6h7.2v5.6h5.7v-13.8h12.7v4.6H37.4z"/>
                <path className="st4" d="M72.4,174.5h3.7V153H9.6v4.6H4.8v4.6H1.3v21.5h2.3h3v-5.2h6.7h3.6v-3.9h5.4v2.3h11.3v-2.3h5.5v5.7h6.8v-5.7
                    h3.7v9.1h7.8v-3.3h3.1v4.1h4.2v-4.1h7.8V174.5z"/>
                <path className="st4" d="M63.8,178.4v-3.9V153h29v4.6h5.1v4.6h3.8v21.5h-2.6h-3.2v-5.2h-5.2v4.3h-3.8v-8.2H74.6v3.9H63.8z"/>
                <path className="st5" d="M20.8,167.6h16.4V153H11.8v3.1h-6v3.1H1.3v14.6h3h3.7v-3.5h8.2v5.3h4.5V167.6z"/>
                <path className="st5" d="M21.7,171.6v-4V153H89v3.1h5.9v3.1h4.3v14.6h-2.8h-3.7v-3.5h-8.1v5.3h-4.4v-8H64v2.7H51.5v-2.7h-6.6v6.2h-5.1
                    v-2.2h-5.9v4h-5.1v-4H21.7z"/>
                </g>
            </svg>
        </div>
    </>
  )
}