import React from "react";
import { TorchImage } from "./svg/torch-svg";

export const Staff = ({data}) => {
  return (
  <section id='staff' className='container pb-0'>
    <div className='row justify-content-center'>
      <div className='col-12 text-center mb-5'>
        <h2 className='heading mx-auto mb-0'>
          <TorchImage widthImage={'3rem'} heightImage={'3rem'} className={'torchLeft'}/>
          {data ? data.heading : 'Loading'}
          <TorchImage widthImage={'3rem'} heightImage={'3rem'} className={'torchRight'}/>
        </h2>
      </div>
      <div className='col-12'>
        <div className='row justify-content-evenly'>
          {data
          ? data.StaffList.map((d, i) => (
          <div key={`${d.name}-${i}`} className=' member col-12 col-sm-6 col-lg-3 mb-5 mb-sm-0'>
            <div className="img-wrapper">
              <img width="260" height="260" className="w-100" src={d.img} alt='...' />
              <div className="member-social text-center">
                <a rel="noopener noreferrer" target="_blank" className="mx-3 mx-sm-2" href={`${d ? d.linkTwitter : '#' }`}
                  title={`Follow ${d.name} on Twitter`}>
                  <img className="icon" src="../img/twitter-primary.svg" alt='...' />
                </a>
                <a rel="noopener noreferrer" target="_blank" className="mx-3 mx-sm-2" href={`mailto:${d ? d.mailMember
                  : '#' }`} title={`Mail to ${d.name}`}>
                  <img className="icon" src="../img/email.svg" alt='...' />
                </a>
              </div>
            </div>
            <div className='caption text-center mb-2'>
              <h3 className='h6 text-white mb-0 mt-2'>{d.name}</h3>
              <p className='text-small text-gray'>{d.job}</p>
            </div>
          </div>
          ))
          : 'loading'}
        </div>
      </div>
    </div>
  </section>
  )
}