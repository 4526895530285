import { configureStore, createSlice } from '@reduxjs/toolkit'

const defaultValue = {
  show: false,
  title: '',
  content: '',
  icon: '',
  job: ''
}

export const uiSlice = createSlice({
  name: 'ui',
  initialState: { modal: defaultValue },
  reducers: {
    showModal: (state, action) => {
      state.modal = {
        show: true,
        title: action.payload.title,
        content: action.payload.content,
        icon: action.payload.icon,
        job: action.payload.job
      }
    },
    hideModal: (state) => {
      state.modal = defaultValue
    }
  },
})

// Action creators are generated for each case reducer function
export const { showModal, hideModal } = uiSlice.actions

export default configureStore({
  reducer: {
    ui: uiSlice.reducer
  },
})