import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux'
import { hideModal } from '../store/store'
import ReactMarkdown from "react-markdown";

const AppModal = () => {
  const { show, title, job, content } = useSelector((state) => state.ui.modal)
  const dispatch = useDispatch()

  return (
    <>
      <Modal dialogClassName={"teamModal modal-lg modal-dialog-centered"} show={show} onHide={() => dispatch(hideModal())}>
        <Modal.Header closeButton>
          <Modal.Title>{title}<div className="modal-job">{job}</div></Modal.Title>
        </Modal.Header>
        <Modal.Body><ReactMarkdown>{content}</ReactMarkdown></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary d-none" onClick={() => dispatch(hideModal())}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AppModal;