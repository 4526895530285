import React from "react";
import { Soon } from "../../components/soon";
import { usePageData } from "../../hooks/app-hooks";
import {Helmet} from "react-helmet";
import { withNotFoundLayout } from '../../layouts'

const SoonPage = () => {
    const PageData = usePageData();
    return (
        <>
            <Helmet>
            <title>Coming Soon</title>
            <meta name="description" content=""/>
            <meta property="og:title" content="Coming Soon"/>
            <meta name="keywords" content="" />
            <meta property="og:description" content="" />
            </Helmet>
            <Soon data={PageData.NotFound} />
        </>
    );
};

export default withNotFoundLayout(SoonPage);
