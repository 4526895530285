import React from "react";
export const MainImage = () => {
  return (
    <>
        <svg id="footer-background" width="1440" height="600" viewBox="0 0 1440 600" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="torch">
                <g className="st93">
                <path className="st94" d="M305.7,151.8h-12.1v4.1h-4.7v3.4h-3.7v5.1h-4v12.5h4v4.1h3.7v4.1h4.7v4.4h12.1v-4.4h4.7v-4.1h4.4v-4.1h4
                    v-12.5h-4v-5.1h-4.4v-3.4h-4.7V151.8z"/>
                <path className="st95" d="M306.1,156.5h-12.2v4.1h-4.4v4.1h-4.4v12.5h4.4v4h4.4v4.4h12.2v-4.4h4v-4h4.4v-12.5h-4.4v-4.1h-4V156.5z"
                    />
                <path className="st96" d="M293.2,161.2h11.8v3.7h4.7v12.2h-4.7v3.4h-11.8v-3.4h-3.4V165h3.4V161.2z"/>
                </g>
                <rect x="289.2" y="182.4" className="st97" width="21.6" height="2.7"/>
                <rect x="293.7" y="185.1" className="st97" width="2.7" height="2.7"/>
                <rect x="296.4" y="185.1" className="st98" width="2.7" height="2.7"/>
                <rect x="299.1" y="185.1" className="st99" width="2.7" height="2.7"/>
                <rect x="301.8" y="185.1" className="st86" width="2.7" height="2.7"/>
                <rect x="296.4" y="187.8" className="st100" width="2.7" height="2.7"/>
                <rect x="301.8" y="187.8" className="st100" width="2.7" height="2.7"/>
                <rect x="299.1" y="187.8" className="st101" width="2.7" height="2.7"/>
                <rect x="293.7" y="187.8" className="st97" width="2.7" height="2.7"/>
                <rect x="304.5" y="185.1" className="st97" width="2.7" height="2.7"/>
                <rect x="296.4" y="190.5" className="st97" width="2.7" height="2.7"/>
                <rect x="301.8" y="190.5" className="st97" width="2.7" height="2.7"/>
                <rect x="296.4" y="193.2" className="st97" width="2.7" height="2.7"/>
                <rect x="296.4" y="195.9" className="st97" width="2.7" height="2.7"/>
                <rect x="304.5" y="187.8" className="st97" width="2.7" height="2.7"/>
                <rect x="301.8" y="193.2" className="st97" width="2.7" height="2.7"/>
                <rect x="301.8" y="195.9" className="st97" width="2.7" height="2.7"/>
                <rect x="299.1" y="198.6" className="st97" width="2.7" height="2.7"/>
                <rect x="299.1" y="195.9" className="st87" width="2.7" height="2.7"/>
                <rect x="299.1" y="193.2" className="st102" width="2.7" height="2.7"/>
                <rect x="299.1" y="190.5" className="st102" width="2.7" height="2.7"/>
                <path className="st84" d="M289.2,179.2v3.1h21.6v-3.1h-3.1v-5.9H305v-6.3h-5.9v3.1H296v2.7h-3.2v6.3H289.2z"/>
                <path className="st103" d="M304.5,173.4v9h-5.4v-11.7h2.7v2.7H304.5z"/>
                <rect x="292.8" y="167.1" className="flame3 st83" width="2.7" height="2.7"/>
                <rect x="296.4" y="158.1" className="flame1 st83" width="2.7" height="2.7"/>
                <rect x="309" y="168" className="flame2 st83" width="2.7" height="2.7"/>
                <rect x="295.5" y="175.2" className="st83" width="3.6" height="3.6"/>
                <rect x="295.5" y="178.8" className="st83" width="3.6" height="3.6"/>
            </g>
            <g id="torch_2">
                <g className="st93">
                <path className="st94" d="M1109.7,147.8h-12.2v4.1h-4.7v3.4h-3.7v5.1h-4.1v12.5h4.1v4.1h3.7v4.1h4.7v4.4h12.2v-4.4h4.7v-4.1h4.4
                    v-4.1h4.1v-12.5h-4.1v-5.1h-4.4v-3.4h-4.7V147.8z"/>
                <path className="st95" d="M1110.1,152.5h-12.1v4.1h-4.4v4.1h-4.4v12.5h4.4v4h4.4v4.4h12.1v-4.4h4.1v-4h4.4v-12.5h-4.4v-4.1h-4.1
                    V152.5z"/>
                <path className="st96" d="M1097.2,157.2h11.8v3.7h4.7v12.2h-4.7v3.4h-11.8v-3.4h-3.4V161h3.4V157.2z"/>
                </g>
                <rect x="1093.2" y="178.4" className="st97" width="21.6" height="2.7"/>
                <rect x="1097.7" y="181.1" className="st97" width="2.7" height="2.7"/>
                <rect x="1100.4" y="181.1" className="st98" width="2.7" height="2.7"/>
                <rect x="1103.1" y="181.1" className="st99" width="2.7" height="2.7"/>
                <rect x="1105.8" y="181.1" className="st86" width="2.7" height="2.7"/>
                <rect x="1100.4" y="183.8" className="st100" width="2.7" height="2.7"/>
                <rect x="1105.8" y="183.8" className="st100" width="2.7" height="2.7"/>
                <rect x="1103.1" y="183.8" className="st101" width="2.7" height="2.7"/>
                <rect x="1097.7" y="183.8" className="st97" width="2.7" height="2.7"/>
                <rect x="1108.5" y="181.1" className="st97" width="2.7" height="2.7"/>
                <rect x="1100.4" y="186.5" className="st97" width="2.7" height="2.7"/>
                <rect x="1105.8" y="186.5" className="st97" width="2.7" height="2.7"/>
                <rect x="1100.4" y="189.2" className="st97" width="2.7" height="2.7"/>
                <rect x="1100.4" y="191.9" className="st97" width="2.7" height="2.7"/>
                <rect x="1108.5" y="183.8" className="st97" width="2.7" height="2.7"/>
                <rect x="1105.8" y="189.2" className="st97" width="2.7" height="2.7"/>
                <rect x="1105.8" y="191.9" className="st97" width="2.7" height="2.7"/>
                <rect x="1103.1" y="194.6" className="st97" width="2.7" height="2.7"/>
                <rect x="1103.1" y="191.9" className="st87" width="2.7" height="2.7"/>
                <rect x="1103.1" y="189.2" className="st102" width="2.7" height="2.7"/>
                <rect x="1103.1" y="186.5" className="st102" width="2.7" height="2.7"/>
                <path className="st84" d="M1093.2,175.2v3.1h21.6v-3.1h-3.2v-5.9h-2.7v-6.3h-5.8v3.1h-3.2v2.7h-3.1v6.3H1093.2z"/>
                <path className="st103" d="M1108.5,169.4v9h-5.4v-11.7h2.7v2.7H1108.5z"/>
                <rect x="1096.8" y="163.1" className="flame3 st83" width="2.7" height="2.7"/>
                <rect x="1100.4" y="154.1" className="flame1 st83" width="2.7" height="2.7"/>
                <rect x="1113" y="164" className="flame2 st83" width="2.7" height="2.7"/>
                <rect x="1099.5" y="171.2" className="st83" width="3.6" height="3.6"/>
                <rect x="1099.5" y="174.8" className="st83" width="3.6" height="3.6"/>
            </g>
            <g id="torch_3">
                <g className="st93">
                <path className="st94" d="M130.7,182.8h-12.2v4.1h-4.7v3.4h-3.7v5.1h-4.1v12.5h4.1v4.1h3.7v4.1h4.7v4.4h12.2v-4.4h4.7v-4.1h4.4v-4.1
                    h4v-12.5h-4v-5.1h-4.4v-3.4h-4.7V182.8z"/>
                <path className="st95" d="M131.1,187.5h-12.1v4.1h-4.4v4.1h-4.4v12.5h4.4v4h4.4v4.4h12.1v-4.4h4.1v-4h4.4v-12.5h-4.4v-4.1h-4.1
                    V187.5z"/>
                <path className="st96" d="M118.2,192.2h11.8v3.7h4.7v12.2h-4.7v3.4h-11.8v-3.4h-3.4V196h3.4V192.2z"/>
                </g>
                <rect x="114.2" y="213.4" className="st97" width="21.6" height="2.7"/>
                <rect x="118.7" y="216.1" className="st97" width="2.7" height="2.7"/>
                <rect x="121.4" y="216.1" className="st98" width="2.7" height="2.7"/>
                <rect x="124.1" y="216.1" className="st99" width="2.7" height="2.7"/>
                <rect x="126.8" y="216.1" className="st86" width="2.7" height="2.7"/>
                <rect x="121.4" y="218.8" className="st100" width="2.7" height="2.7"/>
                <rect x="126.8" y="218.8" className="st100" width="2.7" height="2.7"/>
                <rect x="124.1" y="218.8" className="st101" width="2.7" height="2.7"/>
                <rect x="118.7" y="218.8" className="st97" width="2.7" height="2.7"/>
                <rect x="129.5" y="216.1" className="st97" width="2.7" height="2.7"/>
                <rect x="121.4" y="221.5" className="st97" width="2.7" height="2.7"/>
                <rect x="126.8" y="221.5" className="st97" width="2.7" height="2.7"/>
                <rect x="121.4" y="224.2" className="st97" width="2.7" height="2.7"/>
                <rect x="121.4" y="226.9" className="st97" width="2.7" height="2.7"/>
                <rect x="129.5" y="218.8" className="st97" width="2.7" height="2.7"/>
                <rect x="126.8" y="224.2" className="st97" width="2.7" height="2.7"/>
                <rect x="126.8" y="226.9" className="st97" width="2.7" height="2.7"/>
                <rect x="124.1" y="229.6" className="st97" width="2.7" height="2.7"/>
                <rect x="124.1" y="226.9" className="st87" width="2.7" height="2.7"/>
                <rect x="124.1" y="224.2" className="st102" width="2.7" height="2.7"/>
                <rect x="124.1" y="221.5" className="st102" width="2.7" height="2.7"/>
                <path className="st84" d="M114.2,210.2v3.1h21.6v-3.1h-3.2v-5.9h-2.7v-6.3h-5.8v3.1h-3.2v2.7h-3.1v6.3H114.2z"/>
                <path className="st103" d="M129.5,204.4v9h-5.4v-11.7h2.7v2.7H129.5z"/>
                <rect x="117.8" y="198.1" className="flame3 st83" width="2.7" height="2.7"/>
                <rect x="121.4" y="189.1" className="flame1 st83" width="2.7" height="2.7"/>
                <rect x="134" y="199" className="flame2 st83" width="2.7" height="2.7"/>
                <rect x="120.5" y="206.2" className="st83" width="3.6" height="3.6"/>
                <rect x="120.5" y="209.8" className="st83" width="3.6" height="3.6"/>
            </g>
            <g id="torch_4">
                <g className="st93">
                <path className="st94" d="M920.7,149.8h-12.1v4.1h-4.7v3.4h-3.7v5.1h-4.1v12.5h4.1v4.1h3.7v4.1h4.7v4.4h12.1v-4.4h4.7v-4.1h4.4v-4.1
                    h4.1v-12.5h-4.1v-5.1h-4.4v-3.4h-4.7V149.8z"/>
                <path className="st95" d="M921.1,154.5h-12.2v4.1h-4.4v4.1h-4.4v12.5h4.4v4h4.4v4.4h12.2v-4.4h4v-4h4.4v-12.5h-4.4v-4.1h-4V154.5z"
                    />
                <path className="st96" d="M908.2,159.2h11.8v3.7h4.7v12.2h-4.7v3.4h-11.8v-3.4h-3.4V163h3.4V159.2z"/>
                </g>
                <rect x="904.2" y="180.4" className="st97" width="21.6" height="2.7"/>
                <rect x="908.7" y="183.1" className="st97" width="2.7" height="2.7"/>
                <rect x="911.4" y="183.1" className="st98" width="2.7" height="2.7"/>
                <rect x="914.1" y="183.1" className="st99" width="2.7" height="2.7"/>
                <rect x="916.8" y="183.1" className="st86" width="2.7" height="2.7"/>
                <rect x="911.4" y="185.8" className="st100" width="2.7" height="2.7"/>
                <rect x="916.8" y="185.8" className="st100" width="2.7" height="2.7"/>
                <rect x="914.1" y="185.8" className="st101" width="2.7" height="2.7"/>
                <rect x="908.7" y="185.8" className="st97" width="2.7" height="2.7"/>
                <rect x="919.5" y="183.1" className="st97" width="2.7" height="2.7"/>
                <rect x="911.4" y="188.5" className="st97" width="2.7" height="2.7"/>
                <rect x="916.8" y="188.5" className="st97" width="2.7" height="2.7"/>
                <rect x="911.4" y="191.2" className="st97" width="2.7" height="2.7"/>
                <rect x="911.4" y="193.9" className="st97" width="2.7" height="2.7"/>
                <rect x="919.5" y="185.8" className="st97" width="2.7" height="2.7"/>
                <rect x="916.8" y="191.2" className="st97" width="2.7" height="2.7"/>
                <rect x="916.8" y="193.9" className="st97" width="2.7" height="2.7"/>
                <rect x="914.1" y="196.6" className="st97" width="2.7" height="2.7"/>
                <rect x="914.1" y="193.9" className="st87" width="2.7" height="2.7"/>
                <rect x="914.1" y="191.2" className="st102" width="2.7" height="2.7"/>
                <rect x="914.1" y="188.5" className="st102" width="2.7" height="2.7"/>
                <path className="st84" d="M904.2,177.2v3.1h21.6v-3.1h-3.1v-5.9H920v-6.3h-5.9v3.1H911v2.7h-3.2v6.3H904.2z"/>
                <path className="st103" d="M919.5,171.4v9h-5.4v-11.7h2.7v2.7H919.5z"/>
                <rect x="907.8" y="165.1" className="flame3 st83" width="2.7" height="2.7"/>
                <rect x="911.4" y="156.1" className="flame1 st83" width="2.7" height="2.7"/>
                <rect x="924" y="166" className="flame2 st83" width="2.7" height="2.7"/>
                <rect x="910.5" y="173.2" className="st83" width="3.6" height="3.6"/>
                <rect x="910.5" y="176.8" className="st83" width="3.6" height="3.6"/>
            </g>
            <g id="torch_5">
                <g className="st93">
                <path className="st94" d="M1316.7,184.8h-12.2v4.1h-4.7v3.4h-3.7v5.1h-4.1v12.5h4.1v4.1h3.7v4.1h4.7v4.4h12.2v-4.4h4.7v-4.1h4.4
                    v-4.1h4.1v-12.5h-4.1v-5.1h-4.4v-3.4h-4.7V184.8z"/>
                <path className="st95" d="M1317.1,189.5h-12.1v4.1h-4.4v4.1h-4.4v12.5h4.4v4h4.4v4.4h12.1v-4.4h4.1v-4h4.4v-12.5h-4.4v-4.1h-4.1
                    V189.5z"/>
                <path className="st96" d="M1304.2,194.2h11.8v3.7h4.7v12.2h-4.7v3.4h-11.8v-3.4h-3.4V198h3.4V194.2z"/>
                </g>
                <rect x="1300.2" y="215.4" className="st97" width="21.6" height="2.7"/>
                <rect x="1304.7" y="218.1" className="st97" width="2.7" height="2.7"/>
                <rect x="1307.4" y="218.1" className="st98" width="2.7" height="2.7"/>
                <rect x="1310.1" y="218.1" className="st99" width="2.7" height="2.7"/>
                <rect x="1312.8" y="218.1" className="st86" width="2.7" height="2.7"/>
                <rect x="1307.4" y="220.8" className="st100" width="2.7" height="2.7"/>
                <rect x="1312.8" y="220.8" className="st100" width="2.7" height="2.7"/>
                <rect x="1310.1" y="220.8" className="st101" width="2.7" height="2.7"/>
                <rect x="1304.7" y="220.8" className="st97" width="2.7" height="2.7"/>
                <rect x="1315.5" y="218.1" className="st97" width="2.7" height="2.7"/>
                <rect x="1307.4" y="223.5" className="st97" width="2.7" height="2.7"/>
                <rect x="1312.8" y="223.5" className="st97" width="2.7" height="2.7"/>
                <rect x="1307.4" y="226.2" className="st97" width="2.7" height="2.7"/>
                <rect x="1307.4" y="228.9" className="st97" width="2.7" height="2.7"/>
                <rect x="1315.5" y="220.8" className="st97" width="2.7" height="2.7"/>
                <rect x="1312.8" y="226.2" className="st97" width="2.7" height="2.7"/>
                <rect x="1312.8" y="228.9" className="st97" width="2.7" height="2.7"/>
                <rect x="1310.1" y="231.6" className="st97" width="2.7" height="2.7"/>
                <rect x="1310.1" y="228.9" className="st87" width="2.7" height="2.7"/>
                <rect x="1310.1" y="226.2" className="st102" width="2.7" height="2.7"/>
                <rect x="1310.1" y="223.5" className="st102" width="2.7" height="2.7"/>
                <path className="st84" d="M1300.2,212.2v3.1h21.6v-3.1h-3.2v-5.9h-2.7v-6.3h-5.8v3.1h-3.2v2.7h-3.1v6.3H1300.2z"/>
                <path className="st103" d="M1315.5,206.4v9h-5.4v-11.7h2.7v2.7H1315.5z"/>
                <rect x="1303.8" y="200.1" className="flame3 st83" width="2.7" height="2.7"/>
                <rect x="1307.4" y="191.1" className="flame1 st83" width="2.7" height="2.7"/>
                <rect x="1320" y="201" className="flame2 st83" width="2.7" height="2.7"/>
                <rect x="1306.5" y="208.2" className="st83" width="3.6" height="3.6"/>
                <rect x="1306.5" y="211.8" className="st83" width="3.6" height="3.6"/>
            </g>
            <g id="main-land-group">
                <g id="portal2">
                    <rect x="788.2" y="173" className="st91" width="4.9" height="4.9"/>
                    <rect x="788.2" y="177.9" className="st90" width="4.9" height="4.9"/>
                    <rect x="793.1" y="182.9" className="st90" width="4.9" height="9.9"/>
                    <rect x="798.1" y="192.7" className="st90" width="4.9" height="9.9"/>
                    <rect x="803" y="207.5" className="st90" width="4.9" height="9.9"/>
                    <rect x="803" y="217.4" className="st92" width="4.9" height="9.9"/>
                    <rect x="812.9" y="227.3" className="st90" width="4.9" height="29.6"/>
                    <rect x="807.9" y="217.4" className="st90" width="4.9" height="4.9"/>
                    <path className="st92" d="M807.9,247h4.9v19.7h-4.9v10H803v9.9h-4.9V298h-4.7v8.3v4.8h-34.7v-12.3h-4.9v-12.3h-4.9v-14.2h4.9l0.1,7.5
                    h7.5v-4.6H757v-9.4h-3.3v-14.7h4.5V247h-4.5v-14.9h10.6v5h4.8v-4.8h9.6v-5h4.4v5l5.1-0.1v4.9v12.5h4.9v6h4.8V247h-4.8v-5.7h4.8
                    v-4.2h-4.8v-4.9h4.8h5v4.9h4.9V247z"/>
                    <rect x="748.7" y="192.7" className="st90" width="4.9" height="4.9"/>
                    <rect x="743.8" y="202.6" className="st90" width="4.9" height="14.8"/>
                    <rect x="748.7" y="227.3" className="st90" width="4.9" height="36.4"/>
                    <rect x="733.9" y="227.3" className="st90" width="4.9" height="14.8"/>
                    <rect x="733.9" y="247" className="st90" width="4.9" height="19.7"/>
                    <rect x="738.9" y="256.9" className="st90" width="4.9" height="19.7"/>
                    <rect x="743.8" y="271.7" className="st90" width="4.9" height="19.7"/>
                    <rect x="748.7" y="286.5" className="st90" width="4.9" height="12.3"/>
                    <rect x="753.7" y="298.8" className="st90" width="4.9" height="12.3"/>
                    <rect x="793.3" y="298" className="st90" width="4.9" height="8.2"/>
                    <rect x="758.6" y="311.1" className="st90" width="29.8" height="4.9"/>
                    <rect x="748.7" y="197.7" className="st105" width="4.9" height="4.9"/>
                    <rect x="738.9" y="217.4" className="st105" width="4.9" height="4.9"/>
                    <rect x="733.9" y="242.1" className="st105" width="4.9" height="4.9"/>
                    <rect x="753.7" y="247" className="st90" width="4.9" height="4.9"/>
                    <rect x="758.6" y="275.2" className="st90" width="4.9" height="4.9"/>
                    <rect x="788.4" y="311.1" className="st105" width="4.9" height="4.9"/>
                    <rect x="739.9" y="222.3" className="st90" width="4.9" height="4.9"/>
                    <rect x="753.7" y="182.9" className="st90" width="4.9" height="9.9"/>
                    <rect x="758.6" y="177.9" className="st90" width="24.7" height="4.9"/>
                    <rect x="783.3" y="177.9" className="st105" width="4.9" height="4.9"/>
                    <rect x="803" y="202.6" className="st105" width="4.9" height="4.9"/>
                    <rect x="793.1" y="212.5" className="st90" width="4.9" height="4.9"/>
                    <rect x="803" y="227.3" className="st90" width="4.9" height="4.9"/>
                    <rect x="803" y="232.2" className="st90" width="4.9" height="4.9"/>
                    <rect x="798.1" y="222.3" className="st90" width="4.9" height="9.9"/>
                    <rect x="788.2" y="232.2" className="st90" width="4.9" height="17.5"/>
                    <rect x="793.1" y="247" className="st90" width="4.9" height="8.6"/>
                    <rect x="753.7" y="263.6" className="st90" width="4.9" height="16.4"/>
                    <rect x="748.7" y="263.6" className="st91" width="4.9" height="8.6"/>
                    <rect x="769.3" y="244.5" className="st90" width="4.9" height="24"/>
                    <rect x="773.4" y="263.6" className="st90" width="4.9" height="9.9"/>
                    <rect x="778.3" y="259.3" className="st90" width="4.9" height="9.2"/>
                    <rect x="769.3" y="283.4" className="st90" width="4.9" height="9.2"/>
                    <rect x="788.2" y="207.5" className="st90" width="4.9" height="4.9"/>
                    <rect x="778.3" y="207.5" className="st90" width="4.9" height="4.9"/>
                    <rect x="768.5" y="207.5" className="st90" width="4.9" height="4.9"/>
                    <rect x="758.6" y="207.5" className="st90" width="4.9" height="4.9"/>
                    <rect x="743.8" y="217.4" className="st90" width="4.9" height="4.9"/>
                    <rect x="753.7" y="217.4" className="st90" width="4.9" height="4.9"/>
                    <rect x="738.9" y="222.3" className="st90" width="4.9" height="4.9"/>
                    <rect x="778.3" y="283.2" className="st90" width="4.9" height="4.9"/>
                    <rect x="778.3" y="292.6" className="st90" width="4.9" height="4.9"/>
                    <rect x="783.3" y="287.7" className="st90" width="4.9" height="4.9"/>
                    <rect x="793.1" y="263.6" className="st90" width="4.9" height="4.9"/>
                    <rect x="802.4" y="248.9" className="st90" width="4.9" height="4.9"/>
                    <rect x="783.3" y="232.2" className="st90" width="4.9" height="4.9"/>
                    <rect x="773.4" y="232.2" className="st90" width="4.9" height="4.9"/>
                    <rect x="768.5" y="232.2" className="st90" width="4.9" height="4.9"/>
                    <rect x="763.5" y="232.2" className="st90" width="4.9" height="4.9"/>
                    <rect x="753.7" y="212.5" className="st90" width="9.9" height="4.9"/>
                    <rect x="753.7" y="207.5" className="st91" width="4.9" height="4.9"/>
                    <rect x="748.7" y="207.5" className="st91" width="4.9" height="4.9"/>
                    <rect x="783.3" y="207.5" className="st92" width="4.9" height="4.9"/>
                    <rect x="773.4" y="207.5" className="st92" width="4.9" height="4.9"/>
                    <rect x="763.5" y="207.5" className="st92" width="4.9" height="4.9"/>
                    <rect x="748.7" y="212.5" className="st92" width="4.9" height="4.9"/>
                    <rect x="743.8" y="222.3" className="st92" width="4.9" height="4.9"/>
                    <rect x="738.9" y="232.2" className="st92" width="4.9" height="24.7"/>
                    <rect x="743.8" y="256.9" className="st92" width="4.9" height="15.4"/>
                    <rect x="738.9" y="227.3" className="st92" width="9.9" height="4.9"/>
                    <rect x="807.9" y="222.3" className="st105" width="4.9" height="4.9"/>
                    <rect x="807.9" y="242.1" className="st105" width="4.9" height="4.9"/>
                    <rect x="793.1" y="237.1" className="st105" width="4.9" height="4.9"/>
                    <rect x="764.4" y="306.2" className="st105" width="4.9" height="4.9"/>
                    <rect x="758.6" y="298.8" className="st105" width="4.9" height="4.9"/>
                    <rect x="798.1" y="281.5" className="st105" width="4.9" height="4.9"/>
                    <rect x="812.9" y="256.9" className="st105" width="4.9" height="4.9"/>
                    <rect x="812.9" y="261.8" className="st90" width="4.9" height="4.9"/>
                    <rect x="807.9" y="266.7" className="st90" width="4.9" height="9.9"/>
                    <rect x="803" y="276.6" className="st90" width="4.9" height="9.9"/>
                    <rect x="753.7" y="177.9" className="st91" width="4.9" height="4.9"/>
                    <rect x="748.7" y="182.9" className="st91" width="4.9" height="4.9"/>
                    <rect x="748.7" y="187.8" className="st91" width="4.9" height="4.9"/>
                    <rect x="743.8" y="192.7" className="st91" width="4.9" height="4.9"/>
                    <rect x="743.8" y="197.7" className="st91" width="4.9" height="4.9"/>
                    <rect x="738.9" y="202.6" className="st91" width="4.9" height="4.9"/>
                    <rect x="738.9" y="207.5" className="st91" width="4.9" height="4.9"/>
                    <rect x="738.9" y="212.5" className="st91" width="4.9" height="4.9"/>
                    <rect x="733.9" y="217.4" className="st91" width="4.9" height="4.9"/>
                    <rect x="733.9" y="222.3" className="st91" width="4.9" height="4.9"/>
                    <rect x="729" y="227.3" className="st91" width="4.9" height="4.9"/>
                    <rect x="733.9" y="266.7" className="st91" width="4.9" height="4.9"/>
                    <rect x="733.9" y="271.7" className="st91" width="4.9" height="4.9"/>
                    <rect x="738.9" y="276.6" className="st91" width="4.9" height="4.9"/>
                    <rect x="738.9" y="281.5" className="st91" width="4.9" height="4.9"/>
                    <rect x="738.9" y="286.5" className="st91" width="4.9" height="4.9"/>
                    <rect x="743.8" y="291.4" className="st91" width="4.9" height="4.9"/>
                    <rect x="743.8" y="296.3" className="st91" width="4.9" height="4.9"/>
                    <rect x="748.7" y="298.8" className="st91" width="4.9" height="7.4"/>
                    <rect x="748.7" y="306.2" className="st91" width="4.9" height="4.9"/>
                    <rect x="753.7" y="311.1" className="st91" width="4.9" height="4.9"/>
                    <rect x="758.6" y="316.1" className="st91" width="34.7" height="4.9"/>
                    <rect x="729" y="232.2" className="st91" width="4.9" height="34.5"/>
                    <rect x="788.2" y="276.6" className="st91" width="4.9" height="16"/>
                    <rect x="793.1" y="268.6" className="st91" width="4.9" height="17.9"/>
                    <rect x="798.1" y="255.6" className="st91" width="4.9" height="12.9"/>
                    <rect x="764.4" y="252.6" className="st91" width="4.9" height="16"/>
                    <rect x="743.8" y="232.2" className="st91" width="4.9" height="24.7"/>
                    <rect x="793.1" y="177.9" className="st91" width="4.9" height="4.9"/>
                    <rect x="783.3" y="197.7" className="st91" width="9.9" height="4.9"/>
                    <rect x="783.3" y="202.6" className="st90" width="9.9" height="4.9"/>
                    <rect x="768.5" y="202.6" className="st92" width="14.8" height="4.9"/>
                    <rect x="758.6" y="202.6" className="st90" width="9.9" height="4.9"/>
                    <rect x="748.7" y="202.6" className="st89" width="9.9" height="4.9"/>
                    <rect x="793.1" y="202.6" className="st91" width="4.9" height="9.9"/>
                    <rect x="763.5" y="197.7" className="st90" width="19.7" height="4.9"/>
                    <rect x="798.1" y="182.9" className="st91" width="4.9" height="4.9"/>
                    <rect x="798.1" y="187.8" className="st91" width="4.9" height="4.9"/>
                    <rect x="803" y="192.7" className="st91" width="4.9" height="4.9"/>
                    <rect x="803" y="197.7" className="st91" width="4.9" height="4.9"/>
                    <rect x="807.9" y="202.6" className="st91" width="4.9" height="4.9"/>
                    <rect x="807.9" y="207.5" className="st91" width="4.9" height="4.9"/>
                    <rect x="807.9" y="212.5" className="st91" width="4.9" height="4.9"/>
                    <rect x="812.9" y="217.4" className="st91" width="4.9" height="4.9"/>
                    <rect x="812.9" y="222.3" className="st91" width="4.9" height="4.9"/>
                    <rect x="748.7" y="217.4" className="st91" width="4.9" height="4.9"/>
                    <rect x="748.7" y="222.3" className="st91" width="4.9" height="4.9"/>
                    <rect x="817.8" y="227.3" className="st91" width="4.9" height="4.9"/>
                    <rect x="817.8" y="232.2" className="st91" width="4.9" height="4.9"/>
                    <rect x="817.8" y="237.1" className="st91" width="4.9" height="4.9"/>
                    <rect x="817.8" y="242.1" className="st91" width="4.9" height="4.9"/>
                    <rect x="817.8" y="247" className="st91" width="4.9" height="4.9"/>
                    <rect x="817.8" y="251.9" className="st91" width="4.9" height="4.9"/>
                    <rect x="817.8" y="256.9" className="st91" width="4.9" height="4.9"/>
                    <rect x="817.8" y="261.8" className="st91" width="4.9" height="4.9"/>
                    <rect x="812.9" y="266.7" className="st91" width="4.9" height="4.9"/>
                    <rect x="812.9" y="271.7" className="st91" width="4.9" height="4.9"/>
                    <rect x="807.9" y="276.6" className="st91" width="4.9" height="4.9"/>
                    <rect x="807.9" y="281.5" className="st91" width="4.9" height="4.9"/>
                    <rect x="803" y="286.5" className="st91" width="4.9" height="4.9"/>
                    <rect x="798.1" y="286.5" className="st90" width="4.9" height="4.9"/>
                    <rect x="798.1" y="291.4" className="st91" width="4.9" height="4.9"/>
                    <rect x="798.1" y="296.3" className="st91" width="4.9" height="4.9"/>
                    <rect x="798.1" y="301.3" className="st91" width="4.9" height="4.9"/>
                    <rect x="793.3" y="306.2" className="st91" width="4.9" height="4.9"/>
                    <rect x="793.3" y="311.1" className="st91" width="4.9" height="4.9"/>
                    <rect x="758.6" y="173" className="st91" width="29.6" height="4.9"/>
                    <rect x="758.6" y="182.9" className="st89" width="34.5" height="4.9"/>
                    <rect x="758.6" y="187.8" className="st89" width="34.5" height="4.9"/>
                    <rect x="783.3" y="192.7" className="st89" width="14.8" height="4.9"/>
                    <rect x="758.6" y="217.4" className="st92" width="39.5" height="4.9"/>
                    <rect x="788.2" y="222.3" className="st92" width="9.9" height="4.9"/>
                    <rect x="753.7" y="222.3" className="st92" width="19.7" height="4.9"/>
                    <rect x="753.7" y="227.3" className="st92" width="14.8" height="4.9"/>
                    <rect x="773.4" y="222.3" className="st91" width="14.8" height="4.9"/>
                    <rect x="773.4" y="227.3" className="st90" width="14.8" height="4.9"/>
                    <rect x="763.5" y="212.5" className="st92" width="29.6" height="4.9"/>
                    <rect x="793.1" y="197.7" className="st89" width="4.9" height="4.9"/>
                    <rect x="798.1" y="202.6" className="st89" width="4.9" height="19.7"/>
                    <rect x="807.9" y="227.3" className="st89" width="4.9" height="14.8"/>
                    <rect x="793.1" y="227.3" className="st89" width="4.9" height="4.9"/>
                    <rect x="788.2" y="227.3" className="st91" width="4.9" height="4.9"/>
                    <rect x="783.3" y="263.6" className="st91" width="4.9" height="4.9"/>
                    <rect x="778.3" y="268.6" className="st91" width="4.9" height="4.9"/>
                    <rect x="768.5" y="268.6" className="st91" width="4.9" height="4.9"/>
                    <rect x="764.4" y="283.2" className="st91" width="4.9" height="4.9"/>
                    <rect x="769.3" y="292.6" className="st91" width="9" height="4.9"/>
                    <rect x="768.5" y="227.3" className="st91" width="4.9" height="4.9"/>
                    <rect x="753.7" y="192.7" className="st89" width="9.9" height="4.9"/>
                    <rect x="763.5" y="187.8" className="st105" width="4.9" height="4.9"/>
                    <rect x="758.6" y="197.7" className="st109" width="4.9" height="4.9"/>
                    <rect x="753.7" y="197.7" className="st92" width="4.9" height="4.9"/>
                    <rect x="763.5" y="192.7" className="st91" width="19.7" height="4.9"/>
                </g>
                <g id="astronaut-purple">
                    <path className="st104" d="M931.4,202h-22.5v5.1h-12.2v5.8h-5.8v29.6h5.8v5.1h40.5h5.8v-5.1h5.8v-29.6h-5.8v-5.8h-11.6V202z"/>
                    <path className="st97" d="M902.5,217.8V212h33.3v5.8h6.2v17.4h-6.2v5.8h-33.3v-5.8H897v-17.4H902.5z"/>
                    <rect x="907" y="217" className="st105" width="5" height="5"/>
                    <rect x="912" y="217" className="st98" width="5" height="5"/>
                    <rect x="907" y="222" className="st105" width="5" height="5"/>
                    <rect x="907" y="227" className="st98" width="5" height="5"/>
                    <rect x="907" y="232" className="st100" width="5" height="5"/>
                    <path className="st106" d="M919,321v-8h18v8H919z"/>
                    <path className="st106" d="M896,321v-8h18v8H896z"/>
                    <path className="st104" d="M943,251l-46,0v6h-7v-6h-6v-12h-12v17h5v5h5v7l20-0.1V313h12v-23h11v23h12l0-45h6v17h12v-28h-12V251z"/>
                    <path className="st106" d="M902,251v-4h17.5H937v4H902z"/>
                    <path className="st106" d="M943,293v-8h12v8H943z"/>
                    <path className="st106" d="M872,239v-8h12v8H872z"/>
                    <rect x="933" y="251" className="st107" width="5" height="5"/>
                    <rect x="933" y="256" className="st107" width="5" height="5"/>
                    <rect x="928" y="256" className="st105" width="5" height="5"/>
                    <rect x="923" y="256" className="st105" width="5" height="5"/>
                    <rect x="923" y="261" className="st107" width="5" height="5"/>
                    <rect x="918" y="261" className="st105" width="5" height="5"/>
                    <rect x="918" y="266" className="st107" width="5" height="5"/>
                    <rect x="950" y="262" className="st107" width="5" height="5"/>
                    <rect x="882" y="263" className="st107" width="5" height="5"/>
                    <rect x="877" y="256" className="st105" width="5" height="12"/>
                    <rect x="950" y="257" className="st105" width="5" height="5"/>
                    <rect x="938" y="251" className="st107" width="5" height="5"/>
                </g>
                <g id="astronaut-blue">
                    <path className="st108" d="M1068.4,202h-22.5v5.1h-12.2v5.8h-5.8v29.6h5.8v5.1h40.5h5.8v-5.1h5.8v-29.6h-5.8v-5.8h-11.6V202z"/>
                    <path className="st97" d="M1039.6,217.8V212h33.3v5.8h6.2v17.4h-6.2v5.8h-33.3v-5.8h-5.6v-17.4H1039.6z"/>
                    <rect x="1044" y="217" className="st105" width="5" height="5"/>
                    <rect x="1049" y="217" className="st98" width="5" height="5"/>
                    <rect x="1044" y="222" className="st105" width="5" height="5"/>
                    <rect x="1044" y="227" className="st98" width="5" height="5"/>
                    <rect x="1044" y="232" className="st100" width="5" height="5"/>
                    <path className="st106" d="M1056,321v-8h18v8H1056z"/>
                    <path className="st106" d="M1033,321v-8h18v8H1033z"/>
                    <path className="st108" d="M1034,251l46,0v6h12v28h-12v-17h-6v45h-12v-23h-11v23h-12v-45.1l-8,0.1v17h-12v-28h12v-6H1034z"/>
                    <path className="st106" d="M1039,251v-4h17.5h17.5v4H1039z"/>
                    <path className="st106" d="M1080,293v-8h12v8H1080z"/>
                    <path className="st106" d="M1019,293v-8h12v8H1019z"/>
                    <rect x="1070" y="251" className="st89" width="5" height="5"/>
                    <rect x="1031" y="251" className="st89" width="5" height="5"/>
                    <rect x="1070" y="256" className="st105" width="5" height="5"/>
                    <rect x="1065" y="256" className="st105" width="5" height="5"/>
                    <rect x="1060" y="256" className="st105" width="5" height="5"/>
                    <rect x="1060" y="261" className="st105" width="5" height="5"/>
                    <rect x="1055" y="261" className="st105" width="5" height="5"/>
                    <rect x="1055" y="266" className="st105" width="5" height="5"/>
                    <rect x="1087" y="262" className="st105" width="5" height="5"/>
                    <rect x="1019" y="262" className="st105" width="5" height="5"/>
                    <rect x="1041" y="251" className="st105" width="5" height="5"/>
                    <rect x="1019" y="267" className="st105" width="5" height="5"/>
                    <rect x="1019" y="272" className="st105" width="5" height="5"/>
                    <rect x="1075" y="251" className="st89" width="5" height="5"/>
                    <rect x="1087" y="257" className="st105" width="5" height="5"/>
                    <rect x="1019" y="257" className="st105" width="5" height="5"/>
                    <rect x="1036" y="251" className="st89" width="5" height="5"/>
                    <rect x="1039" y="268" className="st89" width="5" height="5"/>
                    <rect x="1062" y="298" className="st89" width="5" height="5"/>
                    <rect x="1039" y="273" className="st89" width="5" height="5"/>
                    <rect x="1039" y="278" className="st89" width="5" height="5"/>
                    <rect x="1062" y="303" className="st89" width="5" height="5"/>
                    <rect x="1039" y="283" className="st89" width="5" height="5"/>
                    <rect x="1062" y="308" className="st89" width="5" height="5"/>
                </g>
                <g id="astronaut-orange">
                    <path className="st84" d="M1165.4,202h-22.5v5.1h-12.2v5.8h-5.8v29.6h5.8v5.1h40.5h5.8v-5.1h5.8v-29.6h-5.8v-5.8h-11.6V202z"/>
                    <path className="st97" d="M1136.6,217.8V212h33.3v5.8h6.2v17.4h-6.2v5.8h-33.3v-5.8h-5.6v-17.4H1136.6z"/>
                    <rect x="1141" y="217" className="st105" width="5" height="5"/>
                    <rect x="1146" y="217" className="st98" width="5" height="5"/>
                    <rect x="1141" y="222" className="st105" width="5" height="5"/>
                    <rect x="1141" y="227" className="st98" width="5" height="5"/>
                    <rect x="1141" y="232" className="st100" width="5" height="5"/>
                    <path className="st106" d="M1153,321v-8h18v8H1153z"/>
                    <path className="st106" d="M1130,321v-8h18v8H1130z"/>
                    <path className="st84" d="M1131,251l46,0v6h12v28h-12v-17h-6v45h-12v-23h-11v23h-12v-45.1l-8,0.1v17h-12v-28h12v-6H1131z"/>
                    <path className="st106" d="M1136,251v-4h17.5h17.5v4H1136z"/>
                    <path className="st106" d="M1177,293v-8h12v8H1177z"/>
                    <path className="st106" d="M1116,293v-8h12v8H1116z"/>
                    <rect x="1167" y="251" className="st85" width="5" height="5"/>
                    <rect x="1128" y="251" className="st85" width="5" height="5"/>
                    <rect x="1167" y="256" className="st85" width="5" height="5"/>
                    <rect x="1162" y="256" className="st105" width="5" height="5"/>
                    <rect x="1157" y="256" className="st105" width="5" height="5"/>
                    <rect x="1157" y="261" className="st85" width="5" height="5"/>
                    <rect x="1152" y="261" className="st105" width="5" height="5"/>
                    <rect x="1152" y="266" className="st85" width="5" height="5"/>
                    <rect x="1184" y="262" className="st85" width="5" height="5"/>
                    <rect x="1116" y="262" className="st85" width="5" height="5"/>
                    <rect x="1172" y="251" className="st85" width="5" height="5"/>
                    <rect x="1184" y="257" className="st105" width="5" height="5"/>
                    <rect x="1116" y="257" className="st105" width="5" height="5"/>
                    <rect x="1133" y="251" className="st85" width="5" height="5"/>
                </g>
                <g id="land">
                <path className="st86" d="M1256.6,351v-7.4H801v16.7h11.5v35.3h9.8v27.9h24.6v11.2h8.2v27.9h18v-11.2h-4.9v-22.3h18v11.2h13.1v-11.2
                h18v18.6h26.2v14.9h13.1v-11.2h16.4v11.2h29.5V457h8.2v5.6h39.3v-14.9h13.1v14.9h13.1v13h18v7.4h9.8v-14.9h14.8v-39h27.9
                l1.6,11.2h13.1v27.9h16.4V457h13.1v5.6h8.2v-14.9h-8.2v-13h39.3v-11.2h14.8v-27.9h14.8v-35.3h13.1V351H1256.6z"/>
                <path className="st88" d="M831.6,349.1v-5.5h416.5v12.3h-10.5v26h-9v20.6h-22.5v8.2h-7.5v20.6h-16.5v-8.2h4.5v-16.4h-16.5v8.2h-12
                v-8.2h-16.5v13.7h-24v11h-12v-8.2h-15v8.2h-27v-4.1h-7.5v4.1h-36v-11h-12v11h-12v9.6h-16.5v5.5h-9v-11h-13.5v-28.8h-25.5
                l-1.5,8.2h-12v20.6h-15v-8.2h-12v4.1H884v-11h7.5v-9.6h-36v-8.2H842V382h-13.5v-26h-12v-6.9H831.6z"/>
                <path className="st87" d="M1326.9,346.7v-3.1H726.2v7h15.1v14.8h13v11.7h32.4v4.7h10.8v11.7h23.8v-4.7h-6.5v-9.3h23.8v4.7h17.3v-4.7
                h23.8v7.8h34.6v6.2h17.3v-4.7h21.6v4.7H992v-2.3h10.8v2.3h51.9v-6.2h17.3v6.2h17.3v5.5h23.8v3.1h13v-6.2h19.5v-16.4h36.7l2.2,4.7
                h17.3v11.7h21.6v-4.7h17.3v2.3h10.8v-6.2h-10.8v-5.5h51.9v-4.7h19.5v-11.7h19.4v-14.8h17.3v-3.9H1326.9z"/>
                <path className="st92" d="M939.6,327.7V321h395.3v9.4h10.9v9.4h8.2v44.3h-5.4h-6.8v-10.8h-15v16.1h-8.2v-24.2h-30v8.1h-23.2v-8.1
                h-12.3v18.8h-9.5v-6.7h-10.9v12.1h-9.6v-12.1h-9.5v-12.1h-16.3v8.1h-10.9v-8.1h-21.8v8.1h-10.9v16.1h-9.5v-16.1h-8.2V368h-20.4
                v-9.4h-10.9v6.7h-13.6v18.8h-12.3V368h-21.8v5.4h-16.3v6.7h-10.9v-6.7h-12.3v-8.1h-27.3v-13.4h-20.4v-9.4h6.8v-14.8H939.6z"/>
                <path className="st90" d="M949,326.7V321h381.1v8h10.5v8h7.9v37.8h-5.3h-6.6v-9.2h-14.5v13.7h-7.9v-20.6h-28.9v6.9h-22.3v-6.9h-11.8
                v16h-9.2v-5.7h-10.5v10.3h-9.2v-10.3h-9.2v-10.3h-15.8v6.9h-10.5v-6.9h-21v6.9h-10.5v13.7h-9.2v-13.7h-7.9v-4.6h-19.7v-8H1108
                v5.7h-13.1v16H1083v-13.7h-21v4.6h-15.8v5.7h-10.5v-5.7h-11.8v-6.9h-26.3v-11.5h-19.7v-8h6.6v-12.6H949z"/>
                <path className="st91" d="M934.2,324.5V321h395.3v4.9h10.9v4.9h8.2V354h-5.5h-6.8v-5.6h-15v8.4h-8.2v-12.6h-30v4.2h-23.2v-4.2h-12.3
                v9.8h-9.5v-3.5h-10.9v6.3h-9.5v-6.3h-9.5v-6.3h-16.3v4.2h-10.9v-4.2h-21.8v4.2h-10.9v8.4h-9.5v-8.4h-8.2v-2.8H1110v-4.9h-10.9
                v3.5h-13.6v9.8h-12.3v-8.4h-21.8v2.8H1035v3.5h-10.9v-3.5h-12.3v-4.2h-27.3v-7h-20.4v-4.9h6.8v-7.7H934.2z"/>
                <path className="st92" d="M1048.7,327.7V321H735.1v9.4h-8.6v9.4H720v44.3h4.3h5.4v-10.8h11.9v16.1h6.5v-24.2h23.8v8.1h18.4v-8.1h9.7
                v18.8h7.6v-6.7h8.6v12.1h7.6v-12.1h7.6v-12.1h13v8.1h8.6v-8.1h17.3v8.1h8.7v16.1h7.6v-16.1h6.5V368h16.2v-9.4h8.7v6.7h10.8v18.8
                h9.7V368h17.3v5.4h13v6.7h8.6v-6.7h9.7v-8.1h21.6v-13.4h16.2v-9.4h-5.4v-14.8H1048.7z"/>
                <path className="st90" d="M1058.8,326.7V321h-318v8H732v8h-6.6v37.8h4.4h5.5v-9.2h12.1v13.7h6.6v-20.6h24.1v6.9h18.6v-6.9h9.9v16h7.7
                v-5.7h8.8v10.3h7.7v-10.3h7.7v-10.3h13.2v6.9h8.8v-6.9h17.5v6.9h8.8v13.7h7.7v-13.7h6.6v-4.6h16.4v-8h8.8v5.7h11v16h9.9v-13.7
                h17.5v4.6h13.2v5.7h8.8v-5.7h9.9v-6.9h21.9v-11.5h16.4v-8h-5.5v-12.6H1058.8z"/>
                <path className="st91" d="M1111.8,324.5V321H743.2v4.9h-10.2v4.9h-7.6V354h5.1h6.4v-5.6h14v8.4h7.6v-12.6h28v4.2h21.6v-4.2h11.4v9.8
                h8.9v-3.5h10.2v6.3h8.9v-6.3h8.9v-6.3h15.2v4.2h10.2v-4.2h20.3v4.2h10.2v8.4h8.9v-8.4h7.6v-2.8h19.1v-4.9H958v3.5h12.7v9.8h11.4
                v-8.4h20.3v2.8h15.2v3.5h10.2v-3.5h11.4v-4.2h25.4v-7h19.1v-4.9h-6.4v-7.7H1111.8z"/>
                </g>
            </g>          
            <g id="secondary-land-group">
                <g id="astronaut-green">
                    <path className="st90" d="M207.6,202h22.5v5.1h12.2v5.8h5.8v29.6h-5.8v5.1h-40.5h-5.8v-5.1h-5.8v-29.6h5.8v-5.8h11.6V202z"/>
                    <path className="st97" d="M236.5,217.8V212h-33.3v5.8H197v17.4h6.2v5.8h33.3v-5.8h5.5v-17.4H236.5z"/>
                    <rect x="227" y="217" className="st105" width="5" height="5"/>
                    <rect x="222" y="217" className="st98" width="5" height="5"/>
                    <rect x="227" y="222" className="st105" width="5" height="5"/>
                    <rect x="227" y="227" className="st98" width="5" height="5"/>
                    <rect x="227" y="232" className="st100" width="5" height="5"/>
                    <path className="st106" d="M220,321v-8h-18v8H220z"/>
                    <path className="st106" d="M243,321v-8h-18v8H243z"/>
                    <path className="st90" d="M196,251l46,0v6h7v-6h6v-12h12v17h-5v5h-5v7l-20-0.1V313h-12v-23h-11v23h-12l0-45h-6v17h-12v-28h12V251z"/>
                    <path className="st106" d="M237,251v-4h-17.5H202v4H237z"/>
                    <path className="st106" d="M196,293v-8h-12v8H196z"/>
                    <path className="st106" d="M267,239v-8h-12v8H267z"/>
                    <rect x="201" y="251" className="st92" width="5" height="5"/>
                    <rect x="201" y="256" className="st108" width="5" height="5"/>
                    <rect x="206" y="256" className="st105" width="5" height="5"/>
                    <rect x="211" y="256" className="st105" width="5" height="5"/>
                    <rect x="211" y="261" className="st108" width="5" height="5"/>
                    <rect x="216" y="261" className="st105" width="5" height="5"/>
                    <rect x="216" y="266" className="st108" width="5" height="5"/>
                    <rect x="184" y="262" className="st108" width="5" height="5"/>
                    <rect x="252" y="263" className="st92" width="5" height="5"/>
                    <rect x="257" y="256" className="st105" width="5" height="12"/>
                    <rect x="184" y="257" className="st105" width="5" height="5"/>
                    <rect x="196" y="251" className="st92" width="5" height="5"/>
                </g>
                <g id="portal">
                    <rect x="420.2" y="173" className="st91" width="4.9" height="4.9"/>
                    <rect x="420.2" y="177.9" className="st90" width="4.9" height="4.9"/>
                    <rect x="425.1" y="182.9" className="st90" width="4.9" height="9.9"/>
                    <rect x="430.1" y="192.7" className="st90" width="4.9" height="9.9"/>
                    <rect x="435" y="207.5" className="st90" width="4.9" height="9.9"/>
                    <rect x="435" y="217.4" className="st92" width="4.9" height="9.9"/>
                    <rect x="444.9" y="227.3" className="st90" width="4.9" height="29.6"/>
                    <rect x="439.9" y="217.4" className="st90" width="4.9" height="4.9"/>
                    <path className="st92" d="M439.9,247h4.9v19.7h-4.9v10H435v9.9h-4.9V298h-4.7v8.3v4.8h-34.7v-12.3h-4.9v-12.3h-4.9v-14.2h4.9l0.1,7.5
                    h7.5v-4.6H389v-9.4h-3.3v-14.7h4.5V247h-4.5v-14.9h10.6v5h4.8v-4.8h9.6v-5h4.4v5l5.1-0.1v4.9v12.5h4.9v6h4.8V247h-4.8v-5.7h4.8
                    v-4.2h-4.8v-4.9h4.8h5v4.9h4.9V247z"/>
                    <rect x="380.7" y="192.7" className="st90" width="4.9" height="4.9"/>
                    <rect x="375.8" y="202.6" className="st90" width="4.9" height="14.8"/>
                    <rect x="380.7" y="227.3" className="st90" width="4.9" height="36.4"/>
                    <rect x="365.9" y="227.3" className="st90" width="4.9" height="14.8"/>
                    <rect x="365.9" y="247" className="st90" width="4.9" height="19.7"/>
                    <rect x="370.9" y="256.9" className="st90" width="4.9" height="19.7"/>
                    <rect x="375.8" y="271.7" className="st90" width="4.9" height="19.7"/>
                    <rect x="380.7" y="286.5" className="st90" width="4.9" height="12.3"/>
                    <rect x="385.7" y="298.8" className="st90" width="4.9" height="12.3"/>
                    <rect x="425.3" y="298" className="st90" width="4.9" height="8.2"/>
                    <rect x="390.6" y="311.1" className="st90" width="29.8" height="4.9"/>
                    <rect x="380.7" y="197.7" className="st105" width="4.9" height="4.9"/>
                    <rect x="370.9" y="217.4" className="st105" width="4.9" height="4.9"/>
                    <rect x="365.9" y="242.1" className="st105" width="4.9" height="4.9"/>
                    <rect x="385.7" y="247" className="st90" width="4.9" height="4.9"/>
                    <rect x="390.6" y="275.2" className="st90" width="4.9" height="4.9"/>
                    <rect x="420.4" y="311.1" className="st105" width="4.9" height="4.9"/>
                    <rect x="371.9" y="222.3" className="st90" width="4.9" height="4.9"/>
                    <rect x="385.7" y="182.9" className="st90" width="4.9" height="9.9"/>
                    <rect x="390.6" y="177.9" className="st90" width="24.7" height="4.9"/>
                    <rect x="415.3" y="177.9" className="st105" width="4.9" height="4.9"/>
                    <rect x="435" y="202.6" className="st105" width="4.9" height="4.9"/>
                    <rect x="425.1" y="212.5" className="st90" width="4.9" height="4.9"/>
                    <rect x="435" y="227.3" className="st90" width="4.9" height="4.9"/>
                    <rect x="435" y="232.2" className="st90" width="4.9" height="4.9"/>
                    <rect x="430.1" y="222.3" className="st90" width="4.9" height="9.9"/>
                    <rect x="420.2" y="232.2" className="st90" width="4.9" height="17.5"/>
                    <rect x="425.1" y="247" className="st90" width="4.9" height="8.6"/>
                    <rect x="385.7" y="263.6" className="st90" width="4.9" height="16.4"/>
                    <rect x="380.7" y="263.6" className="st91" width="4.9" height="8.6"/>
                    <rect x="401.3" y="244.5" className="st90" width="4.9" height="24"/>
                    <rect x="405.4" y="263.6" className="st90" width="4.9" height="9.9"/>
                    <rect x="410.3" y="259.3" className="st90" width="4.9" height="9.2"/>
                    <rect x="401.3" y="283.4" className="st90" width="4.9" height="9.2"/>
                    <rect x="420.2" y="207.5" className="st90" width="4.9" height="4.9"/>
                    <rect x="410.3" y="207.5" className="st90" width="4.9" height="4.9"/>
                    <rect x="400.5" y="207.5" className="st90" width="4.9" height="4.9"/>
                    <rect x="390.6" y="207.5" className="st90" width="4.9" height="4.9"/>
                    <rect x="375.8" y="217.4" className="st90" width="4.9" height="4.9"/>
                    <rect x="385.7" y="217.4" className="st90" width="4.9" height="4.9"/>
                    <rect x="370.9" y="222.3" className="st90" width="4.9" height="4.9"/>
                    <rect x="410.3" y="283.2" className="st90" width="4.9" height="4.9"/>
                    <rect x="410.3" y="292.6" className="st90" width="4.9" height="4.9"/>
                    <rect x="415.3" y="287.7" className="st90" width="4.9" height="4.9"/>
                    <rect x="425.1" y="263.6" className="st90" width="4.9" height="4.9"/>
                    <rect x="434.4" y="248.9" className="st90" width="4.9" height="4.9"/>
                    <rect x="415.3" y="232.2" className="st90" width="4.9" height="4.9"/>
                    <rect x="405.4" y="232.2" className="st90" width="4.9" height="4.9"/>
                    <rect x="400.5" y="232.2" className="st90" width="4.9" height="4.9"/>
                    <rect x="395.5" y="232.2" className="st90" width="4.9" height="4.9"/>
                    <rect x="385.7" y="212.5" className="st90" width="9.9" height="4.9"/>
                    <rect x="385.7" y="207.5" className="st91" width="4.9" height="4.9"/>
                    <rect x="380.7" y="207.5" className="st91" width="4.9" height="4.9"/>
                    <rect x="415.3" y="207.5" className="st92" width="4.9" height="4.9"/>
                    <rect x="405.4" y="207.5" className="st92" width="4.9" height="4.9"/>
                    <rect x="395.5" y="207.5" className="st92" width="4.9" height="4.9"/>
                    <rect x="380.7" y="212.5" className="st92" width="4.9" height="4.9"/>
                    <rect x="375.8" y="222.3" className="st92" width="4.9" height="4.9"/>
                    <rect x="370.9" y="232.2" className="st92" width="4.9" height="24.7"/>
                    <rect x="375.8" y="256.9" className="st92" width="4.9" height="15.4"/>
                    <rect x="370.9" y="227.3" className="st92" width="9.9" height="4.9"/>
                    <rect x="439.9" y="222.3" className="st105" width="4.9" height="4.9"/>
                    <rect x="439.9" y="242.1" className="st105" width="4.9" height="4.9"/>
                    <rect x="425.1" y="237.1" className="st105" width="4.9" height="4.9"/>
                    <rect x="396.4" y="306.2" className="st105" width="4.9" height="4.9"/>
                    <rect x="390.6" y="298.8" className="st105" width="4.9" height="4.9"/>
                    <rect x="430.1" y="281.5" className="st105" width="4.9" height="4.9"/>
                    <rect x="444.9" y="256.9" className="st105" width="4.9" height="4.9"/>
                    <rect x="444.9" y="261.8" className="st90" width="4.9" height="4.9"/>
                    <rect x="439.9" y="266.7" className="st90" width="4.9" height="9.9"/>
                    <rect x="435" y="276.6" className="st90" width="4.9" height="9.9"/>
                    <rect x="385.7" y="177.9" className="st91" width="4.9" height="4.9"/>
                    <rect x="380.7" y="182.9" className="st91" width="4.9" height="4.9"/>
                    <rect x="380.7" y="187.8" className="st91" width="4.9" height="4.9"/>
                    <rect x="375.8" y="192.7" className="st91" width="4.9" height="4.9"/>
                    <rect x="375.8" y="197.7" className="st91" width="4.9" height="4.9"/>
                    <rect x="370.9" y="202.6" className="st91" width="4.9" height="4.9"/>
                    <rect x="370.9" y="207.5" className="st91" width="4.9" height="4.9"/>
                    <rect x="370.9" y="212.5" className="st91" width="4.9" height="4.9"/>
                    <rect x="365.9" y="217.4" className="st91" width="4.9" height="4.9"/>
                    <rect x="365.9" y="222.3" className="st91" width="4.9" height="4.9"/>
                    <rect x="361" y="227.3" className="st91" width="4.9" height="4.9"/>
                    <rect x="365.9" y="266.7" className="st91" width="4.9" height="4.9"/>
                    <rect x="365.9" y="271.7" className="st91" width="4.9" height="4.9"/>
                    <rect x="370.9" y="276.6" className="st91" width="4.9" height="4.9"/>
                    <rect x="370.9" y="281.5" className="st91" width="4.9" height="4.9"/>
                    <rect x="370.9" y="286.5" className="st91" width="4.9" height="4.9"/>
                    <rect x="375.8" y="291.4" className="st91" width="4.9" height="4.9"/>
                    <rect x="375.8" y="296.3" className="st91" width="4.9" height="4.9"/>
                    <rect x="380.7" y="298.8" className="st91" width="4.9" height="7.4"/>
                    <rect x="380.7" y="306.2" className="st91" width="4.9" height="4.9"/>
                    <rect x="385.7" y="311.1" className="st91" width="4.9" height="4.9"/>
                    <rect x="390.6" y="316.1" className="st91" width="34.7" height="4.9"/>
                    <rect x="361" y="232.2" className="st91" width="4.9" height="34.5"/>
                    <rect x="420.2" y="276.6" className="st91" width="4.9" height="16"/>
                    <rect x="425.1" y="268.6" className="st91" width="4.9" height="17.9"/>
                    <rect x="430.1" y="255.6" className="st91" width="4.9" height="12.9"/>
                    <rect x="396.4" y="252.6" className="st91" width="4.9" height="16"/>
                    <rect x="375.8" y="232.2" className="st91" width="4.9" height="24.7"/>
                    <rect x="425.1" y="177.9" className="st91" width="4.9" height="4.9"/>
                    <rect x="415.3" y="197.7" className="st91" width="9.9" height="4.9"/>
                    <rect x="415.3" y="202.6" className="st90" width="9.9" height="4.9"/>
                    <rect x="400.5" y="202.6" className="st92" width="14.8" height="4.9"/>
                    <rect x="390.6" y="202.6" className="st90" width="9.9" height="4.9"/>
                    <rect x="380.7" y="202.6" className="st89" width="9.9" height="4.9"/>
                    <rect x="425.1" y="202.6" className="st91" width="4.9" height="9.9"/>
                    <rect x="395.5" y="197.7" className="st90" width="19.7" height="4.9"/>
                    <rect x="430.1" y="182.9" className="st91" width="4.9" height="4.9"/>
                    <rect x="430.1" y="187.8" className="st91" width="4.9" height="4.9"/>
                    <rect x="435" y="192.7" className="st91" width="4.9" height="4.9"/>
                    <rect x="435" y="197.7" className="st91" width="4.9" height="4.9"/>
                    <rect x="439.9" y="202.6" className="st91" width="4.9" height="4.9"/>
                    <rect x="439.9" y="207.5" className="st91" width="4.9" height="4.9"/>
                    <rect x="439.9" y="212.5" className="st91" width="4.9" height="4.9"/>
                    <rect x="444.9" y="217.4" className="st91" width="4.9" height="4.9"/>
                    <rect x="444.9" y="222.3" className="st91" width="4.9" height="4.9"/>
                    <rect x="380.7" y="217.4" className="st91" width="4.9" height="4.9"/>
                    <rect x="380.7" y="222.3" className="st91" width="4.9" height="4.9"/>
                    <rect x="449.8" y="227.3" className="st91" width="4.9" height="4.9"/>
                    <rect x="449.8" y="232.2" className="st91" width="4.9" height="4.9"/>
                    <rect x="449.8" y="237.1" className="st91" width="4.9" height="4.9"/>
                    <rect x="449.8" y="242.1" className="st91" width="4.9" height="4.9"/>
                    <rect x="449.8" y="247" className="st91" width="4.9" height="4.9"/>
                    <rect x="449.8" y="251.9" className="st91" width="4.9" height="4.9"/>
                    <rect x="449.8" y="256.9" className="st91" width="4.9" height="4.9"/>
                    <rect x="449.8" y="261.8" className="st91" width="4.9" height="4.9"/>
                    <rect x="444.9" y="266.7" className="st91" width="4.9" height="4.9"/>
                    <rect x="444.9" y="271.7" className="st91" width="4.9" height="4.9"/>
                    <rect x="439.9" y="276.6" className="st91" width="4.9" height="4.9"/>
                    <rect x="439.9" y="281.5" className="st91" width="4.9" height="4.9"/>
                    <rect x="435" y="286.5" className="st91" width="4.9" height="4.9"/>
                    <rect x="430.1" y="286.5" className="st90" width="4.9" height="4.9"/>
                    <rect x="430.1" y="291.4" className="st91" width="4.9" height="4.9"/>
                    <rect x="430.1" y="296.3" className="st91" width="4.9" height="4.9"/>
                    <rect x="430.1" y="301.3" className="st91" width="4.9" height="4.9"/>
                    <rect x="425.3" y="306.2" className="st91" width="4.9" height="4.9"/>
                    <rect x="425.3" y="311.1" className="st91" width="4.9" height="4.9"/>
                    <rect x="390.6" y="173" className="st91" width="29.6" height="4.9"/>
                    <rect x="390.6" y="182.9" className="st89" width="34.5" height="4.9"/>
                    <rect x="390.6" y="187.8" className="st89" width="34.5" height="4.9"/>
                    <rect x="415.3" y="192.7" className="st89" width="14.8" height="4.9"/>
                    <rect x="390.6" y="217.4" className="st92" width="39.5" height="4.9"/>
                    <rect x="420.2" y="222.3" className="st92" width="9.9" height="4.9"/>
                    <rect x="385.7" y="222.3" className="st92" width="19.7" height="4.9"/>
                    <rect x="385.7" y="227.3" className="st92" width="14.8" height="4.9"/>
                    <rect x="405.4" y="222.3" className="st91" width="14.8" height="4.9"/>
                    <rect x="405.4" y="227.3" className="st90" width="14.8" height="4.9"/>
                    <rect x="395.5" y="212.5" className="st92" width="29.6" height="4.9"/>
                    <rect x="425.1" y="197.7" className="st89" width="4.9" height="4.9"/>
                    <rect x="430.1" y="202.6" className="st89" width="4.9" height="19.7"/>
                    <rect x="439.9" y="227.3" className="st89" width="4.9" height="14.8"/>
                    <rect x="425.1" y="227.3" className="st89" width="4.9" height="4.9"/>
                    <rect x="420.2" y="227.3" className="st91" width="4.9" height="4.9"/>
                    <rect x="415.3" y="263.6" className="st91" width="4.9" height="4.9"/>
                    <rect x="410.3" y="268.6" className="st91" width="4.9" height="4.9"/>
                    <rect x="400.5" y="268.6" className="st91" width="4.9" height="4.9"/>
                    <rect x="396.4" y="283.2" className="st91" width="4.9" height="4.9"/>
                    <rect x="401.3" y="292.6" className="st91" width="9" height="4.9"/>
                    <rect x="400.5" y="227.3" className="st91" width="4.9" height="4.9"/>
                    <rect x="385.7" y="192.7" className="st89" width="9.9" height="4.9"/>
                    <rect x="395.5" y="187.8" className="st105" width="4.9" height="4.9"/>
                    <rect x="390.6" y="197.7" className="st109" width="4.9" height="4.9"/>
                    <rect x="385.7" y="197.7" className="st92" width="4.9" height="4.9"/>
                    <rect x="395.5" y="192.7" className="st91" width="19.7" height="4.9"/>
                </g>
                <g id="astronaut-purple-2">
                    <path className="st86" d="M421.4,202h-22.5v5.1h-12.2v5.8h-5.8v29.6h5.8v5.1h40.5h5.8v-5.1h5.8v-29.6H433v-5.8h-11.6V202z"/>
                    <path className="st87" d="M392.5,217.8V212h33.3v5.8h6.2v17.4h-6.2v5.8h-33.3v-5.8H387v-17.4H392.5z"/>
                    <rect x="397" y="217" className="st88" width="5" height="5"/>
                    <rect x="402" y="217" className="st89" width="5" height="5"/>
                    <rect x="397" y="222" className="st88" width="5" height="5"/>
                    <rect x="397" y="227" className="st89" width="5" height="5"/>
                    <rect x="397" y="232" className="st90" width="5" height="5"/>
                    <path className="st91" d="M409,321v-8h18v8H409z"/>
                    <path className="st91" d="M386,321v-8h18v8H386z"/>
                    <path className="st86" d="M433,251h-46v6h-7v-6h-6v-12h-12v17h5v5h5v7l20-0.1V313h12v-23h11v23h12v-45h6v17h12v-28h-12V251z"
                        />
                    <path className="st91" d="M392,251v-4h17.5H427v4H392z"/>
                    <path className="st91" d="M433,293v-8h12v8H433z"/>
                    <path className="st91" d="M362,239v-8h12v8H362z"/>
                    <rect x="423" y="251" className="st92" width="5" height="5"/>
                    <rect x="423" y="256" className="st92" width="5" height="5"/>
                    <rect x="418" y="256" className="st88" width="5" height="5"/>
                    <rect x="413" y="256" className="st88" width="5" height="5"/>
                    <rect x="413" y="261" className="st92" width="5" height="5"/>
                    <rect x="408" y="261" className="st88" width="5" height="5"/>
                    <rect x="408" y="266" className="st92" width="5" height="5"/>
                    <rect x="440" y="262" className="st92" width="5" height="5"/>
                    <rect x="372" y="263" className="st92" width="5" height="5"/>
                    <rect x="367" y="256" className="st88" width="5" height="12"/>
                    <rect x="440" y="257" className="st88" width="5" height="5"/>
                    <rect x="428" y="251" className="st92" width="5" height="5"/>
                </g>
                <g id="land_1">
                    <path className="st86" d="M112.3,344v-6h284.1v13.6h-7.2v28.6h-6.1v22.6h-15.3v9h-5.1v22.6h-11.2v-9h3.1v-18.1h-11.2v9h-8.2v-9h-11.2
                    v15.1h-16.3v12.1h-8.2v-9h-10.2v9h-18.4v-4.5h-5.1v4.5h-24.5v-12.1h-8.2v12.1h-8.2V445h-11.2v6h-6.1v-12.1h-9.2v-31.7h-17.4l-1,9
                    h-8.2v22.6h-10.2v-9h-8.2v4.5h-5.1v-12.1h5.1v-10.6h-24.5v-9h-9.2v-22.6h-9.2v-28.6h-8.2V344H112.3z"/>
                    <path className="st87" d="M377.3,342.4v-4.4H117.6v10h6.5v21.1h5.6v16.7h14v6.7h4.7v16.7h10.3v-6.7h-2.8v-13.3h10.3v6.7h7.5v-6.7H184
                    v11.1h14.9v8.9h7.5v-6.7h9.3v6.7h16.8v-3.3h4.7v3.3h22.4v-8.9h7.5v8.9h7.5v7.8h10.3v4.4h5.6v-8.9h8.4v-23.3h15.9l0.9,6.7h7.5
                    v16.7h9.3v-6.7h7.5v3.3h4.7v-8.9H340v-7.8h22.4v-6.7h8.4v-16.7h8.4v-21.1h7.5v-5.6H377.3z"/>
                    <path className="st88" d="M68.5,340.5v-2.5H443v5.7h-9.4v12h-8.1v9.5h-20.2v3.8h-6.7v9.5h-14.8v-3.8h4V367h-14.8v3.8h-10.8V367h-14.8
                    v6.3h-21.6v5.1H315v-3.8h-13.5v3.8h-24.2v-1.9h-6.7v1.9h-32.3v-5.1h-10.8v5.1h-10.8v4.4h-14.8v2.5h-8.1v-5.1h-12.1V367h-22.9
                    l-1.3,3.8h-10.8v9.5h-13.5v-3.8h-10.8v1.9h-6.7v-5.1h6.7v-4.4H90v-3.8H77.9v-9.5H65.8v-12H55v-3.2H68.5z"/>
                    <path className="st89" d="M383.1,329.6V321h-313v12h-8.6v12H55v56.7h4.3h5.4V388h11.9v20.6h6.5v-30.9h23.7V388h18.4v-10.3h9.7v24h7.6
                    v-8.6h8.6v15.5h7.6v-15.5h7.6v-15.5h13V388h8.6v-10.3H205V388h8.6v20.6h7.6V388h6.5v-6.9h16.2v-12h8.6v8.6h10.8v24h9.7v-20.6
                    h17.3v6.9h13v8.6h8.6V388h9.7v-10.3h21.6v-17.2h16.2v-12H354v-18.9H383.1z"/>
                    <path className="st90" d="M392.6,328.3V321H75.1v10.2h-8.8v10.2h-6.6v48.3h4.4h5.5v-11.7h12v17.6h6.6v-26.4h24.1v8.8H131v-8.8h9.9
                    v20.5h7.7v-7.3h8.8v13.2h7.7v-13.2h7.7v-13.2h13.1v8.8h8.8v-8.8H212v8.8h8.8v17.6h7.7v-17.6h6.6v-5.9h16.4V362h8.8v7.3h10.9v20.5
                    h9.9v-17.6h17.5v5.9h13.1v7.3h8.8v-7.3h9.9v-8.8h21.9v-14.6h16.4v-10.2H363v-16.1H392.6z"/>
                    <path className="st91" d="M443,321v16.9h-27.7v3.7h-18.9v9h-25.2v5.4h-11.3v4.5h-10.1V356h-15.1v-3.6h-20.2v10.8h-11.3v-12.6h-12.6
                    v-4.5h-10.1v6.3h-18.9v3.6h-7.6v10.8h-8.8V356H235v-5.4h-20.2v5.4h-10.1v-5.4h-15.1v8.1h-8.8v8.1H172v-8.1h-10.1v4.5h-8.8v-12.6
                    h-11.3v5.4h-21.4v-5.4H92.6v16.2H85V356H71.1v7.2h-6.3h-5v-31.8h7.6v-4.2h10.1V321H443z"/>
                </g>
            </g>
            <g id="torch_1">
                <g className="st93">
                <path className="st94" d="M703.7,182.8h-12.2v4.1h-4.7v3.4h-3.7v5.1h-4.1v12.5h4.1v4.1h3.7v4.1h4.7v4.4h12.2v-4.4h4.7v-4.1h4.4v-4.1
                    h4.1v-12.5h-4.1v-5.1h-4.4v-3.4h-4.7V182.8z"/>
                <path className="st95" d="M704.1,187.5h-12.2v4.1h-4.4v4.1h-4.4v12.5h4.4v4h4.4v4.4h12.2v-4.4h4v-4h4.4v-12.5h-4.4v-4.1h-4V187.5z"
                    />
                <path className="st96" d="M691.2,192.2h11.8v3.7h4.7v12.2h-4.7v3.4h-11.8v-3.4h-3.4V196h3.4V192.2z"/>
                </g>
                <rect x="687.2" y="213.4" className="st97" width="21.6" height="2.7"/>
                <rect x="691.7" y="216.1" className="st97" width="2.7" height="2.7"/>
                <rect x="694.4" y="216.1" className="st98" width="2.7" height="2.7"/>
                <rect x="697.1" y="216.1" className="st99" width="2.7" height="2.7"/>
                <rect x="699.8" y="216.1" className="st86" width="2.7" height="2.7"/>
                <rect x="694.4" y="218.8" className="st100" width="2.7" height="2.7"/>
                <rect x="699.8" y="218.8" className="st100" width="2.7" height="2.7"/>
                <rect x="697.1" y="218.8" className="st101" width="2.7" height="2.7"/>
                <rect x="691.7" y="218.8" className="st97" width="2.7" height="2.7"/>
                <rect x="702.5" y="216.1" className="st97" width="2.7" height="2.7"/>
                <rect x="694.4" y="221.5" className="st97" width="2.7" height="2.7"/>
                <rect x="699.8" y="221.5" className="st97" width="2.7" height="2.7"/>
                <rect x="694.4" y="224.2" className="st97" width="2.7" height="2.7"/>
                <rect x="694.4" y="226.9" className="st97" width="2.7" height="2.7"/>
                <rect x="702.5" y="218.8" className="st97" width="2.7" height="2.7"/>
                <rect x="699.8" y="224.2" className="st97" width="2.7" height="2.7"/>
                <rect x="699.8" y="226.9" className="st97" width="2.7" height="2.7"/>
                <rect x="697.1" y="229.6" className="st97" width="2.7" height="2.7"/>
                <rect x="697.1" y="226.9" className="st87" width="2.7" height="2.7"/>
                <rect x="697.1" y="224.2" className="st102" width="2.7" height="2.7"/>
                <rect x="697.1" y="221.5" className="st102" width="2.7" height="2.7"/>
                <path className="st84" d="M687.2,210.2v3.1h21.6v-3.1h-3.1v-5.9H703v-6.3h-5.9v3.1H694v2.7h-3.2v6.3H687.2z"/>
                <path className="st103" d="M702.5,204.4v9h-5.4v-11.7h2.7v2.7H702.5z"/>
                <rect x="690.8" y="198.1" className="flame3 st83" width="2.7" height="2.7"/>
                <rect x="694.4" y="189.1" className="flame1 st83" width="2.7" height="2.7"/>
                <rect x="707" y="199" className="flame2 st83" width="2.7" height="2.7"/>
                <rect x="693.5" y="206.2" className="st83" width="3.6" height="3.6"/>
                <rect x="693.5" y="209.8" className="st83" width="3.6" height="3.6"/>
            </g>
            <g id="lava">
                <g id="stones">
                <rect x="252" y="522" className="st1" width="6" height="6"/>
                <rect x="252" y="528" className="st2" width="6" height="6"/>
                <rect x="270" y="510" className="st3" width="6" height="6"/>
                <rect x="282" y="510" className="st2" width="6" height="6"/>
                <rect x="282" y="504" className="st4" width="6" height="6"/>
                <rect x="294" y="510" className="st5" width="6" height="6"/>
                <rect x="294" y="504" className="st6" width="6" height="6"/>
                <rect x="306" y="510" className="st7" width="6" height="6"/>
                <rect x="306" y="504" className="st8" width="6" height="6"/>
                <rect x="318" y="510" className="st9" width="6" height="6"/>
                <rect x="258" y="516" className="st2" width="6" height="6"/>
                <rect x="270" y="516" className="st1" width="6" height="6"/>
                <rect x="282" y="516" className="st10" width="6" height="6"/>
                <rect x="294" y="516" className="st11" width="6" height="6"/>
                <rect x="306" y="516" className="st11" width="6" height="6"/>
                <rect x="318" y="516" className="st12" width="6" height="6"/>
                <rect x="258" y="522" className="st2" width="6" height="6"/>
                <rect x="270" y="522" className="st13" width="6" height="6"/>
                <rect x="282" y="522" className="st14" width="6" height="6"/>
                <rect x="294" y="522" className="st11" width="6" height="6"/>
                <rect x="306" y="522" className="st12" width="6" height="6"/>
                <rect x="318" y="522" className="st12" width="6" height="6"/>
                <rect x="258" y="528" className="st2" width="6" height="6"/>
                <rect x="270" y="528" className="st15" width="6" height="6"/>
                <rect x="282" y="528" className="st16" width="6" height="6"/>
                <rect x="294" y="528" className="st11" width="6" height="6"/>
                <rect x="306" y="528" className="st12" width="6" height="6"/>
                <rect x="318" y="528" className="st17" width="6" height="6"/>
                <rect x="258" y="534" className="st13" width="6" height="6"/>
                <rect x="252" y="534" className="st18" width="6" height="6"/>
                <rect x="270" y="534" className="st13" width="6" height="6"/>
                <rect x="282" y="534" className="st19" width="6" height="6"/>
                <rect x="294" y="534" className="st11" width="6" height="6"/>
                <rect x="306" y="534" className="st20" width="6" height="6"/>
                <rect x="318" y="534" className="st17" width="6" height="6"/>
                <rect x="258" y="540" className="st21" width="6" height="6"/>
                <rect x="252" y="540" className="st22" width="6" height="6"/>
                <rect x="270" y="540" className="st13" width="6" height="6"/>
                <rect x="282" y="540" className="st12" width="6" height="6"/>
                <rect x="294" y="540" className="st23" width="6" height="6"/>
                <rect x="306" y="540" className="st20" width="6" height="6"/>
                <rect x="318" y="540" className="st24" width="6" height="6"/>
                <rect x="258" y="546" className="st22" width="6" height="6"/>
                <rect x="258" y="558" className="st25" width="6" height="6"/>
                <rect x="258" y="570" className="st26" width="6" height="6"/>
                <rect x="252" y="558" className="st27" width="6" height="6"/>
                <rect x="252" y="546" className="st28" width="6" height="6"/>
                <rect x="270" y="558" className="st29" width="6" height="6"/>
                <rect x="282" y="558" className="st30" width="6" height="6"/>
                <rect x="270" y="546" className="st31" width="6" height="6"/>
                <rect x="294" y="558" className="st32" width="6" height="6"/>
                <rect x="270" y="570" className="st26" width="6" height="6"/>
                <rect x="306" y="558" className="st32" width="6" height="6"/>
                <rect x="282" y="546" className="st31" width="6" height="6"/>
                <rect x="318" y="558" className="st32" width="6" height="6"/>
                <rect x="282" y="570" className="st33" width="6" height="6"/>
                <rect x="258" y="564" className="st34" width="6" height="6"/>
                <rect x="294" y="546" className="st35" width="6" height="6"/>
                <rect x="252" y="564" className="st36" width="6" height="6"/>
                <rect x="294" y="570" className="st37" width="6" height="6"/>
                <rect x="270" y="564" className="st38" width="6" height="6"/>
                <rect x="306" y="546" className="st20" width="6" height="6"/>
                <rect x="282" y="564" className="st39" width="6" height="6"/>
                <rect x="306" y="570" className="st40" width="6" height="6"/>
                <rect x="294" y="564" className="st37" width="6" height="6"/>
                <rect x="318" y="546" className="st24" width="6" height="6"/>
                <rect x="306" y="564" className="st40" width="6" height="6"/>
                <rect x="318" y="570" className="st41" width="6" height="6"/>
                <rect x="318" y="564" className="st42" width="6" height="6"/>
                <rect x="258" y="552" className="st22" width="6" height="6"/>
                <rect x="258" y="576" className="st26" width="6" height="6"/>
                <rect x="252" y="552" className="st27" width="6" height="6"/>
                <rect x="270" y="552" className="st43" width="6" height="6"/>
                <rect x="270" y="576" className="st40" width="6" height="6"/>
                <rect x="270" y="582" className="st44" width="6" height="6"/>
                <rect x="270" y="588" className="st44" width="6" height="6"/>
                <rect x="282" y="552" className="st31" width="6" height="6"/>
                <rect x="282" y="582" className="st40" width="6" height="6"/>
                <rect x="282" y="588" className="st40" width="6" height="6"/>
                <rect x="282" y="576" className="st40" width="6" height="6"/>
                <rect x="282" y="594" className="st45" width="6" height="6"/>
                <rect x="294" y="582" className="st40" width="6" height="6"/>
                <rect x="294" y="552" className="st17" width="6" height="6"/>
                <rect x="288" y="582" className="st40" width="6" height="6"/>
                <rect x="294" y="588" className="st40" width="6" height="6"/>
                <rect x="294" y="576" className="st37" width="6" height="6"/>
                <rect x="300" y="582" className="st40" width="6" height="6"/>
                <rect x="288" y="588" className="st40" width="6" height="6"/>
                <rect x="294" y="594" className="st46" width="6" height="6"/>
                <rect x="288" y="576" className="st40" width="6" height="6"/>
                <rect x="300" y="588" className="st40" width="6" height="6"/>
                <rect x="306" y="582" className="st47" width="6" height="6"/>
                <rect x="288" y="594" className="st46" width="6" height="6"/>
                <rect x="306" y="588" className="st47" width="6" height="6"/>
                <rect x="300" y="576" className="st40" width="6" height="6"/>
                <rect x="318" y="582" className="st48" width="6" height="6"/>
                <rect x="318" y="588" className="st24" width="6" height="6"/>
                <rect x="300" y="594" className="st46" width="6" height="6"/>
                <rect x="306" y="552" className="st20" width="6" height="6"/>
                <rect x="306" y="576" className="st26" width="6" height="6"/>
                <rect x="306" y="594" className="st24" width="6" height="6"/>
                <rect x="318" y="552" className="st24" width="6" height="6"/>
                <rect x="318" y="576" className="st24" width="6" height="6"/>
                <rect x="318" y="594" className="st49" width="6" height="6"/>
                <rect x="264" y="510" className="st50" width="6" height="6"/>
                <rect x="276" y="510" className="st51" width="6" height="6"/>
                <rect x="288" y="510" className="st52" width="6" height="6"/>
                <rect x="288" y="504" className="st53" width="6" height="6"/>
                <rect x="300" y="510" className="st54" width="6" height="6"/>
                <rect x="300" y="504" className="st55" width="6" height="6"/>
                <rect x="312" y="510" className="st56" width="6" height="6"/>
                <rect x="312" y="504" className="st57" width="6" height="6"/>
                <rect x="324" y="510" className="st58" width="6" height="6"/>
                <rect x="330" y="534" className="st17" width="6" height="6"/>
                <rect x="264" y="516" className="st59" width="6" height="6"/>
                <rect x="336" y="534" className="st17" width="6" height="6"/>
                <rect x="342" y="534" className="st60" width="6" height="6"/>
                <rect x="276" y="516" className="st61" width="6" height="6"/>
                <rect x="288" y="516" className="st23" width="6" height="6"/>
                <rect x="300" y="516" className="st11" width="6" height="6"/>
                <rect x="312" y="516" className="st12" width="6" height="6"/>
                <rect x="324" y="516" className="st12" width="6" height="6"/>
                <rect x="264" y="534" className="st13" width="6" height="6"/>
                <rect x="276" y="534" className="st15" width="6" height="6"/>
                <rect x="288" y="534" className="st23" width="6" height="6"/>
                <rect x="300" y="534" className="st12" width="6" height="6"/>
                <rect x="312" y="534" className="st17" width="6" height="6"/>
                <rect x="324" y="534" className="st24" width="6" height="6"/>
                <rect x="330" y="516" className="st62" width="6" height="6"/>
                <rect x="264" y="522" className="st15" width="6" height="6"/>
                <rect x="330" y="558" className="st24" width="6" height="6"/>
                <rect x="330" y="540" className="st24" width="6" height="6"/>
                <rect x="276" y="522" className="st15" width="6" height="6"/>
                <rect x="336" y="558" className="st24" width="6" height="6"/>
                <rect x="336" y="540" className="st17" width="6" height="6"/>
                <rect x="342" y="558" className="st63" width="6" height="6"/>
                <rect x="342" y="540" className="st60" width="6" height="6"/>
                <rect x="264" y="540" className="st22" width="6" height="6"/>
                <rect x="276" y="540" className="st15" width="6" height="6"/>
                <rect x="288" y="522" className="st11" width="6" height="6"/>
                <rect x="300" y="522" className="st23" width="6" height="6"/>
                <rect x="312" y="540" className="st17" width="6" height="6"/>
                <rect x="264" y="558" className="st64" width="6" height="6"/>
                <rect x="324" y="540" className="st24" width="6" height="6"/>
                <rect x="276" y="558" className="st30" width="6" height="6"/>
                <rect x="312" y="522" className="st12" width="6" height="6"/>
                <rect x="288" y="558" className="st37" width="6" height="6"/>
                <rect x="324" y="522" className="st17" width="6" height="6"/>
                <rect x="300" y="558" className="st32" width="6" height="6"/>
                <rect x="330" y="522" className="st65" width="6" height="6"/>
                <rect x="312" y="558" className="st32" width="6" height="6"/>
                <rect x="330" y="546" className="st24" width="6" height="6"/>
                <rect x="324" y="558" className="st24" width="6" height="6"/>
                <rect x="330" y="570" className="st66" width="6" height="6"/>
                <rect x="336" y="522" className="st67" width="6" height="6"/>
                <rect x="336" y="546" className="st17" width="6" height="6"/>
                <rect x="330" y="564" className="st68" width="6" height="6"/>
                <rect x="336" y="570" className="st69" width="6" height="6"/>
                <rect x="342" y="546" className="st70" width="6" height="6"/>
                <rect x="336" y="564" className="st71" width="6" height="6"/>
                <rect x="342" y="564" className="st63" width="6" height="6"/>
                <rect x="264" y="528" className="st13" width="6" height="6"/>
                <rect x="264" y="564" className="st34" width="6" height="6"/>
                <rect x="276" y="528" className="st72" width="6" height="6"/>
                <rect x="276" y="564" className="st30" width="6" height="6"/>
                <rect x="288" y="528" className="st11" width="6" height="6"/>
                <rect x="300" y="528" className="st12" width="6" height="6"/>
                <rect x="312" y="528" className="st12" width="6" height="6"/>
                <rect x="312" y="564" className="st40" width="6" height="6"/>
                <rect x="264" y="546" className="st22" width="6" height="6"/>
                <rect x="324" y="564" className="st73" width="6" height="6"/>
                <rect x="264" y="570" className="st26" width="6" height="6"/>
                <rect x="276" y="546" className="st31" width="6" height="6"/>
                <rect x="276" y="570" className="st26" width="6" height="6"/>
                <rect x="288" y="546" className="st12" width="6" height="6"/>
                <rect x="288" y="570" className="st74" width="6" height="6"/>
                <rect x="288" y="564" className="st37" width="6" height="6"/>
                <rect x="288" y="552" className="st17" width="6" height="6"/>
                <rect x="300" y="552" className="st32" width="6" height="6"/>
                <rect x="300" y="564" className="st37" width="6" height="6"/>
                <rect x="300" y="546" className="st20" width="6" height="6"/>
                <rect x="300" y="570" className="st40" width="6" height="6"/>
                <rect x="312" y="546" className="st17" width="6" height="6"/>
                <rect x="330" y="582" className="st66" width="6" height="6"/>
                <rect x="312" y="570" className="st26" width="6" height="6"/>
                <rect x="330" y="588" className="st24" width="6" height="6"/>
                <rect x="324" y="546" className="st24" width="6" height="6"/>
                <rect x="324" y="570" className="st75" width="6" height="6"/>
                <rect x="336" y="582" className="st24" width="6" height="6"/>
                <rect x="324" y="528" className="st17" width="6" height="6"/>
                <rect x="330" y="528" className="st17" width="6" height="6"/>
                <rect x="330" y="552" className="st24" width="6" height="6"/>
                <rect x="330" y="576" className="st76" width="6" height="6"/>
                <rect x="336" y="528" className="st77" width="6" height="6"/>
                <rect x="276" y="582" className="st44" width="6" height="6"/>
                <rect x="336" y="552" className="st24" width="6" height="6"/>
                <rect x="336" y="576" className="st78" width="6" height="6"/>
                <rect x="276" y="588" className="st44" width="6" height="6"/>
                <rect x="342" y="552" className="st79" width="6" height="6"/>
                <rect x="312" y="582" className="st24" width="6" height="6"/>
                <rect x="264" y="552" className="st21" width="6" height="6"/>
                <rect x="312" y="588" className="st47" width="6" height="6"/>
                <rect x="264" y="576" className="st40" width="6" height="6"/>
                <rect x="324" y="582" className="st75" width="6" height="6"/>
                <rect x="324" y="588" className="st24" width="6" height="6"/>
                <rect x="264" y="582" className="st44" width="6" height="6"/>
                <rect x="276" y="552" className="st31" width="6" height="6"/>
                <rect x="276" y="576" className="st40" width="6" height="6"/>
                <rect x="288" y="540" className="st80" width="6" height="6"/>
                <rect x="300" y="540" className="st12" width="6" height="6"/>
                <rect x="312" y="552" className="st24" width="6" height="6"/>
                <rect x="312" y="576" className="st26" width="6" height="6"/>
                <rect x="312" y="594" className="st81" width="6" height="6"/>
                <rect x="324" y="552" className="st24" width="6" height="6"/>
                <rect x="324" y="576" className="st82" width="6" height="6"/>
                <rect x="1018.1" y="522" className="st1" width="5.9" height="6"/>
                <rect x="1018.1" y="528" className="st2" width="5.9" height="6"/>
                <rect x="1000.3" y="510" className="st3" width="5.9" height="6"/>
                <rect x="988.4" y="510" className="st2" width="5.9" height="6"/>
                <rect x="988.4" y="504" className="st4" width="5.9" height="6"/>
                <rect x="976.5" y="510" className="st5" width="5.9" height="6"/>
                <rect x="976.5" y="504" className="st6" width="5.9" height="6"/>
                <rect x="964.6" y="510" className="st7" width="5.9" height="6"/>
                <rect x="964.6" y="504" className="st8" width="5.9" height="6"/>
                <rect x="952.8" y="510" className="st9" width="5.9" height="6"/>
                <rect x="1012.2" y="516" className="st2" width="5.9" height="6"/>
                <rect x="1000.3" y="516" className="st1" width="5.9" height="6"/>
                <rect x="988.4" y="516" className="st10" width="5.9" height="6"/>
                <rect x="976.5" y="516" className="st11" width="5.9" height="6"/>
                <rect x="964.6" y="516" className="st11" width="5.9" height="6"/>
                <rect x="952.8" y="516" className="st12" width="5.9" height="6"/>
                <rect x="1012.2" y="522" className="st2" width="5.9" height="6"/>
                <rect x="1000.3" y="522" className="st13" width="5.9" height="6"/>
                <rect x="988.4" y="522" className="st14" width="5.9" height="6"/>
                <rect x="976.5" y="522" className="st11" width="5.9" height="6"/>
                <rect x="964.6" y="522" className="st12" width="5.9" height="6"/>
                <rect x="952.8" y="522" className="st12" width="5.9" height="6"/>
                <rect x="1012.2" y="528" className="st2" width="5.9" height="6"/>
                <rect x="1000.3" y="528" className="st15" width="5.9" height="6"/>
                <rect x="988.4" y="528" className="st16" width="5.9" height="6"/>
                <rect x="976.5" y="528" className="st11" width="5.9" height="6"/>
                <rect x="964.6" y="528" className="st12" width="5.9" height="6"/>
                <rect x="952.8" y="528" className="st17" width="5.9" height="6"/>
                <rect x="1012.2" y="534" className="st13" width="5.9" height="6"/>
                <rect x="1018.1" y="534" className="st18" width="5.9" height="6"/>
                <rect x="1000.3" y="534" className="st13" width="5.9" height="6"/>
                <rect x="988.4" y="534" className="st19" width="5.9" height="6"/>
                <rect x="976.5" y="534" className="st11" width="5.9" height="6"/>
                <rect x="964.6" y="534" className="st20" width="5.9" height="6"/>
                <rect x="952.8" y="534" className="st17" width="5.9" height="6"/>
                <rect x="1012.2" y="540" className="st21" width="5.9" height="6"/>
                <rect x="1018.1" y="540" className="st22" width="5.9" height="6"/>
                <rect x="1000.3" y="540" className="st13" width="5.9" height="6"/>
                <rect x="988.4" y="540" className="st12" width="5.9" height="6"/>
                <rect x="976.5" y="540" className="st23" width="5.9" height="6"/>
                <rect x="964.6" y="540" className="st20" width="5.9" height="6"/>
                <rect x="952.8" y="540" className="st24" width="5.9" height="6"/>
                <rect x="1012.2" y="546" className="st22" width="5.9" height="6"/>
                <rect x="1012.2" y="558" className="st25" width="5.9" height="6"/>
                <rect x="1012.2" y="570" className="st26" width="5.9" height="6"/>
                <rect x="1018.1" y="558" className="st27" width="5.9" height="6"/>
                <rect x="1018.1" y="546" className="st28" width="5.9" height="6"/>
                <rect x="1000.3" y="558" className="st29" width="5.9" height="6"/>
                <rect x="988.4" y="558" className="st30" width="5.9" height="6"/>
                <rect x="1000.3" y="546" className="st31" width="5.9" height="6"/>
                <rect x="976.5" y="558" className="st32" width="5.9" height="6"/>
                <rect x="1000.3" y="570" className="st26" width="5.9" height="6"/>
                <rect x="964.6" y="558" className="st32" width="5.9" height="6"/>
                <rect x="988.4" y="546" className="st31" width="5.9" height="6"/>
                <rect x="952.8" y="558" className="st32" width="5.9" height="6"/>
                <rect x="988.4" y="570" className="st33" width="5.9" height="6"/>
                <rect x="1012.2" y="564" className="st34" width="5.9" height="6"/>
                <rect x="976.5" y="546" className="st35" width="5.9" height="6"/>
                <rect x="1018.1" y="564" className="st36" width="5.9" height="6"/>
                <rect x="976.5" y="570" className="st37" width="5.9" height="6"/>
                <rect x="1000.3" y="564" className="st38" width="5.9" height="6"/>
                <rect x="964.6" y="546" className="st20" width="5.9" height="6"/>
                <rect x="988.4" y="564" className="st39" width="5.9" height="6"/>
                <rect x="964.6" y="570" className="st40" width="5.9" height="6"/>
                <rect x="976.5" y="564" className="st37" width="5.9" height="6"/>
                <rect x="952.8" y="546" className="st24" width="5.9" height="6"/>
                <rect x="964.6" y="564" className="st40" width="5.9" height="6"/>
                <rect x="952.8" y="570" className="st41" width="5.9" height="6"/>
                <rect x="952.8" y="564" className="st42" width="5.9" height="6"/>
                <rect x="1012.2" y="552" className="st22" width="5.9" height="6"/>
                <rect x="1012.2" y="576" className="st26" width="5.9" height="6"/>
                <rect x="1018.1" y="552" className="st27" width="5.9" height="6"/>
                <rect x="1000.3" y="552" className="st43" width="5.9" height="6"/>
                <rect x="1000.3" y="576" className="st40" width="5.9" height="6"/>
                <rect x="1000.3" y="582" className="st44" width="5.9" height="6"/>
                <rect x="1000.3" y="588" className="st44" width="5.9" height="6"/>
                <rect x="988.4" y="552" className="st31" width="5.9" height="6"/>
                <rect x="988.4" y="582" className="st40" width="5.9" height="6"/>
                <rect x="988.4" y="588" className="st40" width="5.9" height="6"/>
                <rect x="988.4" y="576" className="st40" width="5.9" height="6"/>
                <rect x="988.4" y="594" className="st45" width="5.9" height="6"/>
                <rect x="976.5" y="582" className="st40" width="5.9" height="6"/>
                <rect x="976.5" y="552" className="st17" width="5.9" height="6"/>
                <rect x="982.5" y="582" className="st40" width="5.9" height="6"/>
                <rect x="976.5" y="588" className="st40" width="5.9" height="6"/>
                <rect x="976.5" y="576" className="st37" width="5.9" height="6"/>
                <rect x="970.6" y="582" className="st40" width="5.9" height="6"/>
                <rect x="982.5" y="588" className="st40" width="5.9" height="6"/>
                <rect x="976.5" y="594" className="st46" width="5.9" height="6"/>
                <rect x="982.5" y="576" className="st40" width="5.9" height="6"/>
                <rect x="970.6" y="588" className="st40" width="5.9" height="6"/>
                <rect x="964.6" y="582" className="st47" width="5.9" height="6"/>
                <rect x="982.5" y="594" className="st46" width="5.9" height="6"/>
                <rect x="964.6" y="588" className="st47" width="5.9" height="6"/>
                <rect x="970.6" y="576" className="st40" width="5.9" height="6"/>
                <rect x="952.8" y="582" className="st48" width="5.9" height="6"/>
                <rect x="952.8" y="588" className="st24" width="5.9" height="6"/>
                <rect x="970.6" y="594" className="st46" width="5.9" height="6"/>
                <rect x="964.6" y="552" className="st20" width="5.9" height="6"/>
                <rect x="964.6" y="576" className="st26" width="5.9" height="6"/>
                <rect x="964.6" y="594" className="st24" width="5.9" height="6"/>
                <rect x="952.8" y="552" className="st24" width="5.9" height="6"/>
                <rect x="952.8" y="576" className="st24" width="5.9" height="6"/>
                <rect x="952.8" y="594" className="st49" width="5.9" height="6"/>
                <rect x="1006.2" y="510" className="st50" width="5.9" height="6"/>
                <rect x="994.4" y="510" className="st51" width="5.9" height="6"/>
                <rect x="982.5" y="510" className="st52" width="5.9" height="6"/>
                <rect x="982.5" y="504" className="st53" width="5.9" height="6"/>
                <rect x="970.6" y="510" className="st54" width="5.9" height="6"/>
                <rect x="970.6" y="504" className="st55" width="5.9" height="6"/>
                <rect x="958.7" y="510" className="st56" width="5.9" height="6"/>
                <rect x="958.7" y="504" className="st57" width="5.9" height="6"/>
                <rect x="946.8" y="510" className="st58" width="5.9" height="6"/>
                <rect x="940.9" y="534" className="st17" width="5.9" height="6"/>
                <rect x="1006.2" y="516" className="st59" width="5.9" height="6"/>
                <rect x="934.9" y="534" className="st17" width="5.9" height="6"/>
                <rect x="929" y="534" className="st60" width="5.9" height="6"/>
                <rect x="994.4" y="516" className="st61" width="5.9" height="6"/>
                <rect x="982.5" y="516" className="st23" width="5.9" height="6"/>
                <rect x="970.6" y="516" className="st11" width="5.9" height="6"/>
                <rect x="958.7" y="516" className="st12" width="5.9" height="6"/>
                <rect x="946.8" y="516" className="st12" width="5.9" height="6"/>
                <rect x="1006.2" y="534" className="st13" width="5.9" height="6"/>
                <rect x="994.4" y="534" className="st15" width="5.9" height="6"/>
                <rect x="982.5" y="534" className="st23" width="5.9" height="6"/>
                <rect x="970.6" y="534" className="st12" width="5.9" height="6"/>
                <rect x="958.7" y="534" className="st17" width="5.9" height="6"/>
                <rect x="946.8" y="534" className="st24" width="5.9" height="6"/>
                <rect x="940.9" y="516" className="st62" width="5.9" height="6"/>
                <rect x="1006.2" y="522" className="st15" width="5.9" height="6"/>
                <rect x="940.9" y="558" className="st24" width="5.9" height="6"/>
                <rect x="940.9" y="540" className="st24" width="5.9" height="6"/>
                <rect x="994.4" y="522" className="st15" width="5.9" height="6"/>
                <rect x="934.9" y="558" className="st24" width="5.9" height="6"/>
                <rect x="934.9" y="540" className="st17" width="5.9" height="6"/>
                <rect x="929" y="558" className="st63" width="5.9" height="6"/>
                <rect x="929" y="540" className="st60" width="5.9" height="6"/>
                <rect x="1006.2" y="540" className="st22" width="5.9" height="6"/>
                <rect x="994.4" y="540" className="st15" width="5.9" height="6"/>
                <rect x="982.5" y="522" className="st11" width="5.9" height="6"/>
                <rect x="970.6" y="522" className="st23" width="5.9" height="6"/>
                <rect x="958.7" y="540" className="st17" width="5.9" height="6"/>
                <rect x="1006.2" y="558" className="st64" width="5.9" height="6"/>
                <rect x="946.8" y="540" className="st24" width="5.9" height="6"/>
                <rect x="994.4" y="558" className="st30" width="5.9" height="6"/>
                <rect x="958.7" y="522" className="st12" width="5.9" height="6"/>
                <rect x="982.5" y="558" className="st37" width="5.9" height="6"/>
                <rect x="946.8" y="522" className="st17" width="5.9" height="6"/>
                <rect x="970.6" y="558" className="st32" width="5.9" height="6"/>
                <rect x="940.9" y="522" className="st65" width="5.9" height="6"/>
                <rect x="958.7" y="558" className="st32" width="5.9" height="6"/>
                <rect x="940.9" y="546" className="st24" width="5.9" height="6"/>
                <rect x="946.8" y="558" className="st24" width="5.9" height="6"/>
                <rect x="940.9" y="570" className="st66" width="5.9" height="6"/>
                <rect x="934.9" y="522" className="st67" width="5.9" height="6"/>
                <rect x="934.9" y="546" className="st17" width="5.9" height="6"/>
                <rect x="940.9" y="564" className="st68" width="5.9" height="6"/>
                <rect x="934.9" y="570" className="st69" width="5.9" height="6"/>
                <rect x="929" y="546" className="st70" width="5.9" height="6"/>
                <rect x="934.9" y="564" className="st71" width="5.9" height="6"/>
                <rect x="929" y="564" className="st63" width="5.9" height="6"/>
                <rect x="1006.2" y="528" className="st13" width="5.9" height="6"/>
                <rect x="1006.2" y="564" className="st34" width="5.9" height="6"/>
                <rect x="994.4" y="528" className="st72" width="5.9" height="6"/>
                <rect x="994.4" y="564" className="st30" width="5.9" height="6"/>
                <rect x="982.5" y="528" className="st11" width="5.9" height="6"/>
                <rect x="970.6" y="528" className="st12" width="5.9" height="6"/>
                <rect x="958.7" y="528" className="st12" width="5.9" height="6"/>
                <rect x="958.7" y="564" className="st40" width="5.9" height="6"/>
                <rect x="1006.2" y="546" className="st22" width="5.9" height="6"/>
                <rect x="946.8" y="564" className="st73" width="5.9" height="6"/>
                <rect x="1006.2" y="570" className="st26" width="5.9" height="6"/>
                <rect x="994.4" y="546" className="st31" width="5.9" height="6"/>
                <rect x="994.4" y="570" className="st26" width="5.9" height="6"/>
                <rect x="982.5" y="546" className="st12" width="5.9" height="6"/>
                <rect x="982.5" y="570" className="st74" width="5.9" height="6"/>
                <rect x="982.5" y="564" className="st37" width="5.9" height="6"/>
                <rect x="982.5" y="552" className="st17" width="5.9" height="6"/>
                <rect x="970.6" y="552" className="st32" width="5.9" height="6"/>
                <rect x="970.6" y="564" className="st37" width="5.9" height="6"/>
                <rect x="970.6" y="546" className="st20" width="5.9" height="6"/>
                <rect x="970.6" y="570" className="st40" width="5.9" height="6"/>
                <rect x="958.7" y="546" className="st17" width="5.9" height="6"/>
                <rect x="940.9" y="582" className="st66" width="5.9" height="6"/>
                <rect x="958.7" y="570" className="st26" width="5.9" height="6"/>
                <rect x="940.9" y="588" className="st24" width="5.9" height="6"/>
                <rect x="946.8" y="546" className="st24" width="5.9" height="6"/>
                <rect x="946.8" y="570" className="st75" width="5.9" height="6"/>
                <rect x="934.9" y="582" className="st24" width="5.9" height="6"/>
                <rect x="946.8" y="528" className="st17" width="5.9" height="6"/>
                <rect x="940.9" y="528" className="st17" width="5.9" height="6"/>
                <rect x="940.9" y="552" className="st24" width="5.9" height="6"/>
                <rect x="940.9" y="576" className="st76" width="5.9" height="6"/>
                <rect x="934.9" y="528" className="st77" width="5.9" height="6"/>
                <rect x="994.4" y="582" className="st44" width="5.9" height="6"/>
                <rect x="934.9" y="552" className="st24" width="5.9" height="6"/>
                <rect x="934.9" y="576" className="st78" width="5.9" height="6"/>
                <rect x="994.4" y="588" className="st44" width="5.9" height="6"/>
                <rect x="929" y="552" className="st79" width="5.9" height="6"/>
                <rect x="958.7" y="582" className="st24" width="5.9" height="6"/>
                <rect x="1006.2" y="552" className="st21" width="5.9" height="6"/>
                <rect x="958.7" y="588" className="st47" width="5.9" height="6"/>
                <rect x="1006.2" y="576" className="st40" width="5.9" height="6"/>
                <rect x="946.8" y="582" className="st75" width="5.9" height="6"/>
                <rect x="946.8" y="588" className="st24" width="5.9" height="6"/>
                <rect x="1006.2" y="582" className="st44" width="5.9" height="6"/>
                <rect x="994.4" y="552" className="st31" width="5.9" height="6"/>
                <rect x="994.4" y="576" className="st40" width="5.9" height="6"/>
                <rect x="982.5" y="540" className="st80" width="5.9" height="6"/>
                <rect x="970.6" y="540" className="st12" width="5.9" height="6"/>
                <rect x="958.7" y="552" className="st24" width="5.9" height="6"/>
                <rect x="958.7" y="576" className="st26" width="5.9" height="6"/>
                <rect x="958.7" y="594" className="st81" width="5.9" height="6"/>
                <rect x="946.8" y="552" className="st24" width="5.9" height="6"/>
                <rect x="946.8" y="576" className="st82" width="5.9" height="6"/>
                <rect x="333" y="537.4" className="st1" width="4.1" height="4.1"/>
                <rect x="333" y="541.5" className="st2" width="4.1" height="4.1"/>
                <rect x="345.4" y="529.1" className="st3" width="4.1" height="4.1"/>
                <rect x="353.6" y="529.1" className="st2" width="4.1" height="4.1"/>
                <rect x="353.6" y="525" className="st4" width="4.1" height="4.1"/>
                <rect x="361.9" y="529.1" className="st5" width="4.1" height="4.1"/>
                <rect x="361.9" y="525" className="st6" width="4.1" height="4.1"/>
                <rect x="370.1" y="529.1" className="st7" width="4.1" height="4.1"/>
                <rect x="370.1" y="525" className="st8" width="4.1" height="4.1"/>
                <rect x="378.4" y="529.1" className="st9" width="4.1" height="4.1"/>
                <rect x="337.1" y="533.2" className="st2" width="4.1" height="4.1"/>
                <rect x="345.4" y="533.2" className="st1" width="4.1" height="4.1"/>
                <rect x="353.6" y="533.2" className="st10" width="4.1" height="4.1"/>
                <rect x="361.9" y="533.2" className="st11" width="4.1" height="4.1"/>
                <rect x="370.1" y="533.2" className="st11" width="4.1" height="4.1"/>
                <rect x="378.4" y="533.2" className="st12" width="4.1" height="4.1"/>
                <rect x="337.1" y="537.4" className="st2" width="4.1" height="4.1"/>
                <rect x="345.4" y="537.4" className="st13" width="4.1" height="4.1"/>
                <rect x="353.6" y="537.4" className="st14" width="4.1" height="4.1"/>
                <rect x="361.9" y="537.4" className="st11" width="4.1" height="4.1"/>
                <rect x="370.1" y="537.4" className="st12" width="4.1" height="4.1"/>
                <rect x="378.4" y="537.4" className="st12" width="4.1" height="4.1"/>
                <rect x="337.1" y="541.5" className="st2" width="4.1" height="4.1"/>
                <rect x="345.4" y="541.5" className="st15" width="4.1" height="4.1"/>
                <rect x="353.6" y="541.5" className="st16" width="4.1" height="4.1"/>
                <rect x="361.9" y="541.5" className="st11" width="4.1" height="4.1"/>
                <rect x="370.1" y="541.5" className="st12" width="4.1" height="4.1"/>
                <rect x="378.4" y="541.5" className="st17" width="4.1" height="4.1"/>
                <rect x="337.1" y="545.6" className="st13" width="4.1" height="4.1"/>
                <rect x="333" y="545.6" className="st18" width="4.1" height="4.1"/>
                <rect x="345.4" y="545.6" className="st13" width="4.1" height="4.1"/>
                <rect x="353.6" y="545.6" className="st19" width="4.1" height="4.1"/>
                <rect x="361.9" y="545.6" className="st11" width="4.1" height="4.1"/>
                <rect x="370.1" y="545.6" className="st20" width="4.1" height="4.1"/>
                <rect x="378.4" y="545.6" className="st17" width="4.1" height="4.1"/>
                <rect x="337.1" y="549.8" className="st21" width="4.1" height="4.1"/>
                <rect x="333" y="549.8" className="st22" width="4.1" height="4.1"/>
                <rect x="345.4" y="549.8" className="st13" width="4.1" height="4.1"/>
                <rect x="353.6" y="549.8" className="st12" width="4.1" height="4.1"/>
                <rect x="361.9" y="549.8" className="st23" width="4.1" height="4.1"/>
                <rect x="370.1" y="549.8" className="st20" width="4.1" height="4.1"/>
                <rect x="378.4" y="549.8" className="st24" width="4.1" height="4.1"/>
                <rect x="337.1" y="553.9" className="st22" width="4.1" height="4.1"/>
                <rect x="337.1" y="562.1" className="st25" width="4.1" height="4.1"/>
                <rect x="337.1" y="570.4" className="st26" width="4.1" height="4.1"/>
                <rect x="333" y="562.1" className="st27" width="4.1" height="4.1"/>
                <rect x="333" y="553.9" className="st28" width="4.1" height="4.1"/>
                <rect x="345.4" y="562.1" className="st29" width="4.1" height="4.1"/>
                <rect x="353.6" y="562.1" className="st30" width="4.1" height="4.1"/>
                <rect x="345.4" y="553.9" className="st31" width="4.1" height="4.1"/>
                <rect x="361.9" y="562.1" className="st32" width="4.1" height="4.1"/>
                <rect x="345.4" y="570.4" className="st26" width="4.1" height="4.1"/>
                <rect x="370.1" y="562.1" className="st32" width="4.1" height="4.1"/>
                <rect x="353.6" y="553.9" className="st31" width="4.1" height="4.1"/>
                <rect x="378.4" y="562.1" className="st32" width="4.1" height="4.1"/>
                <rect x="353.6" y="570.4" className="st33" width="4.1" height="4.1"/>
                <rect x="337.1" y="566.2" className="st34" width="4.1" height="4.1"/>
                <rect x="361.9" y="553.9" className="st35" width="4.1" height="4.1"/>
                <rect x="333" y="566.2" className="st36" width="4.1" height="4.1"/>
                <rect x="361.9" y="570.4" className="st37" width="4.1" height="4.1"/>
                <rect x="345.4" y="566.2" className="st38" width="4.1" height="4.1"/>
                <rect x="370.1" y="553.9" className="st20" width="4.1" height="4.1"/>
                <rect x="353.6" y="566.2" className="st39" width="4.1" height="4.1"/>
                <rect x="370.1" y="570.4" className="st40" width="4.1" height="4.1"/>
                <rect x="361.9" y="566.2" className="st37" width="4.1" height="4.1"/>
                <rect x="378.4" y="553.9" className="st24" width="4.1" height="4.1"/>
                <rect x="370.1" y="566.2" className="st40" width="4.1" height="4.1"/>
                <rect x="378.4" y="570.4" className="st41" width="4.1" height="4.1"/>
                <rect x="378.4" y="566.2" className="st42" width="4.1" height="4.1"/>
                <rect x="337.1" y="558" className="st22" width="4.1" height="4.1"/>
                <rect x="337.1" y="574.5" className="st26" width="4.1" height="4.1"/>
                <rect x="333" y="558" className="st27" width="4.1" height="4.1"/>
                <rect x="345.4" y="558" className="st43" width="4.1" height="4.1"/>
                <rect x="345.4" y="574.5" className="st40" width="4.1" height="4.1"/>
                <rect x="345.4" y="578.6" className="st44" width="4.1" height="4.1"/>
                <rect x="345.4" y="582.8" className="st44" width="4.1" height="4.1"/>
                <rect x="353.6" y="558" className="st31" width="4.1" height="4.1"/>
                <rect x="353.6" y="578.6" className="st40" width="4.1" height="4.1"/>
                <rect x="353.6" y="582.8" className="st40" width="4.1" height="4.1"/>
                <rect x="353.6" y="574.5" className="st40" width="4.1" height="4.1"/>
                <rect x="353.6" y="586.9" className="st45" width="4.1" height="4.1"/>
                <rect x="361.9" y="578.6" className="st40" width="4.1" height="4.1"/>
                <rect x="361.9" y="558" className="st17" width="4.1" height="4.1"/>
                <rect x="357.8" y="578.6" className="st40" width="4.1" height="4.1"/>
                <rect x="361.9" y="582.8" className="st40" width="4.1" height="4.1"/>
                <rect x="361.9" y="574.5" className="st37" width="4.1" height="4.1"/>
                <rect x="366" y="578.6" className="st40" width="4.1" height="4.1"/>
                <rect x="357.8" y="582.8" className="st40" width="4.1" height="4.1"/>
                <rect x="361.9" y="586.9" className="st46" width="4.1" height="4.1"/>
                <rect x="357.8" y="574.5" className="st40" width="4.1" height="4.1"/>
                <rect x="366" y="582.8" className="st40" width="4.1" height="4.1"/>
                <rect x="370.1" y="578.6" className="st47" width="4.1" height="4.1"/>
                <rect x="357.8" y="586.9" className="st46" width="4.1" height="4.1"/>
                <rect x="370.1" y="582.8" className="st47" width="4.1" height="4.1"/>
                <rect x="366" y="574.5" className="st40" width="4.1" height="4.1"/>
                <rect x="378.4" y="578.6" className="st48" width="4.1" height="4.1"/>
                <rect x="378.4" y="582.8" className="st24" width="4.1" height="4.1"/>
                <rect x="366" y="586.9" className="st46" width="4.1" height="4.1"/>
                <rect x="370.1" y="558" className="st20" width="4.1" height="4.1"/>
                <rect x="370.1" y="574.5" className="st26" width="4.1" height="4.1"/>
                <rect x="370.1" y="586.9" className="st24" width="4.1" height="4.1"/>
                <rect x="378.4" y="558" className="st24" width="4.1" height="4.1"/>
                <rect x="378.4" y="574.5" className="st24" width="4.1" height="4.1"/>
                <rect x="378.4" y="586.9" className="st49" width="4.1" height="4.1"/>
                <rect x="341.2" y="529.1" className="st50" width="4.1" height="4.1"/>
                <rect x="349.5" y="529.1" className="st51" width="4.1" height="4.1"/>
                <rect x="357.8" y="529.1" className="st52" width="4.1" height="4.1"/>
                <rect x="357.8" y="525" className="st53" width="4.1" height="4.1"/>
                <rect x="366" y="529.1" className="st54" width="4.1" height="4.1"/>
                <rect x="366" y="525" className="st55" width="4.1" height="4.1"/>
                <rect x="374.2" y="529.1" className="st56" width="4.1" height="4.1"/>
                <rect x="374.2" y="525" className="st57" width="4.1" height="4.1"/>
                <rect x="382.5" y="529.1" className="st58" width="4.1" height="4.1"/>
                <rect x="386.6" y="545.6" className="st17" width="4.1" height="4.1"/>
                <rect x="341.2" y="533.2" className="st59" width="4.1" height="4.1"/>
                <rect x="390.8" y="545.6" className="st17" width="4.1" height="4.1"/>
                <rect x="394.9" y="545.6" className="st60" width="4.1" height="4.1"/>
                <rect x="349.5" y="533.2" className="st61" width="4.1" height="4.1"/>
                <rect x="357.8" y="533.2" className="st23" width="4.1" height="4.1"/>
                <rect x="366" y="533.2" className="st11" width="4.1" height="4.1"/>
                <rect x="374.2" y="533.2" className="st12" width="4.1" height="4.1"/>
                <rect x="382.5" y="533.2" className="st12" width="4.1" height="4.1"/>
                <rect x="341.2" y="545.6" className="st13" width="4.1" height="4.1"/>
                <rect x="349.5" y="545.6" className="st15" width="4.1" height="4.1"/>
                <rect x="357.8" y="545.6" className="st23" width="4.1" height="4.1"/>
                <rect x="366" y="545.6" className="st12" width="4.1" height="4.1"/>
                <rect x="374.2" y="545.6" className="st17" width="4.1" height="4.1"/>
                <rect x="382.5" y="545.6" className="st24" width="4.1" height="4.1"/>
                <rect x="386.6" y="533.2" className="st62" width="4.1" height="4.1"/>
                <rect x="341.2" y="537.4" className="st15" width="4.1" height="4.1"/>
                <rect x="386.6" y="562.1" className="st24" width="4.1" height="4.1"/>
                <rect x="386.6" y="549.8" className="st24" width="4.1" height="4.1"/>
                <rect x="349.5" y="537.4" className="st15" width="4.1" height="4.1"/>
                <rect x="390.8" y="562.1" className="st24" width="4.1" height="4.1"/>
                <rect x="390.8" y="549.8" className="st17" width="4.1" height="4.1"/>
                <rect x="394.9" y="562.1" className="st63" width="4.1" height="4.1"/>
                <rect x="394.9" y="549.8" className="st60" width="4.1" height="4.1"/>
                <rect x="341.2" y="549.8" className="st22" width="4.1" height="4.1"/>
                <rect x="349.5" y="549.8" className="st15" width="4.1" height="4.1"/>
                <rect x="357.8" y="537.4" className="st11" width="4.1" height="4.1"/>
                <rect x="366" y="537.4" className="st23" width="4.1" height="4.1"/>
                <rect x="374.2" y="549.8" className="st17" width="4.1" height="4.1"/>
                <rect x="341.2" y="562.1" className="st64" width="4.1" height="4.1"/>
                <rect x="382.5" y="549.8" className="st24" width="4.1" height="4.1"/>
                <rect x="349.5" y="562.1" className="st30" width="4.1" height="4.1"/>
                <rect x="374.2" y="537.4" className="st12" width="4.1" height="4.1"/>
                <rect x="357.8" y="562.1" className="st37" width="4.1" height="4.1"/>
                <rect x="382.5" y="537.4" className="st17" width="4.1" height="4.1"/>
                <rect x="366" y="562.1" className="st32" width="4.1" height="4.1"/>
                <rect x="386.6" y="537.4" className="st65" width="4.1" height="4.1"/>
                <rect x="374.2" y="562.1" className="st32" width="4.1" height="4.1"/>
                <rect x="386.6" y="553.9" className="st24" width="4.1" height="4.1"/>
                <rect x="382.5" y="562.1" className="st24" width="4.1" height="4.1"/>
                <rect x="386.6" y="570.4" className="st66" width="4.1" height="4.1"/>
                <rect x="390.8" y="537.4" className="st67" width="4.1" height="4.1"/>
                <rect x="390.8" y="553.9" className="st17" width="4.1" height="4.1"/>
                <rect x="386.6" y="566.2" className="st68" width="4.1" height="4.1"/>
                <rect x="390.8" y="570.4" className="st69" width="4.1" height="4.1"/>
                <rect x="394.9" y="553.9" className="st70" width="4.1" height="4.1"/>
                <rect x="390.8" y="566.2" className="st71" width="4.1" height="4.1"/>
                <rect x="394.9" y="566.2" className="st63" width="4.1" height="4.1"/>
                <rect x="341.2" y="541.5" className="st13" width="4.1" height="4.1"/>
                <rect x="341.2" y="566.2" className="st34" width="4.1" height="4.1"/>
                <rect x="349.5" y="541.5" className="st72" width="4.1" height="4.1"/>
                <rect x="349.5" y="566.2" className="st30" width="4.1" height="4.1"/>
                <rect x="357.8" y="541.5" className="st11" width="4.1" height="4.1"/>
                <rect x="366" y="541.5" className="st12" width="4.1" height="4.1"/>
                <rect x="374.2" y="541.5" className="st12" width="4.1" height="4.1"/>
                <rect x="374.2" y="566.2" className="st40" width="4.1" height="4.1"/>
                <rect x="341.2" y="553.9" className="st22" width="4.1" height="4.1"/>
                <rect x="382.5" y="566.2" className="st73" width="4.1" height="4.1"/>
                <rect x="341.2" y="570.4" className="st26" width="4.1" height="4.1"/>
                <rect x="349.5" y="553.9" className="st31" width="4.1" height="4.1"/>
                <rect x="349.5" y="570.4" className="st26" width="4.1" height="4.1"/>
                <rect x="357.8" y="553.9" className="st12" width="4.1" height="4.1"/>
                <rect x="357.8" y="570.4" className="st74" width="4.1" height="4.1"/>
                <rect x="357.8" y="566.2" className="st37" width="4.1" height="4.1"/>
                <rect x="357.8" y="558" className="st17" width="4.1" height="4.1"/>
                <rect x="366" y="558" className="st32" width="4.1" height="4.1"/>
                <rect x="366" y="566.2" className="st37" width="4.1" height="4.1"/>
                <rect x="366" y="553.9" className="st20" width="4.1" height="4.1"/>
                <rect x="366" y="570.4" className="st40" width="4.1" height="4.1"/>
                <rect x="374.2" y="553.9" className="st17" width="4.1" height="4.1"/>
                <rect x="386.6" y="578.6" className="st66" width="4.1" height="4.1"/>
                <rect x="374.2" y="570.4" className="st26" width="4.1" height="4.1"/>
                <rect x="386.6" y="582.8" className="st24" width="4.1" height="4.1"/>
                <rect x="382.5" y="553.9" className="st24" width="4.1" height="4.1"/>
                <rect x="382.5" y="570.4" className="st75" width="4.1" height="4.1"/>
                <rect x="390.8" y="578.6" className="st24" width="4.1" height="4.1"/>
                <rect x="382.5" y="541.5" className="st17" width="4.1" height="4.1"/>
                <rect x="386.6" y="541.5" className="st17" width="4.1" height="4.1"/>
                <rect x="386.6" y="558" className="st24" width="4.1" height="4.1"/>
                <rect x="386.6" y="574.5" className="st76" width="4.1" height="4.1"/>
                <rect x="390.8" y="541.5" className="st77" width="4.1" height="4.1"/>
                <rect x="349.5" y="578.6" className="st44" width="4.1" height="4.1"/>
                <rect x="390.8" y="558" className="st24" width="4.1" height="4.1"/>
                <rect x="390.8" y="574.5" className="st78" width="4.1" height="4.1"/>
                <rect x="349.5" y="582.8" className="st44" width="4.1" height="4.1"/>
                <rect x="394.9" y="558" className="st79" width="4.1" height="4.1"/>
                <rect x="374.2" y="578.6" className="st24" width="4.1" height="4.1"/>
                <rect x="341.2" y="558" className="st21" width="4.1" height="4.1"/>
                <rect x="374.2" y="582.8" className="st47" width="4.1" height="4.1"/>
                <rect x="341.2" y="574.5" className="st40" width="4.1" height="4.1"/>
                <rect x="382.5" y="578.6" className="st75" width="4.1" height="4.1"/>
                <rect x="382.5" y="582.8" className="st24" width="4.1" height="4.1"/>
                <rect x="341.2" y="578.6" className="st44" width="4.1" height="4.1"/>
                <rect x="349.5" y="558" className="st31" width="4.1" height="4.1"/>
                <rect x="349.5" y="574.5" className="st40" width="4.1" height="4.1"/>
                <rect x="357.8" y="549.8" className="st80" width="4.1" height="4.1"/>
                <rect x="366" y="549.8" className="st12" width="4.1" height="4.1"/>
                <rect x="374.2" y="558" className="st24" width="4.1" height="4.1"/>
                <rect x="374.2" y="574.5" className="st26" width="4.1" height="4.1"/>
                <rect x="374.2" y="586.9" className="st81" width="4.1" height="4.1"/>
                <rect x="382.5" y="558" className="st24" width="4.1" height="4.1"/>
                <rect x="382.5" y="574.5" className="st82" width="4.1" height="4.1"/>
                <rect x="1068.3" y="537.4" className="st1" width="4.1" height="4.1"/>
                <rect x="1068.3" y="541.5" className="st2" width="4.1" height="4.1"/>
                <rect x="1056" y="529.1" className="st3" width="4.1" height="4.1"/>
                <rect x="1047.8" y="529.1" className="st2" width="4.1" height="4.1"/>
                <rect x="1047.8" y="525" className="st4" width="4.1" height="4.1"/>
                <rect x="1039.7" y="529.1" className="st5" width="4.1" height="4.1"/>
                <rect x="1039.7" y="525" className="st6" width="4.1" height="4.1"/>
                <rect x="1031.5" y="529.1" className="st7" width="4.1" height="4.1"/>
                <rect x="1031.5" y="525" className="st8" width="4.1" height="4.1"/>
                <rect x="1023.3" y="529.1" className="st9" width="4.1" height="4.1"/>
                <rect x="1064.2" y="533.2" className="st2" width="4.1" height="4.1"/>
                <rect x="1056" y="533.2" className="st1" width="4.1" height="4.1"/>
                <rect x="1047.8" y="533.2" className="st10" width="4.1" height="4.1"/>
                <rect x="1039.7" y="533.2" className="st11" width="4.1" height="4.1"/>
                <rect x="1031.5" y="533.2" className="st11" width="4.1" height="4.1"/>
                <rect x="1023.3" y="533.2" className="st12" width="4.1" height="4.1"/>
                <rect x="1064.2" y="537.4" className="st2" width="4.1" height="4.1"/>
                <rect x="1056" y="537.4" className="st13" width="4.1" height="4.1"/>
                <rect x="1047.8" y="537.4" className="st14" width="4.1" height="4.1"/>
                <rect x="1039.7" y="537.4" className="st11" width="4.1" height="4.1"/>
                <rect x="1031.5" y="537.4" className="st12" width="4.1" height="4.1"/>
                <rect x="1023.3" y="537.4" className="st12" width="4.1" height="4.1"/>
                <rect x="1064.2" y="541.5" className="st2" width="4.1" height="4.1"/>
                <rect x="1056" y="541.5" className="st15" width="4.1" height="4.1"/>
                <rect x="1047.8" y="541.5" className="st16" width="4.1" height="4.1"/>
                <rect x="1039.7" y="541.5" className="st11" width="4.1" height="4.1"/>
                <rect x="1031.5" y="541.5" className="st12" width="4.1" height="4.1"/>
                <rect x="1023.3" y="541.5" className="st17" width="4.1" height="4.1"/>
                <rect x="1064.2" y="545.6" className="st13" width="4.1" height="4.1"/>
                <rect x="1068.3" y="545.6" className="st18" width="4.1" height="4.1"/>
                <rect x="1056" y="545.6" className="st13" width="4.1" height="4.1"/>
                <rect x="1047.8" y="545.6" className="st19" width="4.1" height="4.1"/>
                <rect x="1039.7" y="545.6" className="st11" width="4.1" height="4.1"/>
                <rect x="1031.5" y="545.6" className="st20" width="4.1" height="4.1"/>
                <rect x="1023.3" y="545.6" className="st17" width="4.1" height="4.1"/>
                <rect x="1064.2" y="549.8" className="st21" width="4.1" height="4.1"/>
                <rect x="1068.3" y="549.8" className="st22" width="4.1" height="4.1"/>
                <rect x="1056" y="549.8" className="st13" width="4.1" height="4.1"/>
                <rect x="1047.8" y="549.8" className="st12" width="4.1" height="4.1"/>
                <rect x="1039.7" y="549.8" className="st23" width="4.1" height="4.1"/>
                <rect x="1031.5" y="549.8" className="st20" width="4.1" height="4.1"/>
                <rect x="1023.3" y="549.8" className="st24" width="4.1" height="4.1"/>
                <rect x="1064.2" y="553.9" className="st22" width="4.1" height="4.1"/>
                <rect x="1064.2" y="562.1" className="st25" width="4.1" height="4.1"/>
                <rect x="1064.2" y="570.4" className="st26" width="4.1" height="4.1"/>
                <rect x="1068.3" y="562.1" className="st27" width="4.1" height="4.1"/>
                <rect x="1068.3" y="553.9" className="st28" width="4.1" height="4.1"/>
                <rect x="1056" y="562.1" className="st29" width="4.1" height="4.1"/>
                <rect x="1047.8" y="562.1" className="st30" width="4.1" height="4.1"/>
                <rect x="1056" y="553.9" className="st31" width="4.1" height="4.1"/>
                <rect x="1039.7" y="562.1" className="st32" width="4.1" height="4.1"/>
                <rect x="1056" y="570.4" className="st26" width="4.1" height="4.1"/>
                <rect x="1031.5" y="562.1" className="st32" width="4.1" height="4.1"/>
                <rect x="1047.8" y="553.9" className="st31" width="4.1" height="4.1"/>
                <rect x="1023.3" y="562.1" className="st32" width="4.1" height="4.1"/>
                <rect x="1047.8" y="570.4" className="st33" width="4.1" height="4.1"/>
                <rect x="1064.2" y="566.2" className="st34" width="4.1" height="4.1"/>
                <rect x="1039.7" y="553.9" className="st35" width="4.1" height="4.1"/>
                <rect x="1068.3" y="566.2" className="st36" width="4.1" height="4.1"/>
                <rect x="1039.7" y="570.4" className="st37" width="4.1" height="4.1"/>
                <rect x="1056" y="566.2" className="st38" width="4.1" height="4.1"/>
                <rect x="1031.5" y="553.9" className="st20" width="4.1" height="4.1"/>
                <rect x="1047.8" y="566.2" className="st39" width="4.1" height="4.1"/>
                <rect x="1031.5" y="570.4" className="st40" width="4.1" height="4.1"/>
                <rect x="1039.7" y="566.2" className="st37" width="4.1" height="4.1"/>
                <rect x="1023.3" y="553.9" className="st24" width="4.1" height="4.1"/>
                <rect x="1031.5" y="566.2" className="st40" width="4.1" height="4.1"/>
                <rect x="1023.3" y="570.4" className="st41" width="4.1" height="4.1"/>
                <rect x="1023.3" y="566.2" className="st42" width="4.1" height="4.1"/>
                <rect x="1064.2" y="558" className="st22" width="4.1" height="4.1"/>
                <rect x="1064.2" y="574.5" className="st26" width="4.1" height="4.1"/>
                <rect x="1068.3" y="558" className="st27" width="4.1" height="4.1"/>
                <rect x="1056" y="558" className="st43" width="4.1" height="4.1"/>
                <rect x="1056" y="574.5" className="st40" width="4.1" height="4.1"/>
                <rect x="1056" y="578.6" className="st44" width="4.1" height="4.1"/>
                <rect x="1056" y="582.8" className="st44" width="4.1" height="4.1"/>
                <rect x="1047.8" y="558" className="st31" width="4.1" height="4.1"/>
                <rect x="1047.8" y="578.6" className="st40" width="4.1" height="4.1"/>
                <rect x="1047.8" y="582.8" className="st40" width="4.1" height="4.1"/>
                <rect x="1047.8" y="574.5" className="st40" width="4.1" height="4.1"/>
                <rect x="1047.8" y="586.9" className="st45" width="4.1" height="4.1"/>
                <rect x="1039.7" y="578.6" className="st40" width="4.1" height="4.1"/>
                <rect x="1039.7" y="558" className="st17" width="4.1" height="4.1"/>
                <rect x="1043.8" y="578.6" className="st40" width="4.1" height="4.1"/>
                <rect x="1039.7" y="582.8" className="st40" width="4.1" height="4.1"/>
                <rect x="1039.7" y="574.5" className="st37" width="4.1" height="4.1"/>
                <rect x="1035.6" y="578.6" className="st40" width="4.1" height="4.1"/>
                <rect x="1043.8" y="582.8" className="st40" width="4.1" height="4.1"/>
                <rect x="1039.7" y="586.9" className="st46" width="4.1" height="4.1"/>
                <rect x="1043.8" y="574.5" className="st40" width="4.1" height="4.1"/>
                <rect x="1035.6" y="582.8" className="st40" width="4.1" height="4.1"/>
                <rect x="1031.5" y="578.6" className="st47" width="4.1" height="4.1"/>
                <rect x="1043.8" y="586.9" className="st46" width="4.1" height="4.1"/>
                <rect x="1031.5" y="582.8" className="st47" width="4.1" height="4.1"/>
                <rect x="1035.6" y="574.5" className="st40" width="4.1" height="4.1"/>
                <rect x="1023.3" y="578.6" className="st48" width="4.1" height="4.1"/>
                <rect x="1023.3" y="582.8" className="st24" width="4.1" height="4.1"/>
                <rect x="1035.6" y="586.9" className="st46" width="4.1" height="4.1"/>
                <rect x="1031.5" y="558" className="st20" width="4.1" height="4.1"/>
                <rect x="1031.5" y="574.5" className="st26" width="4.1" height="4.1"/>
                <rect x="1031.5" y="586.9" className="st24" width="4.1" height="4.1"/>
                <rect x="1023.3" y="558" className="st24" width="4.1" height="4.1"/>
                <rect x="1023.3" y="574.5" className="st24" width="4.1" height="4.1"/>
                <rect x="1023.3" y="586.9" className="st49" width="4.1" height="4.1"/>
                <rect x="1060.1" y="529.1" className="st50" width="4.1" height="4.1"/>
                <rect x="1051.9" y="529.1" className="st51" width="4.1" height="4.1"/>
                <rect x="1043.8" y="529.1" className="st52" width="4.1" height="4.1"/>
                <rect x="1043.8" y="525" className="st53" width="4.1" height="4.1"/>
                <rect x="1035.6" y="529.1" className="st54" width="4.1" height="4.1"/>
                <rect x="1035.6" y="525" className="st55" width="4.1" height="4.1"/>
                <rect x="1027.4" y="529.1" className="st56" width="4.1" height="4.1"/>
                <rect x="1027.4" y="525" className="st57" width="4.1" height="4.1"/>
                <rect x="1019.3" y="529.1" className="st58" width="4.1" height="4.1"/>
                <rect x="1015.2" y="545.6" className="st17" width="4.1" height="4.1"/>
                <rect x="1060.1" y="533.2" className="st59" width="4.1" height="4.1"/>
                <rect x="1011.1" y="545.6" className="st17" width="4.1" height="4.1"/>
                <rect x="1007" y="545.6" className="st60" width="4.1" height="4.1"/>
                <rect x="1051.9" y="533.2" className="st61" width="4.1" height="4.1"/>
                <rect x="1043.8" y="533.2" className="st23" width="4.1" height="4.1"/>
                <rect x="1035.6" y="533.2" className="st11" width="4.1" height="4.1"/>
                <rect x="1027.4" y="533.2" className="st12" width="4.1" height="4.1"/>
                <rect x="1019.3" y="533.2" className="st12" width="4.1" height="4.1"/>
                <rect x="1060.1" y="545.6" className="st13" width="4.1" height="4.1"/>
                <rect x="1051.9" y="545.6" className="st15" width="4.1" height="4.1"/>
                <rect x="1043.8" y="545.6" className="st23" width="4.1" height="4.1"/>
                <rect x="1035.6" y="545.6" className="st12" width="4.1" height="4.1"/>
                <rect x="1027.4" y="545.6" className="st17" width="4.1" height="4.1"/>
                <rect x="1019.3" y="545.6" className="st24" width="4.1" height="4.1"/>
                <rect x="1015.2" y="533.2" className="st62" width="4.1" height="4.1"/>
                <rect x="1060.1" y="537.4" className="st15" width="4.1" height="4.1"/>
                <rect x="1015.2" y="562.1" className="st24" width="4.1" height="4.1"/>
                <rect x="1015.2" y="549.8" className="st24" width="4.1" height="4.1"/>
                <rect x="1051.9" y="537.4" className="st15" width="4.1" height="4.1"/>
                <rect x="1011.1" y="562.1" className="st24" width="4.1" height="4.1"/>
                <rect x="1011.1" y="549.8" className="st17" width="4.1" height="4.1"/>
                <rect x="1007" y="562.1" className="st63" width="4.1" height="4.1"/>
                <rect x="1007" y="549.8" className="st60" width="4.1" height="4.1"/>
                <rect x="1060.1" y="549.8" className="st22" width="4.1" height="4.1"/>
                <rect x="1051.9" y="549.8" className="st15" width="4.1" height="4.1"/>
                <rect x="1043.8" y="537.4" className="st11" width="4.1" height="4.1"/>
                <rect x="1035.6" y="537.4" className="st23" width="4.1" height="4.1"/>
                <rect x="1027.4" y="549.8" className="st17" width="4.1" height="4.1"/>
                <rect x="1060.1" y="562.1" className="st64" width="4.1" height="4.1"/>
                <rect x="1019.3" y="549.8" className="st24" width="4.1" height="4.1"/>
                <rect x="1051.9" y="562.1" className="st30" width="4.1" height="4.1"/>
                <rect x="1027.4" y="537.4" className="st12" width="4.1" height="4.1"/>
                <rect x="1043.8" y="562.1" className="st37" width="4.1" height="4.1"/>
                <rect x="1019.3" y="537.4" className="st17" width="4.1" height="4.1"/>
                <rect x="1035.6" y="562.1" className="st32" width="4.1" height="4.1"/>
                <rect x="1015.2" y="537.4" className="st65" width="4.1" height="4.1"/>
                <rect x="1027.4" y="562.1" className="st32" width="4.1" height="4.1"/>
                <rect x="1015.2" y="553.9" className="st24" width="4.1" height="4.1"/>
                <rect x="1019.3" y="562.1" className="st24" width="4.1" height="4.1"/>
                <rect x="1015.2" y="570.4" className="st66" width="4.1" height="4.1"/>
                <rect x="1011.1" y="537.4" className="st67" width="4.1" height="4.1"/>
                <rect x="1011.1" y="553.9" className="st17" width="4.1" height="4.1"/>
                <rect x="1015.2" y="566.2" className="st68" width="4.1" height="4.1"/>
                <rect x="1011.1" y="570.4" className="st69" width="4.1" height="4.1"/>
                <rect x="1007" y="553.9" className="st70" width="4.1" height="4.1"/>
                <rect x="1011.1" y="566.2" className="st71" width="4.1" height="4.1"/>
                <rect x="1007" y="566.2" className="st63" width="4.1" height="4.1"/>
                <rect x="1060.1" y="541.5" className="st13" width="4.1" height="4.1"/>
                <rect x="1060.1" y="566.2" className="st34" width="4.1" height="4.1"/>
                <rect x="1051.9" y="541.5" className="st72" width="4.1" height="4.1"/>
                <rect x="1051.9" y="566.2" className="st30" width="4.1" height="4.1"/>
                <rect x="1043.8" y="541.5" className="st11" width="4.1" height="4.1"/>
                <rect x="1035.6" y="541.5" className="st12" width="4.1" height="4.1"/>
                <rect x="1027.4" y="541.5" className="st12" width="4.1" height="4.1"/>
                <rect x="1027.4" y="566.2" className="st40" width="4.1" height="4.1"/>
                <rect x="1060.1" y="553.9" className="st22" width="4.1" height="4.1"/>
                <rect x="1019.3" y="566.2" className="st73" width="4.1" height="4.1"/>
                <rect x="1060.1" y="570.4" className="st26" width="4.1" height="4.1"/>
                <rect x="1051.9" y="553.9" className="st31" width="4.1" height="4.1"/>
                <rect x="1051.9" y="570.4" className="st26" width="4.1" height="4.1"/>
                <rect x="1043.8" y="553.9" className="st12" width="4.1" height="4.1"/>
                <rect x="1043.8" y="570.4" className="st74" width="4.1" height="4.1"/>
                <rect x="1043.8" y="566.2" className="st37" width="4.1" height="4.1"/>
                <rect x="1043.8" y="558" className="st17" width="4.1" height="4.1"/>
                <rect x="1035.6" y="558" className="st32" width="4.1" height="4.1"/>
                <rect x="1035.6" y="566.2" className="st37" width="4.1" height="4.1"/>
                <rect x="1035.6" y="553.9" className="st20" width="4.1" height="4.1"/>
                <rect x="1035.6" y="570.4" className="st40" width="4.1" height="4.1"/>
                <rect x="1027.4" y="553.9" className="st17" width="4.1" height="4.1"/>
                <rect x="1015.2" y="578.6" className="st66" width="4.1" height="4.1"/>
                <rect x="1027.4" y="570.4" className="st26" width="4.1" height="4.1"/>
                <rect x="1015.2" y="582.8" className="st24" width="4.1" height="4.1"/>
                <rect x="1019.3" y="553.9" className="st24" width="4.1" height="4.1"/>
                <rect x="1019.3" y="570.4" className="st75" width="4.1" height="4.1"/>
                <rect x="1011.1" y="578.6" className="st24" width="4.1" height="4.1"/>
                <rect x="1019.3" y="541.5" className="st17" width="4.1" height="4.1"/>
                <rect x="1015.2" y="541.5" className="st17" width="4.1" height="4.1"/>
                <rect x="1015.2" y="558" className="st24" width="4.1" height="4.1"/>
                <rect x="1015.2" y="574.5" className="st76" width="4.1" height="4.1"/>
                <rect x="1011.1" y="541.5" className="st77" width="4.1" height="4.1"/>
                <rect x="1051.9" y="578.6" className="st44" width="4.1" height="4.1"/>
                <rect x="1011.1" y="558" className="st24" width="4.1" height="4.1"/>
                <rect x="1011.1" y="574.5" className="st78" width="4.1" height="4.1"/>
                <rect x="1051.9" y="582.8" className="st44" width="4.1" height="4.1"/>
                <rect x="1007" y="558" className="st79" width="4.1" height="4.1"/>
                <rect x="1027.4" y="578.6" className="st24" width="4.1" height="4.1"/>
                <rect x="1060.1" y="558" className="st21" width="4.1" height="4.1"/>
                <rect x="1027.4" y="582.8" className="st47" width="4.1" height="4.1"/>
                <rect x="1060.1" y="574.5" className="st40" width="4.1" height="4.1"/>
                <rect x="1019.3" y="578.6" className="st75" width="4.1" height="4.1"/>
                <rect x="1019.3" y="582.8" className="st24" width="4.1" height="4.1"/>
                <rect x="1060.1" y="578.6" className="st44" width="4.1" height="4.1"/>
                <rect x="1051.9" y="558" className="st31" width="4.1" height="4.1"/>
                <rect x="1051.9" y="574.5" className="st40" width="4.1" height="4.1"/>
                <rect x="1043.8" y="549.8" className="st80" width="4.1" height="4.1"/>
                <rect x="1035.6" y="549.8" className="st12" width="4.1" height="4.1"/>
                <rect x="1027.4" y="558" className="st24" width="4.1" height="4.1"/>
                <rect x="1027.4" y="574.5" className="st26" width="4.1" height="4.1"/>
                <rect x="1027.4" y="586.9" className="st81" width="4.1" height="4.1"/>
                <rect x="1019.3" y="558" className="st24" width="4.1" height="4.1"/>
                <rect x="1019.3" y="574.5" className="st82" width="4.1" height="4.1"/>
                <rect x="142.5" y="549.6" className="st1" width="3.2" height="3.2"/>
                <rect x="142.5" y="552.8" className="st2" width="3.2" height="3.2"/>
                <rect x="152.1" y="543.2" className="st3" width="3.2" height="3.2"/>
                <rect x="158.4" y="543.2" className="st2" width="3.2" height="3.2"/>
                <rect x="158.4" y="540" className="st4" width="3.2" height="3.2"/>
                <rect x="164.8" y="543.2" className="st5" width="3.2" height="3.2"/>
                <rect x="164.8" y="540" className="st6" width="3.2" height="3.2"/>
                <rect x="171.2" y="543.2" className="st7" width="3.2" height="3.2"/>
                <rect x="171.2" y="540" className="st8" width="3.2" height="3.2"/>
                <rect x="177.6" y="543.2" className="st9" width="3.2" height="3.2"/>
                <rect x="145.7" y="546.4" className="st2" width="3.2" height="3.2"/>
                <rect x="152.1" y="546.4" className="st1" width="3.2" height="3.2"/>
                <rect x="158.4" y="546.4" className="st10" width="3.2" height="3.2"/>
                <rect x="164.8" y="546.4" className="st11" width="3.2" height="3.2"/>
                <rect x="171.2" y="546.4" className="st11" width="3.2" height="3.2"/>
                <rect x="177.6" y="546.4" className="st12" width="3.2" height="3.2"/>
                <rect x="145.7" y="549.6" className="st2" width="3.2" height="3.2"/>
                <rect x="152.1" y="549.6" className="st13" width="3.2" height="3.2"/>
                <rect x="158.4" y="549.6" className="st14" width="3.2" height="3.2"/>
                <rect x="164.8" y="549.6" className="st11" width="3.2" height="3.2"/>
                <rect x="171.2" y="549.6" className="st12" width="3.2" height="3.2"/>
                <rect x="177.6" y="549.6" className="st12" width="3.2" height="3.2"/>
                <rect x="145.7" y="552.8" className="st2" width="3.2" height="3.2"/>
                <rect x="152.1" y="552.8" className="st15" width="3.2" height="3.2"/>
                <rect x="158.4" y="552.8" className="st16" width="3.2" height="3.2"/>
                <rect x="164.8" y="552.8" className="st11" width="3.2" height="3.2"/>
                <rect x="171.2" y="552.8" className="st12" width="3.2" height="3.2"/>
                <rect x="177.6" y="552.8" className="st17" width="3.2" height="3.2"/>
                <rect x="145.7" y="555.9" className="st13" width="3.2" height="3.2"/>
                <rect x="142.5" y="555.9" className="st18" width="3.2" height="3.2"/>
                <rect x="152.1" y="555.9" className="st13" width="3.2" height="3.2"/>
                <rect x="158.4" y="555.9" className="st19" width="3.2" height="3.2"/>
                <rect x="164.8" y="555.9" className="st11" width="3.2" height="3.2"/>
                <rect x="171.2" y="555.9" className="st20" width="3.2" height="3.2"/>
                <rect x="177.6" y="555.9" className="st17" width="3.2" height="3.2"/>
                <rect x="145.7" y="559.1" className="st21" width="3.2" height="3.2"/>
                <rect x="142.5" y="559.1" className="st22" width="3.2" height="3.2"/>
                <rect x="152.1" y="559.1" className="st13" width="3.2" height="3.2"/>
                <rect x="158.4" y="559.1" className="st12" width="3.2" height="3.2"/>
                <rect x="164.8" y="559.1" className="st23" width="3.2" height="3.2"/>
                <rect x="171.2" y="559.1" className="st20" width="3.2" height="3.2"/>
                <rect x="177.6" y="559.1" className="st24" width="3.2" height="3.2"/>
                <rect x="145.7" y="562.3" className="st22" width="3.2" height="3.2"/>
                <rect x="145.7" y="568.7" className="st25" width="3.2" height="3.2"/>
                <rect x="145.7" y="575.1" className="st26" width="3.2" height="3.2"/>
                <rect x="142.5" y="568.7" className="st27" width="3.2" height="3.2"/>
                <rect x="142.5" y="562.3" className="st28" width="3.2" height="3.2"/>
                <rect x="152.1" y="568.7" className="st29" width="3.2" height="3.2"/>
                <rect x="158.4" y="568.7" className="st30" width="3.2" height="3.2"/>
                <rect x="152.1" y="562.3" className="st31" width="3.2" height="3.2"/>
                <rect x="164.8" y="568.7" className="st32" width="3.2" height="3.2"/>
                <rect x="152.1" y="575.1" className="st26" width="3.2" height="3.2"/>
                <rect x="171.2" y="568.7" className="st32" width="3.2" height="3.2"/>
                <rect x="158.4" y="562.3" className="st31" width="3.2" height="3.2"/>
                <rect x="177.6" y="568.7" className="st32" width="3.2" height="3.2"/>
                <rect x="158.4" y="575.1" className="st33" width="3.2" height="3.2"/>
                <rect x="145.7" y="571.9" className="st34" width="3.2" height="3.2"/>
                <rect x="164.8" y="562.3" className="st35" width="3.2" height="3.2"/>
                <rect x="142.5" y="571.9" className="st36" width="3.2" height="3.2"/>
                <rect x="164.8" y="575.1" className="st37" width="3.2" height="3.2"/>
                <rect x="152.1" y="571.9" className="st38" width="3.2" height="3.2"/>
                <rect x="171.2" y="562.3" className="st20" width="3.2" height="3.2"/>
                <rect x="158.4" y="571.9" className="st39" width="3.2" height="3.2"/>
                <rect x="171.2" y="575.1" className="st40" width="3.2" height="3.2"/>
                <rect x="164.8" y="571.9" className="st37" width="3.2" height="3.2"/>
                <rect x="177.6" y="562.3" className="st24" width="3.2" height="3.2"/>
                <rect x="171.2" y="571.9" className="st40" width="3.2" height="3.2"/>
                <rect x="177.6" y="575.1" className="st41" width="3.2" height="3.2"/>
                <rect x="177.6" y="571.9" className="st42" width="3.2" height="3.2"/>
                <rect x="145.7" y="565.5" className="st22" width="3.2" height="3.2"/>
                <rect x="145.7" y="578.2" className="st26" width="3.2" height="3.2"/>
                <rect x="142.5" y="565.5" className="st27" width="3.2" height="3.2"/>
                <rect x="152.1" y="565.5" className="st43" width="3.2" height="3.2"/>
                <rect x="152.1" y="578.2" className="st40" width="3.2" height="3.2"/>
                <rect x="152.1" y="581.4" className="st44" width="3.2" height="3.2"/>
                <rect x="152.1" y="584.6" className="st44" width="3.2" height="3.2"/>
                <rect x="158.4" y="565.5" className="st31" width="3.2" height="3.2"/>
                <rect x="158.4" y="581.4" className="st40" width="3.2" height="3.2"/>
                <rect x="158.4" y="584.6" className="st40" width="3.2" height="3.2"/>
                <rect x="158.4" y="578.2" className="st40" width="3.2" height="3.2"/>
                <rect x="158.4" y="587.8" className="st45" width="3.2" height="3.2"/>
                <rect x="164.8" y="581.4" className="st40" width="3.2" height="3.2"/>
                <rect x="164.8" y="565.5" className="st17" width="3.2" height="3.2"/>
                <rect x="161.6" y="581.4" className="st40" width="3.2" height="3.2"/>
                <rect x="164.8" y="584.6" className="st40" width="3.2" height="3.2"/>
                <rect x="164.8" y="578.2" className="st37" width="3.2" height="3.2"/>
                <rect x="168" y="581.4" className="st40" width="3.2" height="3.2"/>
                <rect x="161.6" y="584.6" className="st40" width="3.2" height="3.2"/>
                <rect x="164.8" y="587.8" className="st46" width="3.2" height="3.2"/>
                <rect x="161.6" y="578.2" className="st40" width="3.2" height="3.2"/>
                <rect x="168" y="584.6" className="st40" width="3.2" height="3.2"/>
                <rect x="171.2" y="581.4" className="st47" width="3.2" height="3.2"/>
                <rect x="161.6" y="587.8" className="st46" width="3.2" height="3.2"/>
                <rect x="171.2" y="584.6" className="st47" width="3.2" height="3.2"/>
                <rect x="168" y="578.2" className="st40" width="3.2" height="3.2"/>
                <rect x="177.6" y="581.4" className="st48" width="3.2" height="3.2"/>
                <rect x="177.6" y="584.6" className="st24" width="3.2" height="3.2"/>
                <rect x="168" y="587.8" className="st46" width="3.2" height="3.2"/>
                <rect x="171.2" y="565.5" className="st20" width="3.2" height="3.2"/>
                <rect x="171.2" y="578.2" className="st26" width="3.2" height="3.2"/>
                <rect x="171.2" y="587.8" className="st24" width="3.2" height="3.2"/>
                <rect x="177.6" y="565.5" className="st24" width="3.2" height="3.2"/>
                <rect x="177.6" y="578.2" className="st24" width="3.2" height="3.2"/>
                <rect x="177.6" y="587.8" className="st49" width="3.2" height="3.2"/>
                <rect x="148.9" y="543.2" className="st50" width="3.2" height="3.2"/>
                <rect x="155.2" y="543.2" className="st51" width="3.2" height="3.2"/>
                <rect x="161.6" y="543.2" className="st52" width="3.2" height="3.2"/>
                <rect x="161.6" y="540" className="st53" width="3.2" height="3.2"/>
                <rect x="168" y="543.2" className="st54" width="3.2" height="3.2"/>
                <rect x="168" y="540" className="st55" width="3.2" height="3.2"/>
                <rect x="174.4" y="543.2" className="st56" width="3.2" height="3.2"/>
                <rect x="174.4" y="540" className="st57" width="3.2" height="3.2"/>
                <rect x="180.8" y="543.2" className="st58" width="3.2" height="3.2"/>
                <rect x="183.9" y="555.9" className="st17" width="3.2" height="3.2"/>
                <rect x="148.9" y="546.4" className="st59" width="3.2" height="3.2"/>
                <rect x="187.1" y="555.9" className="st17" width="3.2" height="3.2"/>
                <rect x="190.3" y="555.9" className="st60" width="3.2" height="3.2"/>
                <rect x="155.2" y="546.4" className="st61" width="3.2" height="3.2"/>
                <rect x="161.6" y="546.4" className="st23" width="3.2" height="3.2"/>
                <rect x="168" y="546.4" className="st11" width="3.2" height="3.2"/>
                <rect x="174.4" y="546.4" className="st12" width="3.2" height="3.2"/>
                <rect x="180.8" y="546.4" className="st12" width="3.2" height="3.2"/>
                <rect x="148.9" y="555.9" className="st13" width="3.2" height="3.2"/>
                <rect x="155.2" y="555.9" className="st15" width="3.2" height="3.2"/>
                <rect x="161.6" y="555.9" className="st23" width="3.2" height="3.2"/>
                <rect x="168" y="555.9" className="st12" width="3.2" height="3.2"/>
                <rect x="174.4" y="555.9" className="st17" width="3.2" height="3.2"/>
                <rect x="180.8" y="555.9" className="st24" width="3.2" height="3.2"/>
                <rect x="183.9" y="546.4" className="st62" width="3.2" height="3.2"/>
                <rect x="148.9" y="549.6" className="st15" width="3.2" height="3.2"/>
                <rect x="183.9" y="568.7" className="st24" width="3.2" height="3.2"/>
                <rect x="183.9" y="559.1" className="st24" width="3.2" height="3.2"/>
                <rect x="155.2" y="549.6" className="st15" width="3.2" height="3.2"/>
                <rect x="187.1" y="568.7" className="st24" width="3.2" height="3.2"/>
                <rect x="187.1" y="559.1" className="st17" width="3.2" height="3.2"/>
                <rect x="190.3" y="568.7" className="st63" width="3.2" height="3.2"/>
                <rect x="190.3" y="559.1" className="st60" width="3.2" height="3.2"/>
                <rect x="148.9" y="559.1" className="st22" width="3.2" height="3.2"/>
                <rect x="155.2" y="559.1" className="st15" width="3.2" height="3.2"/>
                <rect x="161.6" y="549.6" className="st11" width="3.2" height="3.2"/>
                <rect x="168" y="549.6" className="st23" width="3.2" height="3.2"/>
                <rect x="174.4" y="559.1" className="st17" width="3.2" height="3.2"/>
                <rect x="148.9" y="568.7" className="st64" width="3.2" height="3.2"/>
                <rect x="180.8" y="559.1" className="st24" width="3.2" height="3.2"/>
                <rect x="155.2" y="568.7" className="st30" width="3.2" height="3.2"/>
                <rect x="174.4" y="549.6" className="st12" width="3.2" height="3.2"/>
                <rect x="161.6" y="568.7" className="st37" width="3.2" height="3.2"/>
                <rect x="180.8" y="549.6" className="st17" width="3.2" height="3.2"/>
                <rect x="168" y="568.7" className="st32" width="3.2" height="3.2"/>
                <rect x="183.9" y="549.6" className="st65" width="3.2" height="3.2"/>
                <rect x="174.4" y="568.7" className="st32" width="3.2" height="3.2"/>
                <rect x="183.9" y="562.3" className="st24" width="3.2" height="3.2"/>
                <rect x="180.8" y="568.7" className="st24" width="3.2" height="3.2"/>
                <rect x="183.9" y="575.1" className="st66" width="3.2" height="3.2"/>
                <rect x="187.1" y="549.6" className="st67" width="3.2" height="3.2"/>
                <rect x="187.1" y="562.3" className="st17" width="3.2" height="3.2"/>
                <rect x="183.9" y="571.9" className="st68" width="3.2" height="3.2"/>
                <rect x="187.1" y="575.1" className="st69" width="3.2" height="3.2"/>
                <rect x="190.3" y="562.3" className="st70" width="3.2" height="3.2"/>
                <rect x="187.1" y="571.9" className="st71" width="3.2" height="3.2"/>
                <rect x="190.3" y="571.9" className="st63" width="3.2" height="3.2"/>
                <rect x="148.9" y="552.8" className="st13" width="3.2" height="3.2"/>
                <rect x="148.9" y="571.9" className="st34" width="3.2" height="3.2"/>
                <rect x="155.2" y="552.8" className="st72" width="3.2" height="3.2"/>
                <rect x="155.2" y="571.9" className="st30" width="3.2" height="3.2"/>
                <rect x="161.6" y="552.8" className="st11" width="3.2" height="3.2"/>
                <rect x="168" y="552.8" className="st12" width="3.2" height="3.2"/>
                <rect x="174.4" y="552.8" className="st12" width="3.2" height="3.2"/>
                <rect x="174.4" y="571.9" className="st40" width="3.2" height="3.2"/>
                <rect x="148.9" y="562.3" className="st22" width="3.2" height="3.2"/>
                <rect x="180.8" y="571.9" className="st73" width="3.2" height="3.2"/>
                <rect x="148.9" y="575.1" className="st26" width="3.2" height="3.2"/>
                <rect x="155.2" y="562.3" className="st31" width="3.2" height="3.2"/>
                <rect x="155.2" y="575.1" className="st26" width="3.2" height="3.2"/>
                <rect x="161.6" y="562.3" className="st12" width="3.2" height="3.2"/>
                <rect x="161.6" y="575.1" className="st74" width="3.2" height="3.2"/>
                <rect x="161.6" y="571.9" className="st37" width="3.2" height="3.2"/>
                <rect x="161.6" y="565.5" className="st17" width="3.2" height="3.2"/>
                <rect x="168" y="565.5" className="st32" width="3.2" height="3.2"/>
                <rect x="168" y="571.9" className="st37" width="3.2" height="3.2"/>
                <rect x="168" y="562.3" className="st20" width="3.2" height="3.2"/>
                <rect x="168" y="575.1" className="st40" width="3.2" height="3.2"/>
                <rect x="174.4" y="562.3" className="st17" width="3.2" height="3.2"/>
                <rect x="183.9" y="581.4" className="st66" width="3.2" height="3.2"/>
                <rect x="174.4" y="575.1" className="st26" width="3.2" height="3.2"/>
                <rect x="183.9" y="584.6" className="st24" width="3.2" height="3.2"/>
                <rect x="180.8" y="562.3" className="st24" width="3.2" height="3.2"/>
                <rect x="180.8" y="575.1" className="st75" width="3.2" height="3.2"/>
                <rect x="187.1" y="581.4" className="st24" width="3.2" height="3.2"/>
                <rect x="180.8" y="552.8" className="st17" width="3.2" height="3.2"/>
                <rect x="183.9" y="552.8" className="st17" width="3.2" height="3.2"/>
                <rect x="183.9" y="565.5" className="st24" width="3.2" height="3.2"/>
                <rect x="183.9" y="578.2" className="st76" width="3.2" height="3.2"/>
                <rect x="187.1" y="552.8" className="st77" width="3.2" height="3.2"/>
                <rect x="155.2" y="581.4" className="st44" width="3.2" height="3.2"/>
                <rect x="187.1" y="565.5" className="st24" width="3.2" height="3.2"/>
                <rect x="187.1" y="578.2" className="st78" width="3.2" height="3.2"/>
                <rect x="155.2" y="584.6" className="st44" width="3.2" height="3.2"/>
                <rect x="190.3" y="565.5" className="st79" width="3.2" height="3.2"/>
                <rect x="174.4" y="581.4" className="st24" width="3.2" height="3.2"/>
                <rect x="148.9" y="565.5" className="st21" width="3.2" height="3.2"/>
                <rect x="174.4" y="584.6" className="st47" width="3.2" height="3.2"/>
                <rect x="148.9" y="578.2" className="st40" width="3.2" height="3.2"/>
                <rect x="180.8" y="581.4" className="st75" width="3.2" height="3.2"/>
                <rect x="180.8" y="584.6" className="st24" width="3.2" height="3.2"/>
                <rect x="148.9" y="581.4" className="st44" width="3.2" height="3.2"/>
                <rect x="155.2" y="565.5" className="st31" width="3.2" height="3.2"/>
                <rect x="155.2" y="578.2" className="st40" width="3.2" height="3.2"/>
                <rect x="161.6" y="559.1" className="st80" width="3.2" height="3.2"/>
                <rect x="168" y="559.1" className="st12" width="3.2" height="3.2"/>
                <rect x="174.4" y="565.5" className="st24" width="3.2" height="3.2"/>
                <rect x="174.4" y="578.2" className="st26" width="3.2" height="3.2"/>
                <rect x="174.4" y="587.8" className="st81" width="3.2" height="3.2"/>
                <rect x="180.8" y="565.5" className="st24" width="3.2" height="3.2"/>
                <rect x="180.8" y="578.2" className="st82" width="3.2" height="3.2"/>
                <rect x="1257.8" y="541.6" className="st1" width="3.2" height="3.2"/>
                <rect x="1257.8" y="544.8" className="st2" width="3.2" height="3.2"/>
                <rect x="1248.4" y="535.2" className="st3" width="3.2" height="3.2"/>
                <rect x="1242.1" y="535.2" className="st2" width="3.2" height="3.2"/>
                <rect x="1242.1" y="532" className="st4" width="3.2" height="3.2"/>
                <rect x="1235.8" y="535.2" className="st5" width="3.2" height="3.2"/>
                <rect x="1235.8" y="532" className="st6" width="3.2" height="3.2"/>
                <rect x="1229.4" y="535.2" className="st7" width="3.2" height="3.2"/>
                <rect x="1229.4" y="532" className="st8" width="3.2" height="3.2"/>
                <rect x="1223.1" y="535.2" className="st9" width="3.2" height="3.2"/>
                <rect x="1254.7" y="538.4" className="st2" width="3.2" height="3.2"/>
                <rect x="1248.4" y="538.4" className="st1" width="3.2" height="3.2"/>
                <rect x="1242.1" y="538.4" className="st10" width="3.2" height="3.2"/>
                <rect x="1235.8" y="538.4" className="st11" width="3.2" height="3.2"/>
                <rect x="1229.4" y="538.4" className="st11" width="3.2" height="3.2"/>
                <rect x="1223.1" y="538.4" className="st12" width="3.2" height="3.2"/>
                <rect x="1254.7" y="541.6" className="st2" width="3.2" height="3.2"/>
                <rect x="1248.4" y="541.6" className="st13" width="3.2" height="3.2"/>
                <rect x="1242.1" y="541.6" className="st14" width="3.2" height="3.2"/>
                <rect x="1235.8" y="541.6" className="st11" width="3.2" height="3.2"/>
                <rect x="1229.4" y="541.6" className="st12" width="3.2" height="3.2"/>
                <rect x="1223.1" y="541.6" className="st12" width="3.2" height="3.2"/>
                <rect x="1254.7" y="544.8" className="st2" width="3.2" height="3.2"/>
                <rect x="1248.4" y="544.8" className="st15" width="3.2" height="3.2"/>
                <rect x="1242.1" y="544.8" className="st16" width="3.2" height="3.2"/>
                <rect x="1235.8" y="544.8" className="st11" width="3.2" height="3.2"/>
                <rect x="1229.4" y="544.8" className="st12" width="3.2" height="3.2"/>
                <rect x="1223.1" y="544.8" className="st17" width="3.2" height="3.2"/>
                <rect x="1254.7" y="547.9" className="st13" width="3.2" height="3.2"/>
                <rect x="1257.8" y="547.9" className="st18" width="3.2" height="3.2"/>
                <rect x="1248.4" y="547.9" className="st13" width="3.2" height="3.2"/>
                <rect x="1242.1" y="547.9" className="st19" width="3.2" height="3.2"/>
                <rect x="1235.8" y="547.9" className="st11" width="3.2" height="3.2"/>
                <rect x="1229.4" y="547.9" className="st20" width="3.2" height="3.2"/>
                <rect x="1223.1" y="547.9" className="st17" width="3.2" height="3.2"/>
                <rect x="1254.7" y="551.1" className="st21" width="3.2" height="3.2"/>
                <rect x="1257.8" y="551.1" className="st22" width="3.2" height="3.2"/>
                <rect x="1248.4" y="551.1" className="st13" width="3.2" height="3.2"/>
                <rect x="1242.1" y="551.1" className="st12" width="3.2" height="3.2"/>
                <rect x="1235.8" y="551.1" className="st23" width="3.2" height="3.2"/>
                <rect x="1229.4" y="551.1" className="st20" width="3.2" height="3.2"/>
                <rect x="1223.1" y="551.1" className="st24" width="3.2" height="3.2"/>
                <rect x="1254.7" y="554.3" className="st22" width="3.2" height="3.2"/>
                <rect x="1254.7" y="560.7" className="st25" width="3.2" height="3.2"/>
                <rect x="1254.7" y="567.1" className="st26" width="3.2" height="3.2"/>
                <rect x="1257.8" y="560.7" className="st27" width="3.2" height="3.2"/>
                <rect x="1257.8" y="554.3" className="st28" width="3.2" height="3.2"/>
                <rect x="1248.4" y="560.7" className="st29" width="3.2" height="3.2"/>
                <rect x="1242.1" y="560.7" className="st30" width="3.2" height="3.2"/>
                <rect x="1248.4" y="554.3" className="st31" width="3.2" height="3.2"/>
                <rect x="1235.8" y="560.7" className="st32" width="3.2" height="3.2"/>
                <rect x="1248.4" y="567.1" className="st26" width="3.2" height="3.2"/>
                <rect x="1229.4" y="560.7" className="st32" width="3.2" height="3.2"/>
                <rect x="1242.1" y="554.3" className="st31" width="3.2" height="3.2"/>
                <rect x="1223.1" y="560.7" className="st32" width="3.2" height="3.2"/>
                <rect x="1242.1" y="567.1" className="st33" width="3.2" height="3.2"/>
                <rect x="1254.7" y="563.9" className="st34" width="3.2" height="3.2"/>
                <rect x="1235.8" y="554.3" className="st35" width="3.2" height="3.2"/>
                <rect x="1257.8" y="563.9" className="st36" width="3.2" height="3.2"/>
                <rect x="1235.8" y="567.1" className="st37" width="3.2" height="3.2"/>
                <rect x="1248.4" y="563.9" className="st38" width="3.2" height="3.2"/>
                <rect x="1229.4" y="554.3" className="st20" width="3.2" height="3.2"/>
                <rect x="1242.1" y="563.9" className="st39" width="3.2" height="3.2"/>
                <rect x="1229.4" y="567.1" className="st40" width="3.2" height="3.2"/>
                <rect x="1235.8" y="563.9" className="st37" width="3.2" height="3.2"/>
                <rect x="1223.1" y="554.3" className="st24" width="3.2" height="3.2"/>
                <rect x="1229.4" y="563.9" className="st40" width="3.2" height="3.2"/>
                <rect x="1223.1" y="567.1" className="st41" width="3.2" height="3.2"/>
                <rect x="1223.1" y="563.9" className="st42" width="3.2" height="3.2"/>
                <rect x="1254.7" y="557.5" className="st22" width="3.2" height="3.2"/>
                <rect x="1254.7" y="570.2" className="st26" width="3.2" height="3.2"/>
                <rect x="1257.8" y="557.5" className="st27" width="3.2" height="3.2"/>
                <rect x="1248.4" y="557.5" className="st43" width="3.2" height="3.2"/>
                <rect x="1248.4" y="570.2" className="st40" width="3.2" height="3.2"/>
                <rect x="1248.4" y="573.4" className="st44" width="3.2" height="3.2"/>
                <rect x="1248.4" y="576.6" className="st44" width="3.2" height="3.2"/>
                <rect x="1242.1" y="557.5" className="st31" width="3.2" height="3.2"/>
                <rect x="1242.1" y="573.4" className="st40" width="3.2" height="3.2"/>
                <rect x="1242.1" y="576.6" className="st40" width="3.2" height="3.2"/>
                <rect x="1242.1" y="570.2" className="st40" width="3.2" height="3.2"/>
                <rect x="1242.1" y="579.8" className="st45" width="3.2" height="3.2"/>
                <rect x="1235.8" y="573.4" className="st40" width="3.2" height="3.2"/>
                <rect x="1235.8" y="557.5" className="st17" width="3.2" height="3.2"/>
                <rect x="1238.9" y="573.4" className="st40" width="3.2" height="3.2"/>
                <rect x="1235.8" y="576.6" className="st40" width="3.2" height="3.2"/>
                <rect x="1235.8" y="570.2" className="st37" width="3.2" height="3.2"/>
                <rect x="1232.6" y="573.4" className="st40" width="3.2" height="3.2"/>
                <rect x="1238.9" y="576.6" className="st40" width="3.2" height="3.2"/>
                <rect x="1235.8" y="579.8" className="st46" width="3.2" height="3.2"/>
                <rect x="1238.9" y="570.2" className="st40" width="3.2" height="3.2"/>
                <rect x="1232.6" y="576.6" className="st40" width="3.2" height="3.2"/>
                <rect x="1229.4" y="573.4" className="st47" width="3.2" height="3.2"/>
                <rect x="1238.9" y="579.8" className="st46" width="3.2" height="3.2"/>
                <rect x="1229.4" y="576.6" className="st47" width="3.2" height="3.2"/>
                <rect x="1232.6" y="570.2" className="st40" width="3.2" height="3.2"/>
                <rect x="1223.1" y="573.4" className="st48" width="3.2" height="3.2"/>
                <rect x="1223.1" y="576.6" className="st24" width="3.2" height="3.2"/>
                <rect x="1232.6" y="579.8" className="st46" width="3.2" height="3.2"/>
                <rect x="1229.4" y="557.5" className="st20" width="3.2" height="3.2"/>
                <rect x="1229.4" y="570.2" className="st26" width="3.2" height="3.2"/>
                <rect x="1229.4" y="579.8" className="st24" width="3.2" height="3.2"/>
                <rect x="1223.1" y="557.5" className="st24" width="3.2" height="3.2"/>
                <rect x="1223.1" y="570.2" className="st24" width="3.2" height="3.2"/>
                <rect x="1223.1" y="579.8" className="st49" width="3.2" height="3.2"/>
                <rect x="1251.5" y="535.2" className="st50" width="3.2" height="3.2"/>
                <rect x="1245.2" y="535.2" className="st51" width="3.2" height="3.2"/>
                <rect x="1238.9" y="535.2" className="st52" width="3.2" height="3.2"/>
                <rect x="1238.9" y="532" className="st53" width="3.2" height="3.2"/>
                <rect x="1232.6" y="535.2" className="st54" width="3.2" height="3.2"/>
                <rect x="1232.6" y="532" className="st55" width="3.2" height="3.2"/>
                <rect x="1226.3" y="535.2" className="st56" width="3.2" height="3.2"/>
                <rect x="1226.3" y="532" className="st57" width="3.2" height="3.2"/>
                <rect x="1220" y="535.2" className="st58" width="3.2" height="3.2"/>
                <rect x="1216.8" y="547.9" className="st17" width="3.2" height="3.2"/>
                <rect x="1251.5" y="538.4" className="st59" width="3.2" height="3.2"/>
                <rect x="1213.7" y="547.9" className="st17" width="3.2" height="3.2"/>
                <rect x="1210.5" y="547.9" className="st60" width="3.2" height="3.2"/>
                <rect x="1245.2" y="538.4" className="st61" width="3.2" height="3.2"/>
                <rect x="1238.9" y="538.4" className="st23" width="3.2" height="3.2"/>
                <rect x="1232.6" y="538.4" className="st11" width="3.2" height="3.2"/>
                <rect x="1226.3" y="538.4" className="st12" width="3.2" height="3.2"/>
                <rect x="1220" y="538.4" className="st12" width="3.2" height="3.2"/>
                <rect x="1251.5" y="547.9" className="st13" width="3.2" height="3.2"/>
                <rect x="1245.2" y="547.9" className="st15" width="3.2" height="3.2"/>
                <rect x="1238.9" y="547.9" className="st23" width="3.2" height="3.2"/>
                <rect x="1232.6" y="547.9" className="st12" width="3.2" height="3.2"/>
                <rect x="1226.3" y="547.9" className="st17" width="3.2" height="3.2"/>
                <rect x="1220" y="547.9" className="st24" width="3.2" height="3.2"/>
                <rect x="1216.8" y="538.4" className="st62" width="3.2" height="3.2"/>
                <rect x="1251.5" y="541.6" className="st15" width="3.2" height="3.2"/>
                <rect x="1216.8" y="560.7" className="st24" width="3.2" height="3.2"/>
                <rect x="1216.8" y="551.1" className="st24" width="3.2" height="3.2"/>
                <rect x="1245.2" y="541.6" className="st15" width="3.2" height="3.2"/>
                <rect x="1213.7" y="560.7" className="st24" width="3.2" height="3.2"/>
                <rect x="1213.7" y="551.1" className="st17" width="3.2" height="3.2"/>
                <rect x="1210.5" y="560.7" className="st63" width="3.2" height="3.2"/>
                <rect x="1210.5" y="551.1" className="st60" width="3.2" height="3.2"/>
                <rect x="1251.5" y="551.1" className="st22" width="3.2" height="3.2"/>
                <rect x="1245.2" y="551.1" className="st15" width="3.2" height="3.2"/>
                <rect x="1238.9" y="541.6" className="st11" width="3.2" height="3.2"/>
                <rect x="1232.6" y="541.6" className="st23" width="3.2" height="3.2"/>
                <rect x="1226.3" y="551.1" className="st17" width="3.2" height="3.2"/>
                <rect x="1251.5" y="560.7" className="st64" width="3.2" height="3.2"/>
                <rect x="1220" y="551.1" className="st24" width="3.2" height="3.2"/>
                <rect x="1245.2" y="560.7" className="st30" width="3.2" height="3.2"/>
                <rect x="1226.3" y="541.6" className="st12" width="3.2" height="3.2"/>
                <rect x="1238.9" y="560.7" className="st37" width="3.2" height="3.2"/>
                <rect x="1220" y="541.6" className="st17" width="3.2" height="3.2"/>
                <rect x="1232.6" y="560.7" className="st32" width="3.2" height="3.2"/>
                <rect x="1216.8" y="541.6" className="st65" width="3.2" height="3.2"/>
                <rect x="1226.3" y="560.7" className="st32" width="3.2" height="3.2"/>
                <rect x="1216.8" y="554.3" className="st24" width="3.2" height="3.2"/>
                <rect x="1220" y="560.7" className="st24" width="3.2" height="3.2"/>
                <rect x="1216.8" y="567.1" className="st66" width="3.2" height="3.2"/>
                <rect x="1213.7" y="541.6" className="st67" width="3.2" height="3.2"/>
                <rect x="1213.7" y="554.3" className="st17" width="3.2" height="3.2"/>
                <rect x="1216.8" y="563.9" className="st68" width="3.2" height="3.2"/>
                <rect x="1213.7" y="567.1" className="st69" width="3.2" height="3.2"/>
                <rect x="1210.5" y="554.3" className="st70" width="3.2" height="3.2"/>
                <rect x="1213.7" y="563.9" className="st71" width="3.2" height="3.2"/>
                <rect x="1210.5" y="563.9" className="st63" width="3.2" height="3.2"/>
                <rect x="1251.5" y="544.8" className="st13" width="3.2" height="3.2"/>
                <rect x="1251.5" y="563.9" className="st34" width="3.2" height="3.2"/>
                <rect x="1245.2" y="544.8" className="st72" width="3.2" height="3.2"/>
                <rect x="1245.2" y="563.9" className="st30" width="3.2" height="3.2"/>
                <rect x="1238.9" y="544.8" className="st11" width="3.2" height="3.2"/>
                <rect x="1232.6" y="544.8" className="st12" width="3.2" height="3.2"/>
                <rect x="1226.3" y="544.8" className="st12" width="3.2" height="3.2"/>
                <rect x="1226.3" y="563.9" className="st40" width="3.2" height="3.2"/>
                <rect x="1251.5" y="554.3" className="st22" width="3.2" height="3.2"/>
                <rect x="1220" y="563.9" className="st73" width="3.2" height="3.2"/>
                <rect x="1251.5" y="567.1" className="st26" width="3.2" height="3.2"/>
                <rect x="1245.2" y="554.3" className="st31" width="3.2" height="3.2"/>
                <rect x="1245.2" y="567.1" className="st26" width="3.2" height="3.2"/>
                <rect x="1238.9" y="554.3" className="st12" width="3.2" height="3.2"/>
                <rect x="1238.9" y="567.1" className="st74" width="3.2" height="3.2"/>
                <rect x="1238.9" y="563.9" className="st37" width="3.2" height="3.2"/>
                <rect x="1238.9" y="557.5" className="st17" width="3.2" height="3.2"/>
                <rect x="1232.6" y="557.5" className="st32" width="3.2" height="3.2"/>
                <rect x="1232.6" y="563.9" className="st37" width="3.2" height="3.2"/>
                <rect x="1232.6" y="554.3" className="st20" width="3.2" height="3.2"/>
                <rect x="1232.6" y="567.1" className="st40" width="3.2" height="3.2"/>
                <rect x="1226.3" y="554.3" className="st17" width="3.2" height="3.2"/>
                <rect x="1216.8" y="573.4" className="st66" width="3.2" height="3.2"/>
                <rect x="1226.3" y="567.1" className="st26" width="3.2" height="3.2"/>
                <rect x="1216.8" y="576.6" className="st24" width="3.2" height="3.2"/>
                <rect x="1220" y="554.3" className="st24" width="3.2" height="3.2"/>
                <rect x="1220" y="567.1" className="st75" width="3.2" height="3.2"/>
                <rect x="1213.7" y="573.4" className="st24" width="3.2" height="3.2"/>
                <rect x="1220" y="544.8" className="st17" width="3.2" height="3.2"/>
                <rect x="1216.8" y="544.8" className="st17" width="3.2" height="3.2"/>
                <rect x="1216.8" y="557.5" className="st24" width="3.2" height="3.2"/>
                <rect x="1216.8" y="570.2" className="st76" width="3.2" height="3.2"/>
                <rect x="1213.7" y="544.8" className="st77" width="3.2" height="3.2"/>
                <rect x="1245.2" y="573.4" className="st44" width="3.2" height="3.2"/>
                <rect x="1213.7" y="557.5" className="st24" width="3.2" height="3.2"/>
                <rect x="1213.7" y="570.2" className="st78" width="3.2" height="3.2"/>
                <rect x="1245.2" y="576.6" className="st44" width="3.2" height="3.2"/>
                <rect x="1210.5" y="557.5" className="st79" width="3.2" height="3.2"/>
                <rect x="1226.3" y="573.4" className="st24" width="3.2" height="3.2"/>
                <rect x="1251.5" y="557.5" className="st21" width="3.2" height="3.2"/>
                <rect x="1226.3" y="576.6" className="st47" width="3.2" height="3.2"/>
                <rect x="1251.5" y="570.2" className="st40" width="3.2" height="3.2"/>
                <rect x="1220" y="573.4" className="st75" width="3.2" height="3.2"/>
                <rect x="1220" y="576.6" className="st24" width="3.2" height="3.2"/>
                <rect x="1251.5" y="573.4" className="st44" width="3.2" height="3.2"/>
                <rect x="1245.2" y="557.5" className="st31" width="3.2" height="3.2"/>
                <rect x="1245.2" y="570.2" className="st40" width="3.2" height="3.2"/>
                <rect x="1238.9" y="551.1" className="st80" width="3.2" height="3.2"/>
                <rect x="1232.6" y="551.1" className="st12" width="3.2" height="3.2"/>
                <rect x="1226.3" y="557.5" className="st24" width="3.2" height="3.2"/>
                <rect x="1226.3" y="570.2" className="st26" width="3.2" height="3.2"/>
                <rect x="1226.3" y="579.8" className="st81" width="3.2" height="3.2"/>
                <rect x="1220" y="557.5" className="st24" width="3.2" height="3.2"/>
                <rect x="1220" y="570.2" className="st82" width="3.2" height="3.2"/>
                </g>
                <polygon id="lava-light" className="st83" points="1483.3,577.5 1483.3,564.8 1475.3,564.8 1475.3,559.3 1470,559.3 1470,562.3 1465.3,562.3 
                1465.3,564.8 1453.3,564.8 1453.3,562.3 1456.6,562.3 1456.6,559.3 1451.9,559.3 1451.9,562.3 1447.2,562.3 1447.2,564.8 
                1443.2,564.8 1443.2,562.3 1432.6,562.3 1432.6,565.3 1425.3,565.3 1425.3,561.9 1420.6,561.9 1420.6,558.9 1415.9,558.9 
                1415.9,556.4 1411.2,556.4 1411.2,558.9 1406.5,558.9 1406.5,562.3 1401.2,562.3 1401.2,564.8 1375.3,564.8 1375.3,561.8 
                1370.6,561.8 1370.6,564.8 1357.3,564.8 1357.3,562.3 1348,562.3 1348,564.8 1339.4,564.8 1339.4,562.3 1334.7,562.3 
                1334.7,559 1330,559 1330,556 1316,556 1316,559 1312,559 1312,562 1307.3,562 1307.3,565 1299.3,565 1299.3,562 1294,562 
                1294,565 1272,565 1272,562 1268,562 1268,559 1258.7,559 1258.7,562.4 1253.4,562.4 1253.4,564.9 1240.8,564.9 1240.8,559 
                1235.5,559 1235.5,562.4 1230.9,562.4 1230.9,564.9 1230.2,564.9 1230.2,564.8 1222.3,564.8 1222.3,559.3 1217,559.3 
                1217,562.3 1212.3,562.3 1212.3,564.8 1200.3,564.8 1200.3,562.3 1203.6,562.3 1203.6,559.3 1198.9,559.3 1198.9,562.3 
                1194.2,562.3 1194.2,564.8 1190.2,564.8 1190.2,562.3 1179.6,562.3 1179.6,565.3 1172.3,565.3 1172.3,561.9 1167.7,561.9 
                1167.7,558.9 1163,558.9 1163,556.4 1158.3,556.4 1158.3,558.9 1153.6,558.9 1153.6,562.3 1148.3,562.3 1148.3,564.8 
                1122.4,564.8 1122.4,561.8 1117.7,561.8 1117.7,564.8 1104.4,564.8 1104.4,562.3 1095.1,562.3 1095.1,564.8 1086.5,564.8 
                1086.5,562.3 1081.8,562.3 1081.8,559 1077.1,559 1077.1,556 1063.1,556 1063.1,559 1059.1,559 1059.1,562 1054.4,562 
                1054.4,565 1046.4,565 1046.4,562 1041.1,562 1041.1,565 1018.5,565 1018.5,562 1014.5,562 1014.5,559 1005.2,559 
                1005.2,562.4 999.9,562.4 999.9,564.9 992.8,564.9 992.8,564.8 987.3,564.8 987.3,559 982,559 982,559.3 979.5,559.3 
                979.5,562.3 974.8,562.3 974.8,564.8 962.8,564.8 962.8,562.3 966.1,562.3 966.1,559.3 961.4,559.3 961.4,562.3 957,562.3 
                957,564.8 953,564.8 953,562.3 942.4,562.3 942.4,565.3 935.1,565.3 935.1,561.9 930.4,561.9 930.4,558.9 925.7,558.9 
                925.7,556.4 921,556.4 921,558.9 916.3,558.9 916.3,562.3 911,562.3 911,564.8 885.1,564.8 885.1,561.8 880.4,561.8 
                880.4,564.8 867.1,564.8 867.1,562.3 858,562.3 858,564.8 849.4,564.8 849.4,562.3 844.7,562.3 844.7,559 840,559 840,556 
                826,556 826,559 822,559 822,562 817.3,562 817.3,565 809.3,565 809.3,562 804,562 804,565 781.4,565 781.4,562 777.4,562 
                777.4,559 768.1,559 768.1,562.4 762.8,562.4 762.8,564.9 750.2,564.9 750.2,559 745,559 745,562.4 740.3,562.4 740.3,564.9 
                739.7,564.9 739.7,564.8 731.7,564.8 731.7,559.3 726.4,559.3 726.4,562.3 721.7,562.3 721.7,564.8 709.7,564.8 709.7,562.3 
                713,562.3 713,559.3 708.3,559.3 708.3,562.3 703.6,562.3 703.6,564.8 699.6,564.8 699.6,562.3 689,562.3 689,565.3 682,565.3 
                682,561.9 677.3,561.9 677.3,558.9 672.6,558.9 672.6,556.4 668,556.4 668,558.9 663.3,558.9 663.3,562.3 658,562.3 658,564.8 
                632.1,564.8 632.1,561.8 627.4,561.8 627.4,564.8 614.1,564.8 614.1,562.3 604.8,562.3 604.8,564.8 596.2,564.8 596.2,562.3 
                591.5,562.3 591.5,559 586.8,559 586.8,556 572.8,556 572.8,559 568.8,559 568.8,562 564.1,562 564.1,565 556.1,565 556.1,562 
                551,562 551,565 528.4,565 528.4,562 524.4,562 524.4,559 515.1,559 515.1,562.4 509.8,562.4 509.8,564.9 497.2,564.9 
                497.2,559 491.9,559 491.9,562.4 487.2,562.4 487.2,564.9 475.9,564.9 475.9,564.8 467.9,564.8 467.9,559.3 462.6,559.3 
                462.6,562.3 458,562.3 458,564.8 446,564.8 446,562.3 449.3,562.3 449.3,559.3 444.6,559.3 444.6,562.3 440,562.3 440,564.8 
                436,564.8 436,562.3 425.4,562.3 425.4,565.3 418.1,565.3 418.1,561.9 413.4,561.9 413.4,558.9 408.7,558.9 408.7,556.4 
                404,556.4 404,558.9 399.3,558.9 399.3,562.3 394,562.3 394,564.8 368.1,564.8 368.1,561.8 363.4,561.8 363.4,564.8 
                350.1,564.8 350.1,562.3 341,562.3 341,564.8 332.4,564.8 332.4,562.3 327.7,562.3 327.7,559 323,559 323,556 309,556 309,559 
                305,559 305,562 300.3,562 300.3,565 292.3,565 292.3,562 287,562 287,565 264.4,565 264.4,562 260.4,562 260.4,559 251.1,559 
                251.1,562.4 245.8,562.4 245.8,564.9 233.2,564.9 233.2,559 228,559 228,562.4 223.3,562.4 223.3,564.9 222.8,564.9 
                222.8,564.8 214.8,564.8 214.8,559.3 209.5,559.3 209.5,562.3 204.8,562.3 204.8,564.8 192.8,564.8 192.8,562.3 196.1,562.3 
                196.1,559.3 191.4,559.3 191.4,562.3 186.7,562.3 186.7,564.8 182.7,564.8 182.7,562.3 172.1,562.3 172.1,565.3 165,565.3 
                165,561.9 160.3,561.9 160.3,558.9 155.6,558.9 155.6,556.4 150.9,556.4 150.9,558.9 146.2,558.9 146.2,562.3 140.9,562.3 
                140.9,564.8 115,564.8 115,561.8 110.3,561.8 110.3,564.8 97.2,564.8 97.2,562.3 87.9,562.3 87.9,564.8 79.3,564.8 79.3,562.3 
                74.6,562.3 74.6,559 70,559 70,556 56,556 56,559 52,559 52,562 47.3,562 47.3,565 39.3,565 39.3,562 34,562 34,565 11.5,565 
                11.5,562 7.5,562 7.5,559 -1.8,559 -1.8,562.4 -7.1,562.4 -7.1,564.9 -11.5,564.9 -11.5,564.8 -19.5,564.8 -19.5,559.3 
                -19.7,559.3 -19.7,559 -25,559 -25,562.3 -29.5,562.3 -29.5,562.4 -29.7,562.4 -29.7,564.8 -41.5,564.8 -41.5,562.3 
                -38.2,562.3 -38.2,559.3 -42.9,559.3 -42.9,562.3 -47.7,562.3 -47.7,564.8 -51.7,564.8 -51.7,562.3 -62.2,562.3 -62.2,565.3 
                -69.5,565.3 -69.5,561.9 -74.2,561.9 -74.2,558.9 -78.9,558.9 -78.9,556.4 -83.7,556.4 -83.7,558.9 -88.3,558.9 -88.3,562.3 
                -93.7,562.3 -93.7,564.8 -119.5,564.8 -119.5,561.8 -124.2,561.8 -124.2,564.8 -137.5,564.8 -137.5,562.3 -146.8,562.3 
                -146.8,564.8 -155.4,564.8 -155.4,562.3 -160.2,562.3 -160.2,559 -164.8,559 -164.8,556 -178.8,556 -178.8,559 -182.8,559 
                -182.8,562 -187.5,562 -187.5,565 -195.5,565 -195.5,562 -200.8,562 -200.8,565 -222.8,565 -222.8,562 -226.8,562 -226.8,559 
                -236.2,559 -236.2,562.4 -241.4,562.4 -241.4,564.9 -254,564.9 -254,559 -259.3,559 -259.3,562.4 -263.9,562.4 -263.9,564.9 
                -264.7,564.9 -264.7,564.8 -272.5,564.8 -272.5,559.3 -277.8,559.3 -277.8,562.3 -282.5,562.3 -282.5,564.8 -294.5,564.8 
                -294.5,562.3 -291.2,562.3 -291.2,559.3 -295.9,559.3 -295.9,562.3 -300.7,562.3 -300.7,564.8 -304.7,564.8 -304.7,562.3 
                -315.2,562.3 -315.2,565.3 -322.5,565.3 -322.5,561.9 -327.2,561.9 -327.2,558.9 -331.8,558.9 -331.8,556.4 -336.5,556.4 
                -336.5,558.9 -341.2,558.9 -341.2,562.3 -346.5,562.3 -346.5,564.8 -372.4,564.8 -372.4,561.8 -377.2,561.8 -377.2,564.8 
                -390.4,564.8 -390.4,562.3 -399.8,562.3 -399.8,564.8 -408.3,564.8 -408.3,562.3 -413,562.3 -413,559 -417.8,559 -417.8,556 
                -431.8,556 -431.8,559 -435.8,559 -435.8,562 -440.4,562 -440.4,565 -448.4,565 -448.4,562 -453.8,562 -453.8,565 -476.3,565 
                -476.3,562 -480.3,562 -480.3,559 -489.6,559 -489.6,562.4 -494.9,562.4 -494.9,564.9 -502,564.9 -502,564.8 -507.5,564.8 
                -507.5,559 -512.8,559 -512.8,559.3 -515.3,559.3 -515.3,562.3 -520,562.3 -520,564.8 -532,564.8 -532,562.3 -528.8,562.3 
                -528.8,559.3 -533.4,559.3 -533.4,562.3 -537.8,562.3 -537.8,564.8 -541.8,564.8 -541.8,562.3 -552.4,562.3 -552.4,565.3 
                -559.8,565.3 -559.8,561.9 -564.4,561.9 -564.4,558.9 -569.1,558.9 -569.1,556.4 -573.8,556.4 -573.8,558.9 -578.5,558.9 
                -578.5,562.3 -583.8,562.3 -583.8,564.8 -609.8,564.8 -609.8,561.8 -614.4,561.8 -614.4,564.8 -627.8,564.8 -627.8,562.3 
                -636.8,562.3 -636.8,564.8 -645.4,564.8 -645.4,562.3 -650.1,562.3 -650.1,559 -654.8,559 -654.8,556 -668.8,556 -668.8,559 
                -672.8,559 -672.8,562 -677.5,562 -677.5,565 -685.5,565 -685.5,562 -690.8,562 -690.8,565 -713.4,565 -713.4,562 -717.4,562 
                -717.4,559 -726.8,559 -726.8,562.4 -732,562.4 -732,564.9 -744.6,564.9 -744.6,559 -749.8,559 -749.8,562.4 -754.5,562.4 
                -754.5,564.9 -755.1,564.9 -755.1,564.8 -763.1,564.8 -763.1,559.3 -768.4,559.3 -768.4,562.3 -773.1,562.3 -773.1,564.8 
                -785.1,564.8 -785.1,562.3 -781.8,562.3 -781.8,559.3 -786.5,559.3 -786.5,562.3 -791.2,562.3 -791.2,564.8 -795.2,564.8 
                -795.2,562.3 -805.8,562.3 -805.8,565.3 -812.8,565.3 -812.8,561.9 -817.5,561.9 -817.5,558.9 -822.2,558.9 -822.2,556.4 
                -826.8,556.4 -826.8,558.9 -831.5,558.9 -831.5,562.3 -836.8,562.3 -836.8,564.8 -862.8,564.8 -862.8,561.8 -867.4,561.8 
                -867.4,564.8 -880.8,564.8 -880.8,562.3 -890,562.3 -890,564.8 -898.6,564.8 -898.6,562.3 -903.3,562.3 -903.3,559 -908,559 
                -908,556 -922,556 -922,559 -926,559 -926,562 -930.8,562 -930.8,565 -938.8,565 -938.8,562 -943.8,562 -943.8,565 -966.4,565 
                -966.4,562 -970.4,562 -970.4,559 -979.8,559 -979.8,562.4 -985,562.4 -985,564.9 -997.6,564.9 -997.6,559 -1003,559 
                -1003,562.4 -1007.6,562.4 -1007.6,564.9 -1019,564.9 -1019,564.8 -1026.9,564.8 -1026.9,559.3 -1032.2,559.3 -1032.2,562.3 
                -1036.8,562.3 -1036.8,564.8 -1048.8,564.8 -1048.8,562.3 -1045.6,562.3 -1045.6,559.3 -1050.2,559.3 -1050.2,562.3 
                -1054.8,562.3 -1054.8,564.8 -1058.8,564.8 -1058.8,562.3 -1069.4,562.3 -1069.4,565.3 -1076.8,565.3 -1076.8,561.9 
                -1081.4,561.9 -1081.4,558.9 -1086.1,558.9 -1086.1,556.4 -1090.8,556.4 -1090.8,558.9 -1095.6,558.9 -1095.6,562.3 
                -1100.8,562.3 -1100.8,564.8 -1126.8,564.8 -1126.8,561.8 -1131.4,561.8 -1131.4,564.8 -1144.8,564.8 -1144.8,562.3 
                -1153.8,562.3 -1153.8,564.8 -1162.4,564.8 -1162.4,562.3 -1167.1,562.3 -1167.1,559 -1171.8,559 -1171.8,556 -1185.8,556 
                -1185.8,559 -1189.8,559 -1189.8,562 -1194.6,562 -1194.6,565 -1202.6,565 -1202.6,562 -1207.8,562 -1207.8,565 -1230.4,565 
                -1230.4,562 -1234.4,562 -1234.4,559 -1243.8,559 -1243.8,562.4 -1249.1,562.4 -1249.1,564.9 -1261.6,564.9 -1261.6,559 
                -1266.8,559 -1266.8,562.4 -1271.6,562.4 -1271.6,564.9 -1272.1,564.9 -1272.1,564.8 -1280.1,564.8 -1280.1,559.3 
                -1285.3,559.3 -1285.3,562.3 -1290.1,562.3 -1290.1,564.8 -1302.1,564.8 -1302.1,562.3 -1298.8,562.3 -1298.8,559.3 
                -1303.4,559.3 -1303.4,562.3 -1308.1,562.3 -1308.1,564.8 -1312.1,564.8 -1312.1,562.3 -1322.8,562.3 -1322.8,565.3 
                -1329.8,565.3 -1329.8,561.9 -1334.6,561.9 -1334.6,558.9 -1339.2,558.9 -1339.2,556.4 -1343.9,556.4 -1343.9,558.9 
                -1348.6,558.9 -1348.6,562.3 -1353.9,562.3 -1353.9,564.8 -1379.8,564.8 -1379.8,561.8 -1384.6,561.8 -1384.6,564.8 
                -1397.6,564.8 -1397.6,562.3 -1406.9,562.3 -1406.9,564.8 -1415.6,564.8 -1415.6,562.3 -1420.2,562.3 -1420.2,559 -1424.8,559 
                -1424.8,556 -1438.8,556 -1438.8,559 -1442.8,559 -1442.8,562 -1447.6,562 -1447.6,565 -1455.6,565 -1455.6,562 -1460.8,562 
                -1460.8,565 -1483.3,565 -1483.3,562 -1487.3,562 -1487.3,559 -1496.6,559 -1496.6,562.4 -1501.9,562.4 -1501.9,564.9 
                -1514.6,564.9 -1514.6,559 -1519.8,559 -1519.8,562.4 -1524.6,562.4 -1524.6,564.9 -1538.4,564.9 -1538.4,578.4 -1547.1,578.4 
                -1547.1,599.1 -1547.1,599.9 -1294.1,599.9 -1263.3,599.9 -1030.2,599.9 -1010.2,599.9 -777.1,599.9 -746.4,599.9 
                -539.8,599.9 -493.3,599.9 -286.7,599.9 -255.9,599.9 -52.3,599.9 -2.8,599.9 200.8,599.9 231.5,599.9 464.6,599.9 
                484.6,599.9 717.7,599.9 748.4,599.9 955.1,599.9 1001.5,599.9 1208.2,599.9 1238.9,599.9 1492,599.9 1492,577.5 					"/>
                <g id="light-group">
                <rect className="st83 light-5" x="17.7" y="564.8" width="4.1" height="4.1"/>
                                <rect className="st83 light-15" x="80.5" y="564.7" width="4.1" height="4.1"/>
                                <rect className="st83 light-3" x="45.8" y="564.9" width="4.1" height="4.1"/>
                                <rect className="st83 light-18" x="60.7" y="565" width="4.1" height="4.1"/>
                                <rect className="st83 light-7" x="369.7" y="564.8" width="4.1" height="4.1" />
                                <rect className="st83 light-9" x="325.5" y="564.7" width="4.1" height="4.1" />
                                <rect className="st83 light-17" x="345.8" y="564.9" width="4.1" height="4.1" />
                                <rect className="st83 light-18" x="334.7" y="565" width="4.1" height="4.1" />
                                <rect className="st83 light-16" x="360.5" y="564.9" width="4.1" height="4.1" />
                                <rect className="st83 light-11" x="412.3" y="564.3" width="4.1" height="4.1" />
                                <rect className="st83 light-5" x="246" y="566.3" width="4.1" height="4.1" />
                                <rect className="st83 light-15" x="201.8" y="566.2" width="4.1" height="4.1" />
                                <rect className="st83 light-4" x="222.1" y="566.5" width="4.1" height="4.1" />
                                <rect className="st83 light-12" x="211" y="566.6" width="4.1" height="4.1" />
                                <rect className="st83 light-13" x="236.8" y="566.5" width="4.1" height="4.1" />
                                <rect className="st83 light-14" x="288.6" y="565.8" width="4.1" height="4.1" />
                                <rect className="st83 light-10" x="129.1" y="565.6" width="4.1" height="4.1" />
                                <rect className="st83 light-6" x="84.9" y="565.4" width="4.1" height="4.1" />
                                <rect className="st83 light-3" x="105.2" y="565.7" width="4.1" height="4.1" />
                                <rect className="st83 light-2" x="94.1" y="565.8" width="4.1" height="4.1" />
                                <rect className="st83 light-8" x="119.9" y="565.7" width="4.1" height="4.1" />
                                <rect className="st83 light-1" x="171.7" y="565" width="4.1" height="4.1" />
                                <rect className="st83 light-7" x="716" y="564.5" width="4.1" height="4.1" />
                                <rect className="st83 light-9" x="671.8" y="564.4" width="4.1" height="4.1" />
                                <rect className="st83 light-17" x="692.1" y="564.7" width="4.1" height="4.1" />
                                <rect className="st83 light-18" x="681" y="564.8" width="4.1" height="4.1" />
                                <rect className="st83 light-16" x="706.8" y="564.7" width="4.1" height="4.1" />
                                <rect className="st83 light-11" x="758.6" y="564" width="4.1" height="4.1" />
                                <rect className="st83 light-5" x="592.3" y="566.1" width="4.1" height="4.1" />
                                <rect className="st83 light-15" x="548.1" y="565.9" width="4.1" height="4.1" />
                                <rect className="st83 light-4" x="568.4" y="566.2" width="4.1" height="4.1" />
                                <rect className="st83 light-12" x="557.3" y="566.3" width="4.1" height="4.1" />
                                <rect className="st83 light-13" x="583.1" y="566.2" width="4.1" height="4.1" />
                                <rect className="st83 light-14" x="634.9" y="565.6" width="4.1" height="4.1" />
                                <rect className="st83 light-10" x="475.4" y="565.3" width="4.1" height="4.1" />
                                <rect className="st83 light-6" x="431.2" y="565.2" width="4.1" height="4.1" />
                                <rect className="st83 light-3" x="451.5" y="565.4" width="4.1" height="4.1" />
                                <rect className="st83 light-2" x="440.4" y="565.6" width="4.1" height="4.1" />
                                <rect className="st83 light-8" x="466.2" y="565.4" width="4.1" height="4.1" />
                                <rect className="st83 light-1" x="518" y="564.8" width="4.1" height="4.1" />
                                <rect className="st83 light-7" x="1014.7" y="564.2" width="4.1" height="4.1" />
                                <rect className="st83 light-9" x="970.5" y="564.1" width="4.1" height="4.1" />
                                <rect className="st83 light-17" x="990.8" y="564.4" width="4.1" height="4.1" />
                                <rect className="st83 light-18" x="979.7" y="564.5" width="4.1" height="4.1" />
                                <rect className="st83 light-16" x="1005.5" y="564.4" width="4.1" height="4.1" />
                                <rect className="st83 light-11" x="1057.3" y="563.7" width="4.1" height="4.1" />
                                <rect className="st83 light-5" x="891" y="565.8" width="4.1" height="4.1" />
                                <rect className="st83 light-15" x="846.8" y="565.6" width="4.1" height="4.1" />
                                <rect className="st83 light-4" x="867.1" y="565.9" width="4.1" height="4.1" />
                                <rect className="st83 light-12" x="856" y="566" width="4.1" height="4.1" />
                                <rect className="st83 light-13" x="881.8" y="565.9" width="4.1" height="4.1" />
                                <rect className="st83 light-14" x="933.6" y="565.3" width="4.1" height="4.1" />
                                <rect className="st83 light-10" x="774.1" y="565" width="4.1" height="4.1" />
                                <rect className="st83 light-6" x="729.9" y="564.9" width="4.1" height="4.1" />
                                <rect className="st83 light-3" x="750.2" y="565.1" width="4.1" height="4.1" />
                                <rect className="st83 light-2" x="739.1" y="565.3" width="4.1" height="4.1" />
                                <rect className="st83 light-8" x="764.9" y="565.1" width="4.1" height="4.1" />
                                <rect className="st83 light-1" x="816.7" y="564.5" width="4.1" height="4.1" />
                                <rect className="st83 light-17" x="1358.3" y="564.5" width="4.1" height="4.1" />
                                <rect className="st83 light-18" x="1347.2" y="564.6" width="4.1" height="4.1" />
                                <rect className="st83 light-16" x="1373" y="564.5" width="4.1" height="4.1" />
                                <rect className="st83 light-11" x="1424.8" y="563.9" width="4.1" height="4.1" />
                                <rect className="st83 light-5" x="1258.5" y="565.9" width="4.1" height="4.1" />
                                <rect className="st83 light-15" x="1214.3" y="565.8" width="4.1" height="4.1" />
                                <rect className="st83 light-4" x="1234.6" y="566" width="4.1" height="4.1" />
                                <rect className="st83 light-12" x="1223.5" y="566.2" width="4.1" height="4.1" />
                                <rect className="st83 light-13" x="1249.3" y="566" width="4.1" height="4.1" />
                                <rect className="st83 light-14" x="1301.1" y="565.4" width="4.1" height="4.1" />
                                <rect className="st83 light-10" x="1141.6" y="565.1" width="4.1" height="4.1" />
                                <rect className="st83 light-6" x="1097.4" y="565" width="4.1" height="4.1" />
                                <rect className="st83 light-3" x="1117.7" y="565.3" width="4.1" height="4.1" />
                                <rect className="st83 light-2" x="1106.6" y="565.4" width="4.1" height="4.1" />
                                <rect className="st83 light-8" x="1132.4" y="565.3" width="4.1" height="4.1" />
                                <rect className="st83 light-1" x="1184.2" y="564.6" width="4.1" height="4.1" />
                            </g>
                <path id="lava-midtones" className="st84" d="M1483.3,577.6v-8.5H1470v3h-4v3h-5.3v2.5h-13.3V575h5.3v-3h3.3v-3h-26v3h-10v-3h5.3
                v-3h-5.3v-2.5h-12.7v2.5h-4v3h-14.7v3h-9.3v-3.4h-12v3.4h-5.3v3h-4.7v2.5h-5.3V572h5.3v-3l-13.4-0.4v3.4h-4.7v-5.9h-4.7v-2.5
                h-5.3v-2.5h-12v2.5h-5.3v2.5h-4v3h-5.3v5.9h-4.7v-3h-4.7v-3h-8.7v5.9h-4.7v-3h-4v-3.4h-4.7v-3h-5.3v-2.1h-7.3v2.5h-4.7v3h8v3
                h5.3v3h4.7v2.5h-12.7V575h-6v-3h-5.3v-3h-12v3h-5.6v-2.9h-4.4V569h-8.7v0.1h-0.2v3h-4v3h-5.3v2.5h-13.4V575h5.3v-3h3.3v-3h-26
                v3h-10v-3h5.3v-3h-5.3v-2.5h-12.7v2.5h-4v3h-14.7v3h-9.3v-3.4h-12v3.4h-5.3v3h-4.7v2.5h-5.3V572h5.3v-3l-13.4-0.4v3.4h-4.7
                v-5.9h-4.7v-2.5h-5.3v-2.5h-12v2.5h-5.3v2.5h-4v3h-5.3v5.9h-4.7v-3h-4.7v-3h-8.7v5.9h-4.7v-3h-4v-3.4h-4.7v-3h-5.3v-2.1h-7.3
                v2.5h-4.7v3h8v3h5.3v3h4.7v2.5h-12.7V575h-6v-3h-5.3v-3h-12v0.1h-3.2v2.9h-6.8v-3h-7.3h-1.4h-24.6v3h-10v-3h5.3v-3h-5.3v-2.5
                h-12.7v2.5h-4v3H898v3h-9.3v-3.4h-12v3.4h-5.3v3h-4.7v2.5h-5.3V572h5.3v-3l-13.4-0.4v3.4h-4.7v-5.9h-4.7v-2.5h-5.3v-2.5h-12
                v2.5h-5.3v2.5h-4v3H812v5.9h-4.7v-3h-4.7v-3h-8.7v5.9h-4.7v-3h-4v-3.4h-4.7v-3h-5.3v-2.1h-7.3v2.5h-4.7v3h8v3h5.3v3h4.7v2.5
                h-12.7V575h-6v-3h-5.3v-3h-12v3h-5.5v-2.9h-4.5V569h-8.7v0.1h-0.2v3h-4v3H717v2.5h-13.4V575h5.3v-3h3.3v-3h-26v3h-10v-3h5.3v-3
                h-5.3v-2.5h-12.7v2.5h-4v3h-14.7v3h-9.3v-3.4h-12v3.4h-5.3v3h-4.7v2.5h-5.3V572h5.3v-3l-13.4-0.4v3.4h-4.7v-5.9h-4.7v-2.5h-5.3
                v-2.5h-12v2.5h-5.3v2.5h-4v3h-5.3v5.9h-4.7v-3h-4.7v-3h-8.7v5.9H536v-3h-4v-3.4h-4.7v-3H522v-2.1h-7.3v2.5H510v3h8v3h5.3v3h4.7
                v2.5h-12.7V575h-6v-3H504v-3h-12v3h-10v-3h-8.7v0.1h-10.8v3h-4v3h-5.3v2.5h-13.4V575h5.3v-3h3.3v-3h-26v3h-10v-3h5.3v-3h-5.3
                v-2.5h-12.7v2.5h-4v3H381v3h-9.3v-3.4h-12v3.4h-5.3v3h-4.7v2.5h-5.3V572h5.3v-3l-13.4-0.4v3.4h-4.7v-5.9H327v-2.5h-5.3v-2.5
                h-12v2.5h-5.3v2.5h-4v3h-5.3v5.9h-4.7v-3h-4.7v-3H277v5.9h-4.7v-3h-4v-3.4h-4.7v-3h-5.3v-2.1H251v2.5h-4.7v3h8v3h5.3v3h4.7v2.5
                h-12.7V575h-6v-3h-5.3v-3h-12v3h-5.5v-2.9h-4.5V569h-8.7v0.1h-0.2v3h-4v3h-5.3v2.5h-13.4V575h5.3v-3h3.3v-3h-26v3h-10v-3h5.3
                v-3h-5.3v-2.5h-12.7v2.5h-4v3H128v3h-9.3v-3.4h-12v3.4h-5.3v3h-4.7v2.5h-5.3V572h5.3v-3l-13.4-0.4v3.4h-4.7v-5.9h-4.7v-2.5
                h-5.3v-2.5h-12v2.5h-5.3v2.5h-4v3H42v5.9h-4.7v-3h-4.7v-3h-8.7v5.9h-4.7v-3h-4v-3.4h-4.7v-3H5.1v-2.1h-7.3v2.5h-4.7v3h8v3h5.3
                v3h4.7v2.5H-1.6V575h-6v-3h-5.3v-3h-12v3h-10v-3h-8.7v9.3h-8.7v0.1V600h253.1h30.7h233.1h20h233.1h30.7h206.7h46.4h206.7h30.7
                H1492v-22.4H1483.3z M956.7,575h5.3v-3h1.9v5.6h-7.2V575z"/>
                <polygon id="lava-dark" className="st85" points="1486.7,592.4 1486.7,589.4 
                1478,589.4 1478,586.4 1474,586.4 1474,583.4 1464.7,583.4 1464.7,585.9 1469.4,585.9 1469.4,589.3 1474.1,589.3 1474.1,591.4 
                1469.4,591.4 1469.4,594.4 1465.4,594.4 1465.4,597.4 1460.1,597.4 1460.1,594.9 1455.4,594.9 1455.4,591.5 1451.4,591.5 
                1451.4,589 1446.7,589 1446.7,586.5 1437.4,586.5 1437.4,589 1434.1,589 1434.1,591.5 1424.8,591.5 1424.8,594.5 1419.5,594.5 
                1419.5,592.4 1414.8,592.4 1414.8,586.5 1410.8,586.5 1410.8,583.5 1406.8,583.5 1406.8,580.5 1410.8,580.5 1410.8,577.1 
                1414.8,577.1 1414.8,574.6 1406.8,574.6 1406.8,577.6 1402.8,577.6 1402.8,580.6 1393.5,580.6 1393.5,583.1 1388.8,583.1 
                1388.8,589 1383.5,589 1383.5,591.5 1379.5,591.5 1379.5,589 1372.8,589 1372.8,586.5 1365.5,586.5 1365.5,594.5 1360.8,594.5 
                1360.8,591.5 1352.1,591.5 1352.1,594.5 1347.4,594.5 1347.4,592.4 1342.7,592.4 1342.7,589 1337.4,589 1337.4,586.5 
                1328.7,586.5 1328.7,583.1 1324.7,583.1 1324.7,580.6 1320.7,580.6 1320.7,586.5 1315.4,586.5 1315.4,591.6 1302.7,591.6 
                1302.7,594.6 1296.7,594.6 1296.7,597.1 1279.4,597.1 1279.4,595.4 1274.7,595.4 1274.7,589 1270,589 1270,586.5 1256.7,586.5 
                1256.7,588.2 1253.4,588.2 1253.4,591.6 1247.4,591.6 1247.4,597.9 1238.9,597.9 1238.9,592.4 1233.6,592.4 1233.6,589.4 
                1224.9,589.4 1224.9,586.4 1220.9,586.4 1220.9,583.4 1211.6,583.4 1211.6,585.9 1216.3,585.9 1216.3,589.3 1221,589.3 
                1221,591.4 1216.3,591.4 1216.3,591.6 1216.1,591.6 1216.1,594.4 1212.3,594.4 1212.3,594.6 1212.1,594.6 1212.1,597.1 
                1207.5,597.1 1207.5,597.4 1207,597.4 1207,594.9 1202.3,594.9 1202.3,591.5 1198.3,591.5 1198.3,589 1193.6,589 1193.6,586.5 
                1184.3,586.5 1184.3,589 1181,589 1181,591.5 1171.7,591.5 1171.7,594.5 1166.4,594.5 1166.4,592.4 1161.7,592.4 1161.7,586.5 
                1157.7,586.5 1157.7,583.5 1153.7,583.5 1153.7,580.5 1157.7,580.5 1157.7,577.1 1161.7,577.1 1161.7,574.6 1153.7,574.6 
                1153.7,577.6 1149.7,577.6 1149.7,580.6 1140.4,580.6 1140.4,583.1 1135.7,583.1 1135.7,589 1130.4,589 1130.4,591.5 
                1126.4,591.5 1126.4,589 1119.7,589 1119.7,586.5 1112.4,586.5 1112.4,594.5 1107.7,594.5 1107.7,591.5 1099,591.5 1099,594.5 
                1094.3,594.5 1094.3,592.4 1089.6,592.4 1089.6,589 1084.3,589 1084.3,586.5 1075.6,586.5 1075.6,583.1 1071.6,583.1 
                1071.6,580.6 1067.6,580.6 1067.6,586.5 1062.3,586.5 1062.3,591.6 1049.6,591.6 1049.6,594.6 1043.6,594.6 1043.6,597.1 
                1026.3,597.1 1026.3,595.4 1021.6,595.4 1021.6,589 1016.9,589 1016.9,586.5 1003.6,586.5 1003.6,588.2 1000.3,588.2 
                1000.3,591.6 996.2,591.6 996.2,589.4 987.5,589.4 987.5,586.4 983.5,586.4 983.5,583.4 974.2,583.4 974.2,585.9 978.9,585.9 
                978.9,589.3 983.6,589.3 983.6,591.4 978.9,591.4 978.9,594.4 976.3,594.4 976.3,591.6 964.9,591.6 964.9,591.5 960.9,591.5 
                960.9,589 956.2,589 956.2,586.5 946.9,586.5 946.9,589 943.6,589 943.6,591.5 934.3,591.5 934.3,594.5 929,594.5 929,592.4 
                924.3,592.4 924.3,586.5 920.3,586.5 920.3,583.5 916.3,583.5 916.3,580.5 920.3,580.5 920.3,577.1 924.3,577.1 924.3,574.6 
                916.3,574.6 916.3,577.6 912.3,577.6 912.3,580.6 903,580.6 903,583.1 898.3,583.1 898.3,589 893,589 893,591.5 889,591.5 
                889,589 882.3,589 882.3,586.5 875,586.5 875,594.5 870.3,594.5 870.3,591.5 861.6,591.5 861.6,594.5 857,594.5 857,592.4 
                852.3,592.4 852.3,589 847,589 847,586.5 838.3,586.5 838.3,583.1 834.3,583.1 834.3,580.6 830.3,580.6 830.3,586.5 825,586.5 
                825,591.6 812.3,591.6 812.3,594.6 806.3,594.6 806.3,597.1 789,597.1 789,595.4 784.3,595.4 784.3,589 779.6,589 779.6,586.5 
                766.3,586.5 766.3,588.2 763,588.2 763,591.6 757,591.6 757,597.9 748.5,597.9 748.5,592.4 743.2,592.4 743.2,589.4 
                734.5,589.4 734.5,586.4 730.5,586.4 730.5,583.4 721.2,583.4 721.2,585.9 725.9,585.9 725.9,589.3 730.6,589.3 730.6,591.4 
                725.9,591.4 725.9,591.6 725.7,591.6 725.7,594.4 721.9,594.4 721.9,594.6 721.7,594.6 721.7,597.1 717,597.1 717,597.4 
                716.6,597.4 716.6,594.9 711.9,594.9 711.9,591.5 707.9,591.5 707.9,589 703.2,589 703.2,586.5 693.9,586.5 693.9,589 
                690.6,589 690.6,591.5 681.3,591.5 681.3,594.5 676,594.5 676,592.4 671.3,592.4 671.3,586.5 667.3,586.5 667.3,583.5 
                663.3,583.5 663.3,580.5 667.3,580.5 667.3,577.1 671.3,577.1 671.3,574.6 663.3,574.6 663.3,577.6 659.3,577.6 659.3,580.6 
                650,580.6 650,583.1 645.3,583.1 645.3,589 640,589 640,591.5 636,591.5 636,589 629.3,589 629.3,586.5 622,586.5 622,594.5 
                617.3,594.5 617.3,591.5 608.6,591.5 608.6,594.5 603.9,594.5 603.9,592.4 599.2,592.4 599.2,589 593.9,589 593.9,586.5 
                585.2,586.5 585.2,583.1 581.2,583.1 581.2,580.6 577.2,580.6 577.2,586.5 571.9,586.5 571.9,591.6 559.2,591.6 559.2,594.6 
                553.2,594.6 553.2,597.1 535.9,597.1 535.9,595.4 531.2,595.4 531.2,589 526.5,589 526.5,586.5 513.2,586.5 513.2,588.2 
                509.9,588.2 509.9,591.6 503.9,591.6 503.9,597.9 490.6,597.9 490.6,595.4 485.9,595.4 485.9,591.6 479.3,591.6 479.3,589.4 
                470.6,589.4 470.6,586.4 466.6,586.4 466.6,583.4 457.3,583.4 457.3,585.9 462,585.9 462,589.3 466.7,589.3 466.7,591.4 
                462,591.4 462,594.4 458,594.4 458,597.4 452.7,597.4 452.7,594.9 448,594.9 448,591.5 444,591.5 444,589 439.3,589 
                439.3,586.5 430,586.5 430,589 426.7,589 426.7,591.5 417.4,591.5 417.4,594.5 412.1,594.5 412.1,592.4 407.4,592.4 
                407.4,586.5 403.4,586.5 403.4,583.5 399.4,583.5 399.4,580.5 403.4,580.5 403.4,577.1 407.4,577.1 407.4,574.6 399.4,574.6 
                399.4,577.6 395.4,577.6 395.4,580.6 386,580.6 386,583.1 381.3,583.1 381.3,589 376,589 376,591.5 372,591.5 372,589 
                365.3,589 365.3,586.5 358,586.5 358,594.5 353.3,594.5 353.3,591.5 344.6,591.5 344.6,594.5 340,594.5 340,592.4 335.3,592.4 
                335.3,589 330,589 330,586.5 321.3,586.5 321.3,583.1 317.3,583.1 317.3,580.6 313.3,580.6 313.3,586.5 308,586.5 308,591.6 
                295.3,591.6 295.3,594.6 289.3,594.6 289.3,597.1 272,597.1 272,595.4 267.3,595.4 267.3,589 262.6,589 262.6,586.5 
                249.3,586.5 249.3,588.2 246,588.2 246,591.6 240,591.6 240,597.9 231.5,597.9 231.5,592.4 226.2,592.4 226.2,589.4 
                217.5,589.4 217.5,586.4 213.5,586.4 213.5,583.4 204.2,583.4 204.2,585.9 208.9,585.9 208.9,589.3 213.6,589.3 213.6,591.4 
                208.9,591.4 208.9,591.6 208.7,591.6 208.7,594.4 204.9,594.4 204.9,594.6 204.7,594.6 204.7,597.1 200.1,597.1 200.1,597.4 
                199.6,597.4 199.6,594.9 194.9,594.9 194.9,591.5 190.9,591.5 190.9,589 186.2,589 186.2,586.5 176.9,586.5 176.9,589 
                173.6,589 173.6,591.5 164.3,591.5 164.3,594.5 159,594.5 159,592.4 154.3,592.4 154.3,586.5 150.3,586.5 150.3,583.5 
                146.3,583.5 146.3,580.5 150.3,580.5 150.3,577.1 154.3,577.1 154.3,574.6 146.3,574.6 146.3,577.6 142.3,577.6 142.3,580.6 
                133,580.6 133,583.1 128.3,583.1 128.3,589 123,589 123,591.5 119,591.5 119,589 112.3,589 112.3,586.5 105,586.5 105,594.5 
                100.3,594.5 100.3,591.5 91.6,591.5 91.6,594.5 86.9,594.5 86.9,592.4 82.2,592.4 82.2,589 76.9,589 76.9,586.5 68.2,586.5 
                68.2,583.1 64.2,583.1 64.2,580.6 60.2,580.6 60.2,586.5 54.9,586.5 54.9,591.6 42.3,591.6 42.3,594.6 36.3,594.6 36.3,597.1 
                19,597.1 19,595.4 14.3,595.4 14.3,589 9.6,589 9.6,586.5 -3.7,586.5 -3.7,588.2 -7,588.2 -7,591.6 -13,591.6 -13,597.9 
                -26.3,597.9 -26.3,595.4 -31,595.4 -31,591.6 -44.3,591.6 -44.3,594.6 -48.3,594.6 -48.3,597.1 -53,597.1 -53,600 200.1,599.6 
                200.1,600 464,599.6 464,600 717,599.6 717,600 954.4,599.7 954.4,600 1207.5,599.6 1207.5,600 1492,599.6 1492,592.4 					"/>
            </g>
            <animateMotion xlinkHref="#secondary-land-group" dur="20s" begin="0s" repeatCount="indefinite" path="M20,50 C20,-30 -30,-30 0,30 C50-30 20,30 20,30 z"></animateMotion>
            <animateMotion xlinkHref="#main-land-group" dur="20s" begin="0s" repeatCount="indefinite" path="M20,50 C20,-30 -30,-30 0,30 C50-30 20,30 20,30 z"></animateMotion>
        </svg>
    </>
  )
}