import React from "react";
import { NotFound } from "../../components/not-found";
import { usePageData } from "../../hooks/app-hooks";
import {Helmet} from "react-helmet";
import { withNotFoundLayout } from '../../layouts'

const NotFoundPage = () => {
    const PageData = usePageData();
    return (
        <>
            <Helmet>
            <title>Page Not Found</title>
            <meta name="description" content="A Collective of Cryptocurrency Founders and Investors Supporting the Creator Economy"/>
            <meta property="og:title" content="Page Not Found"/>
            <meta name="keywords" content="" />
            <meta property="og:description" content="A Collective of Cryptocurrency Founders and Investors Supporting the Creator Economy" />
            </Helmet>
            <NotFound data={PageData.NotFound} />
        </>
    );
};

export default withNotFoundLayout(NotFoundPage);
