import React from "react";
import { MainImage } from "./svg/main-svg";
export const FooterBefore = ({data}) => {
return (
<section id='footer-before' className='container-fluid p-0'>
  <div className='row'>
    <div className='col-12'>
          <MainImage />
    </div>
  </div>
</section>
)
}

export default FooterBefore;