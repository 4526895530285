import React from "react";
import {Helmet} from "react-helmet";
import { Intro } from "../../components/intro";
import { About } from "../../components/about";
import { Team } from "../../components/Team";
import { Staff } from "../../components/staff";
import { FooterBefore } from "../../components/footerbefore";
import { usePageData } from "../../hooks/app-hooks";
import SmoothScroll from "smooth-scroll";
import { withStandardLayout } from '../../layouts';

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});


const IndexPage = () => {
  const PageData = usePageData();
  return (
    <>
      <Helmet>
        <title>Own.fund</title>
        <meta name="description" content="A Collective of Cryptocurrency Founders and Investors Supporting the Creator Economy"/>
        <meta property="og:title" content="Own.fund"/>
        <meta name="keywords" content="" />
        <meta property="og:description" content="A Collective of Cryptocurrency Founders and Investors Supporting the Creator Economy" />
        <link rel="stylesheet" type="text/css" href="css/animation.css" />
      </Helmet>
      <Intro data={PageData.Intro} />
      <About data={PageData.About} />
      <Team data={PageData.Team} />
      <Staff data={PageData.Staff} />
      <FooterBefore data={PageData.FooterBefore} />
    </>
  );
};

export default withStandardLayout(IndexPage);
